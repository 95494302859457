import React, { useState } from 'react';
import { Box, Button, Grid, InputAdornment, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { MdMailOutline } from "react-icons/md";

export default function ForgetPassword() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        email: ''
    });

    const resetPassword = () => {
        setLoading(true);
        postUrl('/forgot-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <Grid container>
                    <Grid item xs={12}>
                        <InputLabel>{t('user.email')}</InputLabel>
                        <TextField
                            variant="standard"
                            placeholder={t('user.email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdMailOutline className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            type="email"
                            autoComplete="email"
                            color='primary'
                            value={state.email}
                            onChange={({ target }) => setState({ ...state, email: target.value })}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={resetPassword}
                        className='big-button'
                        style={{ marginTop: 20 }}
                        disabled={_.size(state.email) > 0 ? false : true}
                    >
                        {t('button.reset')}
                    </Button>
                </Box>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '15vh',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
        // position:'absolute',
        // bottom:30
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));