import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Grid, Box, Select, MenuItem, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BonusHistoryItem from '@components/BonusHistoryItem';
import i18next from 'i18next';

const Bonus = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    let location = useLocation();

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [bonusList, setBonusList] = useState([]);
    const [searchParams] = useSearchParams();
    const [bonus, setBonus] = useState(parseInt(atob(searchParams.get("b"))) || "");
    const [bonusTitle, setBonusTitle] = useState({});

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (typeof(searchParams.get("b")) === 'string' && bonus) {
            try {
                getUrl(`/commission-records`, { id: bonus, per_page: 10, page: page }).then((response) => {
                    setLoading(false);
                    if (response.status && isMountedRef.current) {
                        let { data, current_page, last_page } = response.listing;
                        setTotal(last_page);
                        setCurrPage(current_page);

                        if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                        if (page > 1) { setBonusList(_.concat(bonusList, data)); }
                        else { setBonusList(data); }
                    }
                }).catch((error) => {
                    setLoading(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
            } catch (error) {
                setLoading(false);
                navigate(`/`);
            }
        } else {
            setLoading(false);
            navigate(`/`);
        }

        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
    }, [page, searchParams, bonus]);

    useEffect(() => {
        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setBonusTitle(response.data);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom ) {
            setPage(page + 1);
        }
    }

    const handleChange = (event) => {
        setBonus(event.target.value);
        navigate(`/bonus?b=${btoa(event.target.value)}`, { state: { from: storePaths(location) } });
    };

    return (
        <div className='flex-c-m flex-col m-t-60'>
            <div className={styles.walletBalanceLayout}>
                <Typography variant='h6' className='txt-title' style={{fontWeight: 'bold'}}>
                    {/* {t('bonus.title')} */}
                    {_.find(bonusTitle, { id: bonus})?.name?.[i18n.language] || ''}
                </Typography>
            </div>

            <Grid container style={{padding: '0 35px'}}>
                {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bonus}
                            onChange={handleChange}
                            sx={{ 
                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
                            }}
                            style={{
                                color: '#121C28',
                                borderRadius: 10,
                                background: 'linear-gradient(91deg, #B77800 1.51%, #FFC85E 99.83%)',
                                boxShadow: 'rgba(255, 255, 255, 0.12) 3px 3px 3px 0px inset, rgb(0, 0, 0) 3px 3px 4px',
                                height: 50,
                                marginBottom: 20,
                                fontWeight: 'bold',
                            }}
                        >
                            <MenuItem key={''} value={'all'}>{t('bonus.all')}</MenuItem>
                            {
                                _.size(bonusTitle) > 0 &&
                                _.map(bonusTitle, (detail, key) => (
                                    <MenuItem key={key} value={detail.id}>{detail?.name?.[i18n.language]}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid> */}

                {
                    bonusList?.length > 0 ? (
                        bonusList.map((history, key) => (
                            <Grid item xs={12} key={history.id} style={{ padding: '10px 0' }}>
                                <Box>
                                    <BonusHistoryItem history={history} />
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src="images/wallets/emptystate.png" alt="Transaction Icon" style={{ marginBottom: '10px', width: 100 }} loading='lazy' />
                            <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20 }}>
                                {t('table.noTransactionYet')}
                            </Typography>
                        </Grid>
                    )
                }

                {
                    bonusList?.length > 0 && (
                        <div className={styles.endFontStyle}>
                            <p className='fs-12 txt-content txt-center'>
                                {currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                            </p>
                        </div>
                    )
                }
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundImage: `url('images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 85,
        justifyContent: "center",
    },
    // colorBrow: {
    //     color: theme.palette.primary.main
    // },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
        width:'100%'
    },
    viewAll: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    sliderShow: {
        width: '1000px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        padding: '0 30px'
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    },
    drawerContent: {
        padding: '0 35px'
    },
    rectangle: {
        background: '#31455B',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.57) inset',
        height: '4px',
        margin: '5px 0',
        flexShrink: 0
    },
}));

export default Bonus;