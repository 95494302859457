import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { BottomNavigation, BottomNavigationAction, Paper, createTheme } from '@mui/material';

import { storePaths } from '@utils/Tools';
import { MuiThemeProvider } from '@material-ui/core';

const MAIN_NAVIGATE = ['home', 'funds', 'account', 'plans', 'trade', 'community'];
const SHOW_BAR = ['/', '/setting', '/funds/wallet', '/funds/bonus', '/account', '/community/sponsor', '/plans', '/trade', '/trade/marketplace', '/trade/trading', '/trade/transaction-details']
const SELLING_BTN = []
// const SELLING_BTN = ['/trade', '/trade/marketplace', '/trade/trading', '/trade/transaction-details']

const BottomNavigationBar = memo(() => {
    const { t } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const [value, setValue] = useState('home');
    const [selectedIcon, setSelectedIcon] = useState('home');
    let location = useLocation();
    let path = location.pathname.split('/');

    useEffect(() => {
        let temp_value = _.intersection(MAIN_NAVIGATE, path);
        if (_.size(temp_value) > 0) {
            setValue(temp_value[0]);
            setSelectedIcon(temp_value[0]);
        } else {
            setValue('home');
            setSelectedIcon('home');
        }
    }, [path]);

    return (
        <>
            {

                _.includes(SHOW_BAR, location.pathname)
                    ?
                    <Paper sx={{ position: 'fixed', zIndex: 1, bottom: 0, maxWidth: 'inherit', width: '100%', textAlign: "center", justifyContent: "flex-start" }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                setSelectedIcon(newValue);
                            }}
                        >
                            <BottomNavigationAction
                                label={t('title.home')}
                                value="home"
                                icon={
                                    <div>
                                        <img
                                            src={`/images/footer_icon/${selectedIcon === 'home' ? 'home-sel.png' : 'home.png'}`}
                                            alt={t('title.home')}
                                            loading='lazy'
                                            style={{ height: '16px', width: '16px' }}
                                        />
                                    </div>
                                }
                                to="/"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                            <BottomNavigationAction
                                label={t('title.plan')}
                                value="plans"
                                icon={
                                    <div>
                                        <img
                                            src={`/images/footer_icon/${selectedIcon === 'plans' ? 'package-sel.png' : 'package.png'}`}
                                            alt={t('title.plan')}
                                            loading='lazy'
                                            style={{ height: '16px', width: '16px' }}
                                        />
                                    </div>
                                }
                                to="/plans"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />

                            <BottomNavigationAction
                                label={t('title.genealogy')}
                                value="community"
                                icon={
                                    <div>
                                        <img
                                            src={`/images/footer_icon/${selectedIcon === 'community' ? 'downline-sel.png' : 'downline.png'}`}
                                            alt={t('title.genealogy')}
                                            loading='lazy'
                                            style={{ height: '16px', width: '16px' }}
                                        />
                                    </div>
                                }
                                to="/community/sponsor"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />

                            {/* {
                                _.includes(SELLING_BTN, location.pathname)
                                    ?
                                    <BottomNavigationAction
                                        label={t('trade.selling')}
                                        value="trade"
                                        icon={
                                            <div className='flex-c-m' style={{ background: '#eee', width: 55, height: 55, marginTop: -28, marginBottom: 4, borderRadius: 48, boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 3px 3px 3px 0px rgba(255, 255, 255, 0.76) inset, -2px -2px 3px 0px rgba(0, 0, 0, 0.14) inset' }}>
                                                <img
                                                    src={`/images/footer_icon/${selectedIcon === 'trade' ? 'selling.png' : 'selling.png'}`}
                                                    alt={t('title.trade')}
                                                    style={{ height: 18 }}
                                                    loading='lazy'
                                                />
                                            </div>
                                        }
                                        to="/trade/selling-form"
                                        state={{ from: storePaths(location) }}
                                        component={RouterLink}
                                    />
                                    :
                                    <BottomNavigationAction
                                        label={t('title.trade')}
                                        value="trade"
                                        icon={
                                            <div>
                                                <img
                                                    src={`/images/footer_icon/${selectedIcon === 'trade' ? 'package-sel.png' : 'package.png'}`}
                                                    alt={t('title.trade')}
                                                    style={{ height: 18 }}
                                                    loading='lazy'
                                        />
                                            </div>
                                        }
                                        to="/trade"
                                        state={{ from: storePaths(location) }}
                                        component={RouterLink}
                                    />
                            } */}
                            <BottomNavigationAction
                                label={t('title.wallet')}
                                value="funds"
                                icon={
                                    <div>
                                        <img
                                            src={`/images/footer_icon/${selectedIcon === 'funds' ? 'wallet-sel.png' : 'wallet.png'}`}
                                            alt={t('title.wallet')}
                                            loading='lazy'
                                            style={{ height: '16px', width: '16px' }}
                                        />
                                    </div>
                                }
                                to="/funds/wallet"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                            {/* <BottomNavigationAction
                                label={t('title.community')}
                                value="community"
                                icon={<BiSitemap style={{ height: 18 }} />}
                                to="/community/sponsor"
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            /> */}
                            <BottomNavigationAction
                                label={t('title.profile')}
                                value="account"
                                icon={
                                    <div>
                                        <img
                                            src={`/images/footer_icon/${selectedIcon === 'account' ? 'account-sel.png' : 'account.png'}`}
                                            alt={t('title.profile')}
                                            className="img-style"
                                            loading='lazy'
                                            style={{ height: '16px', width: '16px' }}
                                        />
                                    </div>
                                }
                                to={accessToken ? "/account" : "/landing"}
                                state={{ from: storePaths(location) }}
                                component={RouterLink}
                            />
                        </BottomNavigation>
                    </Paper>
                    :
                    null
            }
        </>
    )
});

export default BottomNavigationBar;

const sellingBtnStyle = createTheme({
    overrides: {
        MuiBottomNavigationAction: {
            label: {
                //    paddingTop:30
            },
        }
    }
})