import moment from 'moment-timezone';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { CircularProgress, Box, Typography, Link } from '@mui/material';

import { makeStyles, useTheme } from '@mui/styles';
import { changeLanguage } from '@actions';
import { FiTag, FiShoppingBag } from "react-icons/fi";

function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };


export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

    return (
        <div className=' p-t-25 p-b-110' style={{ minHeight: '90vh' }}>
            <div className='p-t-50'>
                <p className='txt-center fs-19' style={{color:'#82512D'}}><b>{t('trade.tradingSummary')}</b></p>
            </div>
            
            {/* ///////////////  Chart  ///////////////// */}
            <div className='p-tb-30 p-lr-35 pos-relative flex-c-m w-full' style={{ backgroundImage: 'url(/images/trade/mountain.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center' }}>
                <div className='flex-m pos-absolute' style={{ width: 'fit-content', transform: 'rotate(-90deg)', top: 105, left: 0 }}>
                    <div className='flex-m' style={{ width: 'fit-content' }}>
                        <div style={{ height: 15, width: 15, borderRadius: 48, backgroundColor: '#DE9D49' }} />
                        <p className='p-l-8' style={{ color: '#565656', fontWeight: 500 }}>{t('trade.unlock')}</p>
                    </div>
                    <div className='flex-m p-l-20' style={{ width: 'fit-content' }}>
                        <div style={{ height: 15, width: 15, borderRadius: 48, backgroundColor: '#82512D' }} />
                        <p className='p-l-8' style={{ color: '#565656', fontWeight: 500 }}>{t('trade.lock')}</p>
                    </div>
                </div>
                <div style={{ boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 4px 4px 6px 0 #fff', height: 185, width: 185, borderRadius:'100%', padding:15 }}>
                    <div className='p-all-8 w-full h-full' style={{background:' #82512D', boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset', borderRadius:'100%'}}>
                    </div>
                </div>
            </div>

            {/* ///////////////  Sell & Sold Amount  ///////////////// */}
            <div className='flex-sb-m p-t-30 p-lr-35'>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiTag className='fs-25'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.selling')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#7D4E2B', height: 80, width: 9, background: 'rgb(125, 78, 43)', position: 'absolute', bottom: 7, right: -4}} />
                </div>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiShoppingBag className='fs-21'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.sold')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#DE9D49', height: 80, width: 9, background: '#DE9D49', position: 'absolute', bottom: 7, right: -4}} />
                </div>
            </div>

             {/* ///////////////  Transaction  ///////////////// */}
            <div className='p-lr-35 p-t-30'>
                <div className='w-full flex-sb-m p-b-20'>
                    <p style={{ color: '#9B9896' }} className='fs-18'>{t('trade.tradingHistory')}</p>
                    <p style={{ color: '#82512D', fontWeight: 500 }} className='fs-16' >{t('button.all')}</p>
                </div>
                {_.map([1, 2, 3], data => {
                    return (
                        <div className='p-b-15'>
                            <Link component={RouterLink} to={`/trade/transaction-details`} className='max-w-full w-full' underline="none">
                                <div className='flex-sb-m p-b-10 shadow-out bor-10 p-tb-10 p-lr-15'>
                                    <div className='flex-m'>
                                        <CircularProgressWithLabel value={progress} />
                                        <div className='p-l-15' style={{ color: '#565656' }}>
                                            <p>Selling 3,000 Units</p>
                                            <p className='fs-14' style={{ fontWeight: 500 }}>2023-12-21</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: 500, color: '#565656' }}>0.67</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
                <p className='txt-center p-t-20' style={{fontWeight:500, color:'#a0a0a0'}}>- {t('table.endOfTheList')} -</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    buttonStyle: {
        width: 300,
        height: 50,
        marginTop: 20,
    },
}));