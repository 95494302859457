import QRCode from 'qrcode.react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useNotificationLoading from '@utils/useNotificationLoading';

const ReferralQr = () => {
    const styles = useStyles();
    const shareLink = useRef(null);
    const { t } = useTranslation();
    const { username } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const copyLink = () => {
        var textField = document.createElement('textarea');
        textField.innerText = `${window.location.origin}/register?r=${btoa(username)}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        addAlert('', t('general.copied'), 'success', '');
    };

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
            {/* QRCode */}
            <Box style={{ marginBottom: 20 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div
                    style={{ 
                        padding: 25 ,
                        borderRadius: '20px',
                        background: 'white',
                        boxShadow: ' 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'

                    }}
                >
                    <QRCode
                        size='178'
                        value={`${window.location.origin}/register?r=${btoa(username)}`}
                        renderAs="svg"
                        fgColor={'black'}
                    />
                </div>
            </div>
                
            </Box>
            {/* URlink */}
            <Box style={{ marginBottom: 20 }}>
                <Typography ref={shareLink} style={{
                    textAlign: 'center',
                    marginLeft: '15px',
                }}>
                    {`${window.location.origin}/register?r=${btoa(username)}`}
                </Typography>
            </Box>
            {/* ShareButton */}
            <Box style={{ marginBottom: 20 }}>
                <Button
                    onClick={copyLink}
                    variant="contained"
                    className='big-button'
                    fullWidth
                >
                    {t("button.copy")}
                </Button>
            </Box>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    titleBox: {
        // backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
}));

export default ReferralQr