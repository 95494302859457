import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import { Button, IconButton, Link } from '@mui/material';

import { changeLanguage } from '@actions';

export default function ComingSoon() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className='p-lr-35 p-t-50 p-b-50' style={{ minHeight: '90vh' }}>
            <div className='p-lr-20 p-t-50'>
                <img src='/images/comingSoon/comingSoon.png' alt='comingSoon' className='w-full' loading='lazy' />
            </div>
            <div className='txt-center p-t-15' style={{ color: '#82512D' }}>
                <p className='fs-24 lh-10' style={{ fontWeight: 700 }}>{t('comingSoon.title')}</p>
                <p className='p-t-3' style={{ fontWeight: 500 }}>{t('comingSoon.content')}</p>
            </div>

            <div className='p-t-50'>
                <Link component={RouterLink} to={`/`} className='max-w-full flex-c' underline="none">
                    <Button variant="contained" color="primary" className='big-button' style={{ marginTop: 20 }} fullWidth>
                        {t('button.backToHomepage')}
                    </Button>
                </Link>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));