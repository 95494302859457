import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { authFail, authSuccess } from '@actions';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths, reducePaths } from '@utils/Tools';

import { AiOutlineUser } from "react-icons/ai";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";

export default function Login() {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                if (_.size(location?.state) > 0) {
                    let pathListing = reducePaths(location);
                    let path = _.size(pathListing) > 0 ? _.last(pathListing) : '/';
                    let urlLink = path?.pathname;
                    if (path?.search !== '') {
                        urlLink = urlLink + path?.search;
                    }
                    navigate(urlLink, { state: { from: pathListing } });
                } else {
                    navigate('/');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <Box component="form" onSubmit={loginUser}>
                    <Grid container direction="row">
                        <Grid item xs={12}>
                            <InputLabel>{t('user.username')}</InputLabel>
                            <TextField
                                variant="standard"
                                placeholder={t('user.username')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AiOutlineUser className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type="username"
                                autoComplete="username"
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} justifyContent="center" alignItems="center">
                            <InputLabel>{t('user.password')}</InputLabel>
                            <TextField
                                variant="standard"
                                placeholder={t('user.password')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdLockOutline className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type={state.showPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.password}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                            />
                        </Grid>
                        <Grid className='p-b-5' item container xs={12} direction="row" justifyContent="flex-end" alignItems="center">
                            <Link component={RouterLink} to={`/forgot-password`} state={{ from: storePaths(location) }} underline="hover">
                                <Typography className={classes.forgotLink}>{t('forgetPassword.title')}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <Box className='flex-c w-full'>
                        <Button variant="contained" color="primary" type="submit" className='big-button' style={{ marginTop: 10 }} fullWidth disabled={(_.size(state.username) > 0 && _.size(state.password) > 0) ? false : true}>
                            {t('button.login')}
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 5,
        paddingBottom: 10,
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10,
    },
    forgotLink: {
        fontSize: 12,
        color: theme.palette.gold,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
}));