import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button, Drawer, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { MdClose } from "react-icons/md";

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery, storePaths, currencyFormat } from '@utils/Tools';

import "@css/tree.css";
import theme from '../../Theme';
import Payment from './Payment';

export default function Plans() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const [activePlans, setActivePlans] = useState({});
    const [rate, setRate] = useState({});
    const [openPlan, setOpenPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/active-plans`, {type:1}).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data, rate } = response;

                if (status) {
                    setActivePlans(data);
                    setRate(rate);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const handleOpenDrawer = (data) => {
        setOpenPlan(true);
        setSelectedPlan(data);
    }

    const handleChange = (value) => {
        setOpenPlan(false);
        navigate(`/payment/${value}`, { state: { from: storePaths(location) } });
    };

    return (
        <Box style={{ padding: '0 0 80px', paddingLeft: 15, paddingRight: 15, minHeight: '90vh', marginTop: 65, overflowY: 'hidden' }}>
            <Box className='p-lr-10 p-b-46' >
                {/* <Typography variant='h6' className='p-tb-12 p-lr-16 txt-center' color="white"><b>{t('plan.title')}</b></Typography> */}

                <div className='flex-c m-t-10'>
                    <div className='p-r-20'>
                        <Typography style={{ fontSize: '0.875rem', fontWeight: 600, color: theme.palette.gold }}>{t('plan.pageDesc1')}</Typography>
                        <Typography style={{ fontSize: '0.625rem', fontWeight: 400, color: theme.palette.gray.main, paddingRight: 30 }}>{t('plan.pageDesc2')}</Typography>
                    </div>
                    <div>
                        <img src={'/images/logos/logo-no-text.png'} className='w-full' alt="plan_logo" loading='lazy' style={{ height: 82, width: 82, maxWidth: 'fit-content', borderRadius: 6}}  />
                    </div>
                </div>
                <Grid container className="m-t-30">
                    {
                        _.size(activePlans) > 0 &&
                        _.map(activePlans, (plan, index) => {
                            return (
                                <Grid item xs={12} key={index} className='p-t-15'>
                                    <div className='p-tb-10 p-lr-15 flex-sb-m' style={{  borderRadius: 6, background: '#28292E', border: 1, borderStyle: 'solid', borderColor: '#F7A6001A' }}>
                                        <Typography variant='h6' style={{ color: 'white', fontSize: '1rem' }}><b>{plan?.name ? plan?.name?.[i18n.language] : plan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></Typography>
                                        <div >
                                            <Button variant="contained" style={{width:'100%', minWidth:'unset', fontSize: '0.75rem'}} onClick={() => handleOpenDrawer(plan)}>
                                                {t('plan.select')}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {/* old design backup code */}
                {/* <Grid container>
                    {
                        _.size(activePlans) > 0 &&
                        _.map(activePlans, (plan, index) => {
                            return (
                                <Grid item xs={6} key={index} className='p-t-70 p-b-25 p-lr-10'>
                                    <div className='p-b-15 p-lr-15 pos-relative' style={{  borderRadius: 8, background: '#253240', boxShadow: 'rgb(0 0 0) 4px 4px 7px 0px, inset rgb(255 255 255 / 27%) 2px 4px 5px 0px' }}>
                                        <div >
                                            <img src={plan?.plan_logo ? plan?.plan_logo?.file_name : '/images/logos/logo-no-text.png'} className='w-full' alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, marginTop:-50}}  />
                                        </div>
                                        <div className='txt-center p-b-10 w-full p-t-20'>
                                            <Typography variant='h6' className='p-b-10' style={{ color: '#CFCCCC', lineHeight: 1.1 }}><b>{plan?.name ? plan?.name?.[i18n.language] : plan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></Typography>
                                            <Typography variant='body2' style={{ color: '#CFCCCC', minHeight:20 }}>{_.size(plan.description) > 0 ? (plan?.description?.[i18n.language]?.title ? plan?.description?.[i18n.language]?.title : plan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title) : ""}</Typography>
                                            <Typography variant='body2' style={{ color: '#CFCCCC', minHeight:20 }}>{_.size(plan.description) > 0 ? (plan?.description?.[i18n.language]?.subtitle ? plan?.description?.[i18n.language]?.subtitle : plan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle) : ""}</Typography>
                                        </div>
                                        <div >
                                            <Button variant="contained" className='small-button' style={{width:'100%', minWidth:'unset'}} fullWidth onClick={() => handleOpenDrawer(plan)}>
                                                $ {currencyFormat(plan?.bv * rate?.sell)}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid> */}

                <Drawer
                    anchor={'bottom'}
                    open={openPlan}
                    onClose={() => setOpenPlan(false)}
                    disableScrollLock={true}
                    classes={{ paper: styles.paperAnchorBottom }}
                >
                    <div className='p-tb-30 p-lr-50'>
                        <div className='dis-flex flex-m flex-sb-m'>
                            <p className='w-full txt-center txt-content'><b>{t('plan.selectedPlan')}</b></p>
                            <IconButton onClick={() => setOpenPlan(false)}>
                                <MdClose className='fs-21' />
                            </IconButton>
                        </div>
                        <Grid container spacing={2} className='p-tb-20 dis-flex flex-c-m'>
                            <Grid item xs={4}>
                                <div style={{ maxWidth: 123, maxHeight: 123, aspectRatio: 1 / 1 }}>
                                    <img src={selectedPlan?.plan_logo ? selectedPlan?.plan_logo?.file_name : '/images/logos/logo-no-text.png'} className='s-full' alt="plan_logo" loading='lazy' />
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div style={{ paddingBottom: 10 }}>
                                    <Typography variant='h6'><b>{selectedPlan?.name ? selectedPlan?.name?.[i18n.language] : selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE] }</b></Typography>
                                    <Typography variant='body1' style={{ color: '#AEAEAE' }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.title ? selectedPlan?.description?.[i18n.language]?.title : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title) : ""}</Typography>
                                    <Typography variant='body1' style={{ color: '#AEAEAE' }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.subtitle ? selectedPlan?.description?.[i18n.language]?.subtitle : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle) : ""}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='flex-c-m'>
                            <Button variant="contained" fullWidth className='big-button' onClick={() => handleChange(selectedPlan?.id)}>
                                {t('plan.selectValue', { value: currencyFormat(selectedPlan?.bv * rate?.sell) })}
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    }
}));