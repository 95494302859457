import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import RegisterForm from '@layouts/RegisterForm';
import _, { isNull, isUndefined } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '@actions';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { reducePaths } from '@utils/Tools';
import { BiChevronLeft } from "react-icons/bi";

const Register = memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);
    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };
    let navigate = useNavigate();
    let location = useLocation();
    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    return (

        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <Box>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <RegisterForm />
                    </Grid>
                </Box>
            </div>
        </div>
    )
});

export default Register;

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));