import React from 'react';
import { changeLanguage } from '@actions';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

export default function Language() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div style={{ marginTop: 80 }}>
            <div className="flex p-lr-20 p-tb-10">
                <div className='flex-sb-m pointer p-tb-10' onClick={() => changeSystemLanguage('en')}>
                    <p className='fs-14' style={{ color: '#848D9B' }}>{t('general.en')}</p>
                    {
                        language === 'en' ?
                            <img src="/images/selected.png" alt='selected' style={{ objectFit: 'cover', height: '9px', width: '12px' }} />
                            : null
                    }
                </div>
                <div style={{ border: '1px solid #1B1B23' }}></div>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => changeSystemLanguage('cn')}>
                    <p className='fs-14' style={{ color: '#848D9B' }}>{t('general.cn')}</p>
                    {
                        language === 'cn' ?
                            <img src="/images/selected.png" alt='selected' style={{ objectFit: 'cover', height: '9px', width: '12px' }} />
                            : null
                    }
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));