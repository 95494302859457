import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';

import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline, MdOutlineEmail } from "react-icons/md";

export default function ResetPassword() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const [state, setState] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        token: searchParams.get("token")
    });

    const [option, setOption] = useState({
        password_show: false,
        password_confirmation_show: false,
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        let postData = {
            email: state.email,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            token: state.token,
        }
        postUrl('/reset-password', postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                navigate('/login');
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += ` ${value}`;
                })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <InputLabel>{t('user.email')}</InputLabel>
                        <TextField
                            variant="standard"
                            placeholder={t('user.email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdOutlineEmail className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            type="email"
                            autoComplete="email"
                            color='primary'
                            value={state.email}
                            onChange={({ target }) => setState({ ...state, email: target.value })}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>{t('user.password')}</InputLabel>
                        <TextField
                            variant="standard"
                            placeholder={t('user.password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdLockOutline className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handlePasswordShow('password_show')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {option?.password_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            autoComplete="password"
                            color='primary'
                            value={state.password}
                            type={option?.password_show ? 'text' : 'password'}
                            onChange={({ target }) => setState({ ...state, password: target.value })}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>{t('user.confirmPassword')}</InputLabel>
                        <TextField
                            variant="standard"
                            placeholder={t('user.confirmPassword')}
                            type={option?.password_confirmation_show ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdLockOutline className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handlePasswordShow('password_confirmation_show')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {option?.password_confirmation_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            autoComplete="password"
                            color='primary'
                            value={state.passwordConfirmation}
                            onChange={({ target }) => setState({ ...state, passwordConfirmation: target.value })}
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={resetPassword}
                        className='big-button'
                        style={{ marginTop: 20 }}
                        fullWidth
                        disabled={(_.size(state.email) > 0 && _.size(state.password) > 0 && _.size(state.passwordConfirmation) > 0) ? false : true}
                    >
                        {t('button.reset')}
                    </Button>
                </Box>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 5,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '70%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));