import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Typography, Grid, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat, storePaths } from '@utils/Tools';

import TransactionItem from '@components/TransactionItem';
import WalletSliderShow from '@layouts/WalletSliderShow';

const Transaction = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const styles = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);

    const [searchParams] = useSearchParams();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('all');
    const [walletSliderShow, setWalletSliderShow] = useState([]);
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [selectedWalletId, setSelectedWalletId] = useState(null);
    const [state, setState] = useState({ id: '', });
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [totalBalance, setTotalBalance] = useState(0);
    const [wallet, setWallet] = useState(null);


    const filterOptions = ['all', 'debit', 'credit'];

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    // useEffect(() => {
    //     if (selectedWalletId !== null) {
    //         fetchData(selectedWalletId);
    //     }
    // }, [selectedOption, selectedWalletId]);

    const handleOptionSelect = (option) => {
        if (option !== selectedOption) {
            setWalletTransactions([]);
        }
        setSelectedOption(option);
        setPage(1);

        handleDrawerClose();
    };

    // Callback function to handle the active index change in WalletSliderShow
    const handleActiveIndexChange = (activeIndex) => {
        // Use the active index to get the corresponding wallet ID and shop ID
        const selectedWalletId = walletSliderShow[activeIndex]?.id;

        // Perform any additional logic or state updates if needed
        setSelectedWalletId(selectedWalletId);
        setPage(1);
        setWalletTransactions([]);

        // Fetch wallet transactions based on the current page and selectedWalletId
        // fetchData(selectedWalletId);
    };

    // Fetch wallet transactions based on the current page and selectedWalletId
    // const fetchData = (selectedWalletId) => {
    //     if (selectedWalletId !== null) {
    //         setLoading(true);
    //         isMountedRef.current = true;
    //         // Include selectedWalletId and selectedShopId in the params object
    //         let params = {
    //             id: selectedWalletId,
    //             filter_option: selectedOption,
    //             per_page: 10,
    //             page: page,
    //         };
    //         getUrl(`/member-transaction-list`, params).then((response) => {
    //             setLoading(false);
    //             if (response.status) {
    //                 let { data, current_page, last_page } = response.data;
    //                 setTotal(last_page);
    //                 if (page < last_page) {
    //                     window.addEventListener('scroll', handleScroll);
    //                 }
    //                 if (page > 1) {
    //                     setWalletTransactions(_.concat(walletTransactions, data));
    //                 } else {
    //                     setWalletTransactions(data);
    //                 }
    //             }
    //         }).catch((error) => {
    //             setLoading(false);
    //             addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //         });
    //         return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
    //     }
    // };    

    // fetch all the wallet data
    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (typeof (searchParams.get("w")) === 'string') {
            try {
                let id = parseInt(atob(searchParams.get("w")));

                if (!isNaN(id)) {
                    setState({ ...state, id: id, });
                    let apiData = {
                        id: id,
                        filter_option: selectedOption,
                        per_page: 10,
                        page: page,
                    }

                    getUrl(`/member-transaction-list`, apiData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            let { data, current_page, last_page } = response.data;
                            setTotal(last_page);
                            setCurrPage(current_page);
                            setSelectedWalletId(id);
                            // const filterOptionParam = searchParams.get("filter_option");
                            // setSelectedOption(filterOptionParam || 'all');

                            if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                            if (page > 1) { setWalletTransactions(_.concat(walletTransactions, data)); }
                            else { setWalletTransactions(data); }
                        } else {
                            navigate(`/`);
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                        navigate(`/`);
                    });
                } else {
                    setLoading(false);
                    navigate(`/`);
                }
            } catch (error) {
                setLoading(false);
                navigate(`/`);
            }
        } else {
            setLoading(false);
            navigate(`/`);
        }

        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll); }
    }, [page, selectedOption, searchParams]);

    useEffect(() => {
        getUrl(`/member-wallets`).then((response) => {
            setLoading(false);
            if (response.status) {

                // setWalletSliderShow(response.data);
                if (typeof (searchParams.get("w")) === 'string') {
                    let id = parseInt(atob(searchParams.get("w")));
                    let _wallet = _.find(response.data, { id })

                    // Update the total balance in the state
                    setTotalBalance(parseFloat(_wallet?.wallet_balances?.[0]?.balance || 0));
                    setWallet(_wallet);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, [searchParams]);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom) { setPage(page + 1); }
    }

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const allowOtherIsBoolean = _.some(wallet?.setting_transfer?.[wallet.id], (toIdObj) => {
        return toIdObj?.allow_others;
    });
    const allowSelfIsBoolean = _.some(wallet?.setting_transfer?.[wallet.id], (toIdObj) => {
        return toIdObj?.allow_self;
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 60, alignItems: 'center' }}>
            {/* Title Transaction */}

            {/* <div style={{ padding: '0 35px 20px' }}>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}> */}
            {/* {t('transaction.title')} */}
            {/* {wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']}
                </Typography>
            </div> */}

            {/* Swiper Wallet Card */}
            {/* <div className={styles.sliderShow}>
                {accessToken && _.size(walletSliderShow) > 0 && (
                    <WalletSliderShow
                        listing={walletSliderShow}
                        onActiveIndexChange={handleActiveIndexChange}
                        selectedWalletId={selectedWalletId}
                    />
                )}
            </div> */}

            <div className="w-full p-lr-20 m-t-15">
                <div className={`${styles.walletBalanceLayout} m-b-15`}>
                    <div className='flex-sb-m'>
                        <Typography variant='body1' className='txt-content' style={{ fontWeight: 500, color: theme.palette.gray.main, fontSize: '0.75rem' }} >
                            {t('wallet.balance')}
                        </Typography>
                        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                            {/* {t('transaction.title')} */}
                            {wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']}
                        </Typography>
                    </div>
                    <Typography variant='h3' className='txt-title' style={{ fontWeight: 600, color: 'white', fontSize: '1.875rem', marginTop: 10 }}>
                        {`${(wallet?.code==='gc'||wallet?.code==='nc'||wallet?.code==='ngc')?'':'$'} ${currencyFormat(totalBalance)}`}
                    </Typography>
                </div>

                <div className="m-b-15 flex-sb-m" style={{ gap: 10 }}>
                    {
                        wallet?.setting_transfer?.[wallet.id] && allowOtherIsBoolean &&
                        <div className="flex-c-m" style={{ flex: 1, backgroundColor: '#16171C', borderRadius: 8, padding: 10, cursor: 'pointer' }} onClick={() => goToLink(`/transfer?w=${btoa(wallet?.id)}`)}>
                            <img src="images/wallets/transfer.png" alt="transfer Icon" style={{ height: 18, width: 18, marginRight: 10 }} loading='lazy' />
                            <Typography style={{ fontSize: '0.625rem' }}>{t('walletListing.transfer')}</Typography>
                        </div>
                    }
                    {
                        wallet?.setting_transfer?.[wallet.id] && allowSelfIsBoolean &&
                        <div className="flex-c-m" style={{ flex: 1, backgroundColor: '#16171C', borderRadius: 8, padding: 10, cursor: 'pointer' }} onClick={() => goToLink(`/convert?w=${btoa(wallet?.id)}`)}>
                            <img src="images/wallets/transfer.png" alt="transfer Icon" style={{ height: 18, width: 18, marginRight: 10 }} loading='lazy' />
                            <Typography style={{ fontSize: '0.625rem' }}>{t('walletListing.convert')}</Typography>
                        </div>
                    }
                    {
                        wallet?.setting_withdraw &&
                        <div className="flex-c-m" style={{ flex: 1, backgroundColor: '#16171C', borderRadius: 8, padding: 10, cursor: 'pointer' }} onClick={() => goToLink(`/withdraw?w=${btoa(wallet?.id)}`)}>
                            <img src="images/wallets/withdraw.png" alt="transfer Icon" style={{ height: 18, width: 18, marginRight: 10 }} loading='lazy' />
                            <Typography style={{ fontSize: '0.625rem' }}>{t('walletListing.withdrawal')}</Typography>
                        </div>
                    }
                    {
                        (wallet?.setting_deposit || wallet?.setting_token_deposit) &&
                        <div className="flex-c-m" style={{ flex: 1, backgroundColor: '#16171C', borderRadius: 8, padding: 10, cursor: 'pointer' }} onClick={() => goToLink(`/deposit?w=${btoa(wallet?.id)}`)}>
                            <img src="images/wallets/deposit.png" alt="transfer Icon" style={{ height: 18, width: 18, marginRight: 10 }} loading='lazy' />
                            <Typography style={{ fontSize: '0.625rem' }}>{t('walletListing.deposit')}</Typography>
                        </div>
                    }
                </div>
            </div>

            {/* Transaction History */}
            <Grid container style={{ marginTop: 20, padding: '0 35px' }}>
                {/* Left side (Transaction) */}
                {/* <Grid item xs={8}>
                    <p className='fs-18 lh-15 txt-title' style={{verticalAlign: 'middle'}}>
                        {t('transaction.title')}
                    </p>
                </Grid> */}

                {/* Right side (All) */}
                {/* <Grid item xs={4} className={styles.viewAll} onClick={handleDrawerOpen}>
                    <Typography variant="subtitle2"  style={{ lineHeight: '1.5', verticalAlign: 'middle' }}>
                        {t(`transaction.filterOptions.${selectedOption}`)}
                    </Typography>
                    <ArrowForwardIosIcon className='txt-title' style={{ marginLeft: 10, width: 14 }} />
                </Grid> */}

                <Grid item xs={12} className="flex-m" style={{ gap: 20 }}>
                    {filterOptions.map((option, index) => (
                        <div key={option} onClick={() => handleOptionSelect(option)} style={{ cursor: 'pointer', paddingBottom: 10, borderBottom: '1px solid', borderBottomColor: selectedOption === option ? '#F7A600' : 'transparent' }} >
                            <Typography style={{ color: 'white', fontSize: '0.75rem', fontWeight: 600 }} >
                                {t(`transaction.filterOptions.${option}`)}
                            </Typography>
                        </div>
                    ))}
                </Grid>

                {/* Wallet Listing */}
                {walletTransactions.length > 0 ? (
                    // Wallet Listing
                    walletTransactions.map((transaction, key) => (
                        <Grid item xs={12} key={transaction.id} style={{ padding: '10px 0', cursor: 'pointer' }} >
                            <Box>
                                <TransactionItem
                                    transaction={transaction}
                                    selectedWalletId={selectedWalletId}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Grid>
                    ))
                ) : (
                    // Display "NO DATA" when walletTransactions is empty
                    <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 20 }}>
                        <img src="images/wallets/transaction_icon.png" alt="Transaction Icon" style={{ marginBottom: '10px', width: 100 }} loading='lazy' />
                        <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20 }}>
                            {t('table.noTransactionYet')}
                        </Typography>
                    </Grid>
                )}

                {walletTransactions.length > 0 && (
                    <Grid item xs={12} className={styles.endFontStyle}>
                        <Typography style={{ color: '#B5B5B5' }}>
                            {currPage === total ? t('table.endOfTheList') : t('table.loadingList')}
                        </Typography>
                    </Grid>
                )}

                {/* Drawer for filter options */}
                <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose} disableScrollLock={true} classes={{ paper: styles.paperAnchorBottom }}>
                    <div className={styles.drawerContent}>
                        <Typography variant="h6" style={{ color: '#cfcccc', fontWeight: 'bold', margin: '10px 0', textAlign: 'center' }}>
                            {t('transaction.options')}
                        </Typography>
                        <List>
                            {filterOptions.map((option, index) => (
                                <React.Fragment key={option}>
                                    <ListItem style={{ cursor: 'pointer' }} onClick={() => handleOptionSelect(option)} >
                                        <ListItemText primary={t(`transaction.filterOptions.${option}`)} />
                                    </ListItem>
                                    {index < filterOptions.length - 1 && <div className='divider-design'></div>}
                                </React.Fragment>
                            ))}
                        </List>
                    </div>
                </Drawer>
            </Grid>
        </div >
    );
}

const useStyles = makeStyles((theme) => ({
    colorBrow: {
        color: theme.palette.primary.main
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    viewAll: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer'
    },
    sliderShow: {
        width: '1000px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        padding: '0 30px'
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
    drawerContent: {
        padding: '10px 35px 0px'
    },
    walletBalanceLayout: {
        backgroundColor: '#16171C',
        padding: 12,
        borderRadius: 12,
    },
}));

export default Transaction;