// AUTH
import Login from '@auths/Login';
import Register from '@auths/Register';
import ForgetPassword from '@auths/ForgetPassword';
import ResetPassword from '@auths/ResetPassword';
import ResetSecurityPassword from '@auths/ResetSecurityPassword';
import SplashScreen from '@auths/SplashScreen';
import SSOLogin from '@auths/SSOLogin';

// GUEST
import Maintenance from '@pages/Maintenance';
import ComingSoon from '@pages/ComingSoon';

// ACCOUNT
import Dashboard from '@pages/Dashboard';
import Account from '@pages/Account';
import Profile from '@pages/Account/BasicInfo';
import Security from '@pages/Account/Security';
import Password from '@pages/Account/Password';
import SecurityPassword from '@pages/Account/SecurityPassword';
import TwoFactorAuth from '@pages/Account/TwoFactorAuth';
import CryptoAddress from '@pages/Account/CryptoAddress';
import Recruit from '@pages/Account/Recruit';
import ReferralQr from '@pages/Account/ReferralQr';
import Language from '@pages/Account/Language';
import Genealogy from '@pages/Genealogy/Genealogy';
// import Kyc from '@pages/Account/Kyc';

// WALLET
import Wallet from '@pages/Wallet/Wallet';
import Transaction from '@pages/Wallet/Transaction';
import Transfer from '@pages/Wallet/Transfer';
import Convert from '@pages/Wallet/Convert';
import Deposit from '@pages/Deposit';
import Withdraw from '@pages/Withdraw';

// BONUS
import Bonus from '@pages/Wallet/Bonus';

// PLAN
import Plans from '@pages/Plan';
import Payment from '@pages/Plan/Payment';
import PlanHistory from '@pages/Plan/PlanHistory';

//NOTICE
import Notice from '@pages/Notice';
import NoticeDetail from '@pages//Notice/Detail';

//TRADE
import Trade from '@pages/Trade';
import TransactionDetails from '@pages/Trade/TransactionDetails';
import Trading from '@pages/Trade/Trading';
import TradeSellingForm from '@pages/Trade/TradeSellingForm';
import Marketplace from '@pages/Trade/Marketplace';

// BONUS
import Pnl from '@pages/BotTrading';

// GAMES
import Game from '@pages/Game';
import GameTopUp from '@pages/Game/GameTopUp';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    {
        path: "/",
        component: <Dashboard />,
        title:'title.dashboard',
        hideBar: true,
    },
    // ACCOUNT
    {
        path: "/account",
        component: <Account />,
        title:'title.account',
        hideBar: true,
    },
    {
        path: "/account/security",
        component: <Security />,
        title:'title.security',
        hideBar: false,
    },
    {
        path: "/account/profile",
        component: <Profile />,
        title:'title.profile',
    },
    {
        path: "/account/password",
        component: <Password />,
        title:'title.password',
    },
    {
        path: "/account/security-password",
        component: <SecurityPassword />,
        title:'title.securityPassword',
    },
    {
        path: "/account/2fa",
        component: <TwoFactorAuth />,
        title:'title.twoFactorAuth',
    },
    {
        path: "/account/withdrawal-address",
        component: <CryptoAddress />,
        title:'title.cryptoAddress',
    },
    {
        path: "/account/recruit",
        component: <Recruit />,
        title:'login.registerAcc',
    },
    {
        path: "/account/referral-qr",
        component: <ReferralQr />,
        title:'title.referralQr',
    },
    {
        path: "/account/language",
        component: <Language />,
        title:'general.language',
    },
    /* {
        path: "/account/kyc",
        component: <Kyc />,
        title:'title.kyc',
    }, */
    //NOTICE
    {
        path: "/account/notices",
        component: <Notice />,
        title: 'title.notice',
    },
    {
        path: "/account/notice/:id",
        component: <NoticeDetail />,
        title: 'title.noticeDetail',
    },
    // GENEALOGY
    {
        path: "/community/:page",
        component: <Genealogy/>,
        title:'title.community',
        hideBar: true,
    },
    // WALLET
    {
        path: "/funds/:page",
        component: <Wallet />,
        title:'title.wallet',
        hideBar: true,
    },
    {
        path: "/transaction",
        component: <Transaction />,
        title:'title.transaction',
    },
    {
        path: "/transfer",
        component: <Transfer />,
        // title:'title.transfer',
    },
    {
        path: "/convert",
        component: <Convert />,
        // title:'title.convert',
    },
    {
        path: "/deposit",
        component: <Deposit />,
        title:'title.deposit',
    },
    {
        path: "/withdraw",
        component: <Withdraw />,
        title:'title.withdraw',
    },
    // BONUS
    {
        path: "/bonus",
        component: <Bonus />,
        // title:'title.bonus',
    },
    // PLAN
    {
        path: "/plans",
        component: <Plans/>,
        // component: <ComingSoon />,
        title:'plan.title',
        hideBar: false,
        hideBack: true,
    },
    {
        path: "/payment/:id",
        component: <Payment/>,
        // component: <ComingSoon />,
        title:'plan.confirm',
    },
    {
        path: "/plan-histories",
        component: <PlanHistory/>,
        title:'title.planHistory',
    },
    // TRADE
    {
        path: "/trade",
        // component: <Trade/>,
        component: <ComingSoon />,
        title:'title.trade',
    },
    // {
    //     path: "/trade/trading",
    //     component: <Trading/>,
    //     title:'title.trade',
    // },
    // {
    //     path: "/trade/transaction-details",
    //     component: <TransactionDetails/>,
    //     title:'title.trade',
    // },
    // {
    //     path: "/trade/marketplace",
    //     component: <Marketplace/>,
    //     title:'title.marketplace',
    // },
    // {
    //     path: "/trade/selling-form",
    //     component: <TradeSellingForm/>,
    //     title:'title.marketplace',
    // },
    // BOT TRADING
    {
        path: "/pnl",
        component: <Pnl />,
        title:'title.pnl',
    },
    {
        path: "/games",
        component: <Game />,
        title:'title.games',
    },
    {
        path: "/games-topup/:id",
        component: <GameTopUp />,
        title:'title.topup',
    },
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/maintenance",
        component: <Maintenance/>,
        exact: true
    },
    {
        path: "/login",
        component: <Login />,
        title: 'button.login'
    },
    {
        path: "/reset-security-password",
        component: <ResetSecurityPassword />,
        title:'forgetSecurityPassword.title',
    },
    {
        path: "/sso-login",
        component: <SSOLogin />,
        title: 'button.login',
        hideBack: true,
    },
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/register",
        component: <Register />,
        title:'title.register',
        // hideBar: true,
    },
    {
        path: "/forgot-password",
        component: <ForgetPassword />,
        title:'forgetPassword.title',
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        title:'resetPassword.title',
    },
    {
        path: "/landing",
        component: <SplashScreen />,
        title:'title.splashScreen',
        hideBar: true,
    },
];