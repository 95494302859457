import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _, { isNull, isUndefined } from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import { Box, Button, IconButton, Link, Typography,  Menu, MenuItem, Tooltip, Grid} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Swiper, SwiperSlide } from "swiper/react";

import { changeLanguage } from '@actions';
import { storePaths } from '@utils/Tools';

import "swiper/css";
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { GoGlobe } from "react-icons/go";

export default function SplashScreen() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box className="pos-relative" style={{ minHeight: '100vh', backgroundSize: 'contain', backgroundPosition: 'center' }}>
            {/* backgroundImage:`url('../images/auth/login-bg.png')` */}
            <div className='flex-sb-m p-lr-20 p-t-15'>
                <div className='p-lr-8 p-tb-4' style={{ backgroundColor: '#26292E', borderRadius: 2 }}>
                    <Typography color={theme.palette.gray.main} style={{ fontWeight: 400, fontSize: 12 }}>{t('dashboard.serverTime')}: {serverTime.time} {serverTime.date}</Typography>
                </div>
                <div className='flex-m p-l-10'>
                    <Tooltip title={t('general.language')} arrow><IconButton onClick={handleClick} ><GoGlobe className="img-style" style={{ color: 'white' }} /></IconButton></Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        slotProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                        <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                    </Menu>
                </div>
            </div>
            
            <Box className="p-tb-30 w-full flex-col-c" style={{ paddingTop: '10vh' }}>
                <img src="images/logos/logo.png" style={{ width: "33%" }} alt="bg-img" loading='lazy'  />
                
                <div className='txt-content p-lr-20' style={{ lineHeight: 0.7, paddingTop: 30 }}>
                    <p className='txt-center' style={{ fontWeight: 600, fontSize: '1rem' }}><b>{t('login.welcomeTo')} {t('title.company')}</b></p>
                </div>
                <Grid container className='p-t-30 p-lr-10'>
                    <Grid item xs={6} md={6} className='p-lr-10'>
                        <Link component={RouterLink} to={`/login`} state={{ from: storePaths(location) }}>
                            <Button variant="contained" className='big-button' style={{ marginTop: 20, backgroundColor: '#26292E', color: 'white' }} fullWidth>
                                {t('button.login')}
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} md={6} className='p-lr-10'>
                        <Link component={RouterLink} to={`/register`} state={{ from: storePaths(location) }}>
                            <Button variant="contained" className='big-button' style={{ marginTop: 20, backgroundColor: theme.palette.gold,  color: '#16171C' }} fullWidth>
                                {t('login.signUp')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({

}));