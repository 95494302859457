import { makeStyles } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import _, { isNull, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';

import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';

import { Tab, Tabs, Typography, Grid, Link } from '@mui/material';


const Notice = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({});
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState('news');
    const [coverImg, setCoverImg] = useState({});
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const GENERAL_TYPE = ['news', 'announcement'];

    useEffect(() => {
        if (!isNull(searchParams.get("type"))) {
            setLoading(true);
            let params = searchParams.get("type");

            if (_.includes(GENERAL_TYPE, params)) {
                setLoading(false);
                setActiveTab(searchParams.get("type"));
            } else {
                setLoading(false);
                navigate(`/account/notices?type=${activeTab}`);
            }
        }

    }, [searchParams]);

    useEffect(() => {
        getUrl(`/get-notice-images`).then(response => {
            if (response.status) {
                setCoverImg(response?.data?.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, [])

    useEffect(() => {
        setLoading(true);
        let params = {
            type: activeTab,
            show: "member",
            hidden: ["content", "status_log"],
            all: true
        }
        getUrl(`/all-notices`, params).then(response => {
            setLoading(false);
            setState(response.data);
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }, [activeTab]);

    const handleTabChange = (event, newValue) => {

        // Always update the active tab to handle toggling
        setActiveTab(newValue);
        navigate(`/account/notices?type=${newValue}`, { state: { from: storePaths(location) } })
    };

    const getImageLink = (info) => {
        let imageDetail = null;
        if (_.size(info?.content_images) > 0) {
            let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
            if (!isUndefined(tempData)) {
                imageDetail = tempData?.file_name;
            } else {
                let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                if (defaultData) {
                    imageDetail = defaultData?.file_name;
                }
            }
        }

        return imageDetail;
    }

    return (
        <div style={{ marginTop: 80, marginBottom: 100 }}>
            <div style={{ padding: '0 30px' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    className={styles.optionNoticeLayout}
                    centered
                >
                    {
                        _.size(GENERAL_TYPE) > 0 && _.map(GENERAL_TYPE, (type, typeIndex) => {
                            return (
                                <Tab
                                    key={typeIndex}
                                    className={`${styles.tabButton} ${activeTab === type && styles.activeTab}`}
                                    value={type}
                                    label={
                                        <Typography variant="body1" style={{ color: activeTab === type ? '#121C28' : 'inherit', fontWeight:500 }}>
                                            {t(`notice.${type}`)}
                                        </Typography>
                                    }
                                />
                            )
                        })
                    }
                </Tabs>
                {
                    loading ?
                        null :
                        (
                            _.size(state) > 0 ?
                                <div style={{ paddingTop: 30 }}>
                                    {_.map(state, data => {
                                        let imageLink = null;
                                        if (data?.content_type === "image" && _.size(data?.content_images) > 0) {
                                            imageLink = getImageLink(data);
                                        }

                                        if (isNull(imageLink)) {
                                            imageLink = '../images/dashboard/example.png';
                                        }

                                        if (isNull(data.cover_image)) {
                                            imageLink = '/images/dashboard/example.png';
                                        } else {
                                            let filename = _.filter(coverImg, {id: data.cover_image});
                                            imageLink = filename[0]?.file_name;
                                        }

                                        if (imageLink) {
                                            return (
                                                <Link component={RouterLink} to={`/account/notice/${data?.id}`} state={{ from: storePaths(location) }} underline='none' key={data.id}>
                                                    <div className='pos-relative flex-r p-b-30'>
                                                        <div className='news-box'>
                                                            <div className='p-l-70' style={{ fontWeight: 500 }}>
                                                                <p className='txt-truncate2 fs-15'>{data?.title[i18n.language] ? data.title[i18n.language] : data?.title?.cn}</p>
                                                                <p className='fs-12' style={{ color: '#9D9D9D' }}>{data?.created_at ? moment(data?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='news-img-pos'>
                                                            <img src={imageLink} alt='new-img' className='w-full max-w-full' style={{ borderRadius: '25px 7px', boxShadow:'3px 4px 4px 0 #0002' }} />
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                                :
                                <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={'/images/genealogy/downline_none.png'} alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                                        {t('table.noRecord')}
                                    </Typography>
                                </Grid>

                        )
                }
            </div>
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    optionNoticeLayout: {
        borderRadius: '48px',
        background: '#253240',
        boxShadow: '2px 2px 3px 0px rgba(200, 252, 255, 0.26), 3px 3px 3px 0px #000 inset;'
    },
    tabButton: {
        borderRadius: '48px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        color: '#E1AC46',
    },
    activeTab: {
        color: '#121C28',
        // background: 'linear-gradient(91deg, #86552E 2.67%, #DDA253 99.35%)',
        background:'#EEB23E',
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 100,
        marginBottom: 30,
    },
}));

export default Notice;