import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';

import { Grid, Typography, Box, AppBar, Tabs, Tab, Divider, createTheme } from '@mui/material';
import GenealogySponsor from './GenealogySponsor';
import "@css/tree.css";

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery, storePaths } from '@utils/Tools';
import { MuiThemeProvider } from '@material-ui/core';
import theme from '../../Theme';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Genealogy(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'sponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0
    });

    const { t } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();
    let { page } = useParams();
    let username = useQuery().get('u');

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        if (page) {
            if (_.includes(['sponsor', 'placement'], page)) {
                setState({
                    ...state,   
                    pageIndex: page === 'placement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    /* useEffect(() => {
        isMountedRef.current = true;
        // setLoading(true);

        if (state.pageIndex !== null) {
            let apiUrl = 'sponsor-genealogy';
            if (state.pageIndex === 0) {
            } else if (state.pageIndex === 1) {
                apiUrl = 'placement-genealogy';
            }

            getUrl(apiUrl).then(response => {
                if (response.status && isMountedRef.current) {
                    setState({ ...state, totalDirectDownlines: response.data.total_direct_downlines, totalDownlines: response.data.total_downlines });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                // setLoading(false);
            });
        }

        return () => isMountedRef.current = false;
    }, [state.pageIndex]); */

    const displayPage = () => {
        if (state.subPage == 'sponsor') {
            return <GenealogySponsor username={username} />
        }
        // else if(state.subPage =='genealogyPlacement'){
        //     return <GenealogyPlacementNew />
        // }
    }

    const handleChange = (event, newValue) => {
        navigate(`/community/${newValue === 1 ? 'placement' : 'sponsor'}`, { state: { from: storePaths(location) } });
    };

    return (
        <Box style={{ padding: '0 0 60px', minHeight: '90vh', overflowY: 'hidden' }}>
            <Box className='p-b-46' >
                {!_.isNull(state.pageIndex) ? <>
                    {/* <MuiThemeProvider theme={tabStyle}>
                        <AppBar position="static" color="default" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <Tabs
                                value={state.pageIndex}
                                onChange={handleChange}
                                variant="fullWidth"
                                textColor='primary'
                                indicatorColor='primary'
                            >
                                <Tab label={t('title.sponsorGenealogy')} disabled style={{ textTransform: 'capitalize', fontSize: 19, fontWeight: 700 }} />
                                <Tab label={t('title.placementGenealogy')} />
                            </Tabs>
                        </AppBar>
                    </MuiThemeProvider> */}
                    <div>
                        {/* /////////  Sponsor Genealogy /////////*/}
                        <TabPanel classes={{ root: styles.tab }} value={state.pageIndex} index={0} dir={theme.direction}>
                            <Grid item xs={12} className={styles.root} >
                                {displayPage()}
                            </Grid>
                        </TabPanel>

                        {/* /////////  Placement Genealogy /////////*/}
                        {/* <TabPanel value={state.pageIndex} index={1} dir={theme.direction}>
                            <Grid item xs={12} className={styles.root} >
                                {displayPage()}
                            </Grid>
                        </TabPanel> */}
                    </div>
                </> : null}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    goldFrame: {
        boxShadow: 'rgb(0 0 0 / 32%) 0 0 5px 0',
        padding: 5,
        borderRadius: 15,
        background: '#fff',
    },
    frameBkg: {
        background: '#fff',
        padding: '10px 15px 15px'
    },
}));

const tabStyle = createTheme({
    overrides: {
        MuiTab:{
            textColorPrimary:{
                color: `${theme.palette.primary.main} !important`,
                "&$selected": {
					color: `${theme.palette.primary.main} !important`,
				},
            },
        },
        PrivateTabIndicator:{
            colorPrimary:{
                backgroundColor:'#dbc685'
            }
        }
    }
})