import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { Grid, IconButton, TextField, FormControl, Select, MenuItem, Typography, InputAdornment, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BiSearch } from "react-icons/bi";
import { MdKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";

import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../Theme';
import moment from 'moment-timezone';

export default function GenealogySponsor(props) {
    const { t, i18n } = useTranslation();
    const { id, username, profile_image, rank_display } = useSelector(state => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const rankDisplay = rank_display ? rank_display.name[i18n.language] : null;

    const styles = useStyles();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    let location = useLocation();

    const [tree, setTree] = useState([]);
    const [user, setUser] = useState({});
    const [uplines, setUplines] = useState({});
    const [openSearch, setOpenSearch] = useState(false);
    const [expandMore, setExpandMore] = useState({});
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '', search: 0 });
    const [expanded, setExpanded] = useState([]);
    const [checkError, setCheckError] = useState(false);
    const [downlinesData, setDownlinesData] = useState({});

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        setState({ ...state, username: props.username || "", search: state.search + 1 });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, props.username]);

    useEffect(() => {
        if (state.search > 0) {
            searchUsername()
        }
        // eslint-disable-next-line
    }, [state.search]);

    const searchUsername = () => {
        setTree([]);
        setLoading(true);
        setCheckError(false);
        getUrl(`/sponsor-genealogy`, { username: state.username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setTree(data.tree_data);
                    setUser(data.user);
                    setOpenSearch(false);
                    setExpanded([]);
                    setDownlinesData(data.downlines_data);

                    let expandStateArr = {};
                    if (props.username) {
                        expandStateArr[data.tree_data.id] = { id: data.tree_data.id, expand: true };
                        setExpanded([`${data.tree_data.id}`]);
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    } else {
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    }
                    setExpandMore(expandStateArr);
                    setUplines(data.uplines);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        setCheckError(true);
                        addAlert('', message, 'error', '');
                    }
                }
                setLoading(false);
            }

        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                setCheckError(true);
                addAlert('', JSON.stringify(error.message), 'error', '');
            }
        });
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSearch = () => {
        setOpenSearch(!openSearch);
    }

    const handleExpand = (id) => {
        setExpandMore({ ...expandMore, [id]: { ...expandMore.id, expand: !expandMore[id].expand } });
    }

    const viewMore = (value) => {
        navigate(`/community/sponsor?u=${value}`, { state: { from: storePaths(location) } });
    }

    const TreeComponent = (data) => (
        <Grid container spacing={2} className='p-t-15 p-b-10 p-l-10'>
            {/* <Grid item xs={4}>
                <Typography variant='body2' className='txt-upper' style={{ color: data.type === 'main' ? '#fff' : '#8B8B8B' }}><b>{t('genealogy.totalStakes')}</b></Typography>
            </Grid> */}
            <Grid item xs={6} className='dis-flex flex-m' style={{ flexDirection: 'column' }}>
                <Typography variant='body2' style={{ wordBreak: 'break-word', color: data.type === 'main' ? theme.palette.primary.main : '#CFCCCC' }}><b>{(data.extraInfo?.personal_sales + data.info?.personal_sales) ? (data.extraInfo?.personal_sales || data.info?.personal_sales) : (0).toFixed(2)}</b></Typography>
                <Typography variant='body2' style={{ color: data.type === 'main' ? theme.palette.primary.main : '#CFCCCC' }}><b>{t('genealogy.personalStake')}</b></Typography>
            </Grid>
            <Grid item xs={6} className='dis-flex flex-m' style={{ flexDirection: 'column' }}>
                <Typography variant='body2' style={{ wordBreak: 'break-word', color: data.type === 'main' ? theme.palette.primary.main : '#CFCCCC' }}><b>{(data.extraInfo?.group_sales + data.info?.group_sales )? (data.extraInfo?.group_sales || data.info?.group_sales) : (0).toFixed(2)}</b></Typography>
                <Typography variant='body2' style={{ color: data.type === 'main' ? theme.palette.primary.main : '#CFCCCC' }}><b>{t('genealogy.totalGroupStake')}</b></Typography>
            </Grid>
        </Grid>
    )

    const getMaxRankDisplay = (data) => {
        let name = { en: '', cn: '' };
        if ((data.rank_code && !data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code >= data.crank_code))) {
            name = JSON.parse(data.rank_name);
        }
        if ((!data.rank_code && data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code < data.crank_code))) {
            name = JSON.parse(data.crank_name);
        }
        return name[i18n.language] ? name[i18n.language] : name.en;
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`}
            className={styles.downlineBoxDesign}
            onClick={() => handleExpand(nodes.id)}
            disabled={_.size(nodes.children) > 0 ? false : true}
            label={
                <>
                    <div className='dis-flex flex-sb'>
                        <div className='dis-flex flex-m'>
                            <div className={styles.outerThemeCircleDesign}>
                                {getMaxRankDisplay(nodes) &&
                                    <div className={styles.innerThemeCircleDesign}>
                                        <Typography variant='body1' className='txt-center' style={{ color: '#121C28' }}><b>{getMaxRankDisplay(nodes)}</b></Typography>
                                    </div>
                                }
                            </div>
                            <div className='p-l-10 dis-flex flex-col flex-c txt-title'>
                                <p variant='body2' className='txt-truncate txt-upper'><b>{nodes.username}</b></p>
                                <p className='fs-12' style={{ fontWeight: 500 }}>{t('genealogy.totalDownlines', { value: nodes.total_downline })}</p>
                            </div>
                        </div>
                        <div style={{ textAlign: 'end' }}>
                            {
                                _.size(nodes.children) > 0 ?
                                    expandMore?.[nodes.id]?.expand ?
                                        <IconButton disabled>
                                            <MdOutlineKeyboardDoubleArrowUp className={styles.expandUpDesign} />
                                        </IconButton>
                                        : <IconButton disabled>
                                            <MdKeyboardDoubleArrowDown className={styles.expandDownDesign} />
                                        </IconButton>
                                    : null
                            }
                        </div>
                    </div>

                    <TreeComponent type={'submain'} info={nodes} extraInfo={downlinesData?.[nodes.user_id] || null}/>

                    {
                        !expandMore?.[nodes.id]?.expand && _.size(nodes.children) > 0 &&
                        <div className='pos-absolute p-all-15 bor-15' style={{ zIndex: -1, bottom: -21, right: -8, width: '105%', height: 50, }} />
                    }
                </>
            }
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderSubTree(node)) : null}
        </TreeItem>
    );

    const renderSubTree = (nodes) => (
        <div key={nodes.id}>
            <div className='divider-design' />

            <div className='p-t-10 p-b-10'>
                <div className='dis-flex flex-m flex-sb'>
                    <div className='p-l-10 dis-flex flex-col flex-c'>
                        <Typography variant='body2' className='txt-truncate txt-upper' style={{ color: theme.palette.secondary.main }}><b>{nodes.username} {/* {'[' + getMaxRankDisplay(nodes) + ']'} */}</b></Typography>
                        <Typography variant='body2' style={{ color: theme.palette.text.content, fontWeight: 500 }}>{t('genealogy.totalDownlines', { value: nodes.total_downline })}</Typography>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        {
                            nodes.total_downline > 0 ?
                                <IconButton onClick={() => viewMore(nodes.username)}>
                                    <MdKeyboardDoubleArrowDown className={styles.expandDownDesign} />
                                </IconButton>
                                : null
                        }
                    </div>
                </div>

                <TreeComponent type={'submain'} info={nodes} extraInfo={downlinesData?.[nodes.user_id] || null}/>
            </div>
        </div>
    )

    return (
        <div>
            <div className="p-lr-20 p-tb-20" style={{ backgroundColor: '#100F15' }}>
                <Typography style={{ fontWeight: 600, fontSize: '1rem', color: 'white' }}>{t('genealogy.downlines')}</Typography>
                <div className="flex-l-m m-t-20">
                    <div>
                        <Avatar className={styles.avatarStyle} src="/images/profile/profile-image.png" />
                        {/* {
                            profile_image ?
                                <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                :
                                <Avatar className={styles.avatarStyle}>
                                    <Typography style={{ color: theme.palette.text.base, fontSize: 16 }} className='txt-upper'>{username ? username.substring(0, 1) : '-'}</Typography>
                                </Avatar>
                        } */}
                    </div>
                    <div className="m-l-15">
                        <Typography style={{ fontSize: '1rem', color: 'white', fontWeight: 500, textTransform: 'capitalize' }}>{username}</Typography>
                        <Typography component="span" style={{ fontSize: '0.625rem', backgroundColor: '#F7A6001A', padding: 6, fontWeight: 500, borderRadius: 2, color: theme.palette.gold }}>{rankDisplay || t('user.guest')}</Typography>
                    </div>
                </div>
                <div className="flex-sb-m m-t-20">
                    <div style={{ flex: 1 }}>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 400, color: theme.palette.gray.main }}>{t('genealogy.totalDownline')}</Typography>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 500, color: 'white' }}>{user?.total_downline}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 400, color: theme.palette.gray.main }}>{t('genealogy.totalCombinedSales')}</Typography>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 500, color: 'white' }}>{(parseFloat(user?.personal_sales || 0) + parseFloat(user?.group_sales || 0)) || (0).toFixed(2)}</Typography>
                    </div>
                </div>
                <div className="flex-sb-m m-t-20">
                    <div style={{ flex: 1 }}>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 400, color: theme.palette.gray.main }}>{t('genealogy.individualSales')}</Typography>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 500, color: 'white' }}>{user?.personal_sales ? user?.personal_sales : (0).toFixed(2)}</Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 400, color: theme.palette.gray.main }}>{t('genealogy.groupSales')}</Typography>
                        <Typography style={{ fontSize: '0.75rem', fontWeight: 500, color: 'white' }}>{user?.group_sales ? user?.group_sales : (0).toFixed(2)}</Typography>
                    </div>
                </div>
                <div className="p-t-20" >
                    <Typography variant="caption" style={{ fontWeight: 500, fontSize: '0.625rem', color: 'white' }}>{t('genealogy.salesUpdated') + ": " + moment().startOf('day').format("YYYY-MM-DD LT")}</Typography>
                </div>
            </div>

            <div className="p-lr-20 m-t-15" style={{ backgroundColor: '#100F15' }}>
                {
                    openSearch ?
                        <div>
                            <TextField
                                variant='standard'
                                InputLabelProps={{ shrink: true }}
                                name="search"
                                placeholder={t('genealogy.username')}
                                fullWidth
                                value={state?.username || ''}
                                onChange={(event) => setState({ ...state, username: event.target.value })}
                                onKeyDown={(e) => (e.keyCode == 13 && viewMore(state.username))}
                                sx={{ '& .MuiInputBase-root': { height: 47, borderRadius: 49 } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => viewMore(state.username)}
                                                edge="end"
                                            >
                                                <BiSearch className='txt-textfield' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                className='bor-48'
                                style={{ color: '#8B8B8B' }}
                            />
                        </div>
                        :
                        <div className='p-t-15 dis-flex flex-m flex-col-sb' style={{ flexDirection: 'row' }}>
                            <p className='txt-content'>{t('genealogy.totalActiveDownlines', { value: user?.total_downline })}</p>
                            <IconButton onClick={handleSearch}>
                                <BiSearch className='fs-25 txt-content' />
                            </IconButton>
                        </div>
                }
            </div>
            <div className="p-lr-20 p-b-20" style={{ backgroundColor: '#100F15' }}>
                {
                    _.size(tree) > 0 ?
                        <>
                            {
                                props.username && tree.id !== id &&
                                <div style={{ paddingTop: 20 }}>
                                    <FormControl fullWidth className='dis-flex flex-b'>
                                        <Select
                                            variant='standard'
                                            value={props?.username || ''}
                                            MenuProps={{ classes: { paper: styles.listRoot } }}
                                            onChange={(event) => viewMore(event.target.value)}
                                            className={`txt-upper txt-center ${styles.selectBox}`}
                                            disableUnderline
                                            IconComponent={() => (
                                                <FaChevronDown className={styles.dropdownIcon} />
                                            )}
                                        >
                                            <MenuItem key={''} value={''} className='txt-upper' style={{ color: theme.palette.text.title, fontSize: 14 }}>{t('general.pleaseSelect')}</MenuItem>
                                            {
                                                _.map(uplines, (detail, key) => (
                                                    <MenuItem key={key} value={detail} className='txt-upper' style={{ color: theme.palette.text.content, fontSize: 14 }}>{detail}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {
                                _.size(tree.children) > 0 ?
                                    <div>
                                        <TreeView
                                            className={styles.tree}
                                            defaultExpanded={[props.username ? `${tree?.id}` : `${id}`]}
                                            expanded={expanded}
                                            onNodeToggle={handleToggle}
                                            style={{ position: 'relative', paddingTop: 15, zIndex: 1 }}
                                        >
                                            {props.username && tree.id !== id ? renderTree(tree) : _.map(tree.children, node => (renderTree(node)))}
                                        </TreeView>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                                        <div style={{ width: 100 }}>
                                            <img src={'/images/genealogy/downline_none.png'} className='s-full' alt="genealogy" loading='lazy' />
                                        </div>
                                        <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                            <Typography variant='h6'><b>{checkError ? t('genealogy.notFoundUsername') : t('genealogy.noDownline')}</b></Typography>
                                            <Typography>{checkError ? t('genealogy.textErrorUsername') : t('genealogy.textNoDownline')}</Typography>
                                        </div>
                                    </div>
                            }
                        </>
                        :
                        loading ?
                            null
                            :
                            <div className='p-t-30 flex-c-m flex-col' style={{ maxWidth: 300, margin: '0 auto' }}>
                                <div style={{ width: 100 }}>
                                    <img src={'/images/genealogy/downline_none.png'} className='s-full' alt="none_genealogy" loading='lazy' />
                                </div>
                                <div className='txt-center p-t-20 txt-content fs-14'>
                                    <Typography variant='h6'><b>{checkError ? t('genealogy.notFoundUsername') : t('genealogy.noDownline')}</b></Typography>
                                    <p style={{ fontWeight: 500 }}>{checkError ? t('genealogy.textErrorUsername') : t('genealogy.textNoDownline')}</p>
                                </div>
                            </div>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    outerThemeCircleDesign: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 49,
        width: 50,
        height: 50,
        aspectRatio: 1 / 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerThemeCircleDesign: {
        background: theme.palette.secondary.main,
        borderRadius: 49,
        padding: 10,
        margin: 10,
        width: 42,
        height: 42,
        aspectRatio: 1 / 1
    },
    expandDownDesign: {
        color: theme.palette.text.base,
        borderRadius: 5,
        background: 'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
        boxShadow: '3px 3px 3px 0px rgba(255, 255, 255, 0.12) inset, 3px 3px 4px #000'
    },
    expandUpDesign: {
        color: theme.palette.text.title,
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: '3px 3px 3px 0px rgba(255, 255, 255, 0.12) inset, 3px 3px 4px #000'
    },
    downlineBoxDesign: {
        position: 'relative',
        marginBottom: 25,
        padding: '15px 15px 15px 0px',
        borderRadius: 15,
        background: theme.palette.primary.main,
        boxShadow: '3px 3px 3px 0px rgba(255, 255, 255, 0.12) inset',
        filter: 'drop-shadow(3px 3px 4px #000)',
    },
    outlinedInput: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
    },
    notchedOutline: {
        border: 0,
        borderColor: 'transparent !important'
    },
    listRoot: {
        backgroundColor: '#253240',
        boxShadow: '9px 8px 6px 0px #000000ba, 3px 3px 3px 0px rgba(255, 255, 255, 0.12) inset'
    },
    selectBox: {
        width: '100%',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 0,
        textAlign: 'end',
        background: 'transparent'
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
    avatarStyle: {
        height: 42,
        width: 42,
        flexShrink: 0,
        background: '#eee095'
    },
}));
