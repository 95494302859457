import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useNotificationLoading from '@utils/useNotificationLoading';

const ReferralQr = (props) => {
    const styles = useStyles();
    const shareLink = useRef(null);
    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { username } = useSelector(state => state.user);

    const handleClose = () => {
        props.handleClose();
    };

    const copyLink = () => {
        var textField = document.createElement('textarea');
        textField.innerText = `${window.location.origin}/register?r=${btoa(username)}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        navigator.clipboard.writeText(`${window.location.origin}/register?r=${btoa(username)}`);
        addAlert('', t('general.copied'), 'success', '');
    };

    return (
        <div>
            <div style={{ borderBottom: '2px solid #26292E' }}>
                <div className='flex-sb-m p-all-15 m-lr-10'>
                    <p className='fs-16' style={{ color: '#E7E7E7' }}>{t('profile.shareQr')}</p>
                    <img className='pointer' src="/images/icon-close.png" alt="close" style={{ objectFit: 'cover', height: '18px', width: 'auto' }} onClick={handleClose} />
                </div>
            </div>
            <div className='flex-col-c-m p-all-20'>
                <div>
                    <p className='fs-16' style={{ color: '#E7E7E7' }}>{t('profile.qrDesc')}{process.env.REACT_APP_APP_NAME}</p>
                </div>
                <div className='m-tb-20' style={{ borderRadius: '10px', background: '#404855' }}>
                    <div className='p-all-15 m-tb-20 m-lr-50' style={{ background: '#E7E7E7' }}>
                        <QRCode
                            size='178'
                            value={`${window.location.origin}/register?r=${btoa(username)}`}
                            renderAs="svg"
                            fgColor={'black'}
                        />
                    </div>
                    <div style={{ borderRadius: '0 0 10px 10px', background: '#E7E7E7', padding: 20 }}>
                        <p className='fs-14' style={{ color: '#020202', fontWeight: 600, lineHeight: '12.1px' }}>{t('profile.qrDesc2')}{process.env.REACT_APP_APP_NAME}</p>
                        {/* <Typography ref={shareLink} style={{ color: '#020202', fontWeight: 600 }}>
                            {`${window.location.origin}/register?r=${btoa(username)}`}
                        </Typography> */}
                    </div>
                </div>
            </div>
            <div className='flex-c p-tb-20' style={{ borderTop: '2px solid #26292E' }}>
                <div className='flex txt-center' onClick={copyLink}>
                    <img className='pointer' src="/images/icon-copy-link.png" alt="usdt" style={{ objectFit: 'cover', height: '40px', width: 'auto' }} />
                    <p className='fs-10' style={{ color: '#E7E7E7', fontWeight: 600, lineHeight: '9.68px' }}>{t('profile.copyLink')}</p>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px',
        background: theme.palette.sectionBackgroundColor
    },
}));

export default ReferralQr