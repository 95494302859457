import moment from 'moment-timezone';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import { changeLanguage } from '@actions';
import { FiTag, FiShoppingBag } from "react-icons/fi";



export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className=' p-t-25 p-b-110' style={{ minHeight: '90vh' }}>
            <div className='p-t-50'>
                <p className='txt-center fs-19' style={{color:'#82512D'}}><b>{t('trade.tradingSummary')}</b></p>
            </div>

            {/* ///////////////  Button Trading & Market  ///////////////// */}
            <div className='p-t-20 p-lr-35'>
                <div className='p-tb-10 p-lr-15 bor-10 shadow-in'>
                    <div className='flex-sb-m w-full'>
                        <div className='flex-m'>
                            <div style={{ color: '#565656' }}>
                                <p className='txt-title'>{t('trade.selling')} 3,000 {t('trade.unit')}</p>
                                <p className='fs-14' style={{ fontWeight: 500 }}>2023-12-21</p>
                            </div>
                        </div>
                        <div>
                            <p style={{ fontWeight: 500, color: '#565656' }}>0.67</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ///////////////  Sell & Sold Amount  ///////////////// */}
            <div className='flex-sb-m p-t-24 p-lr-35'>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiTag className='fs-25'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.selling')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#7D4E2B', height: 80, width: 9, background: 'rgb(125, 78, 43)', position: 'absolute', bottom: 7, right: -4}} />
                </div>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiShoppingBag className='fs-21'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.sold')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#DE9D49', height: 80, width: 9, background: '#DE9D49', position: 'absolute', bottom: 7, right: -4}} />
                </div>
            </div>

          

             {/* ///////////////  Transaction  ///////////////// */}
            <div className='p-lr-35 p-t-24'>
                <div className='w-full flex-sb-m p-b-20'>
                    <p style={{ color: '#9B9896' }} className='fs-18'>{t('trade.soldHistory')}</p>
                </div>
                {_.map([1, 2, 3], data => {
                    return (
                        <div className='p-b-10'>
                            <div className='flex-sb-m p-b-10'>
                                <div style={{ color: '#565656' }}>
                                    <p>{t('trade.soldUnit')}</p>
                                    <p style={{ fontWeight: 500 }}>2023-12-21, 15:38:21</p>
                                </div>
                                <div>
                                    <p>100</p>
                                </div>
                            </div>

                            <div className='w-full' style={{ height: 5, boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF', background: '#F1F1F1' }} />
                        </div>
                    )
                })}
                <p className='txt-center p-t-20' style={{fontWeight:500, color:'#a0a0a0'}}>- {t('table.endOfTheList')} -</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    buttonStyle: {
        width: 300,
        height: 50,
        marginTop: 20,
    },
}));