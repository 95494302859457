import React, { useState } from 'react';
import { storeProfile } from '@actions';
import { Alert, AlertTitle, Button, Grid, TextField, Typography } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function BasicInfo() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { username, email, mobile, name } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        email: email,
        mobile: mobile,
        username: username,
        image: null,
        previewUrl: null,
        name: name
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            email: state.email,
            mobile: state.mobile,
            name: state.name,
        }
        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                    {
                        state?.email === null &&
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                                <Typography style={{ color: 'black', textAlign: 'justify' }} variant='body2' >{t('user.userEmailWarning')}</Typography>
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='name'
                            placeholder={t('user.name')}
                            onChange={handleChange}
                            required
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.name || ''}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='email'
                            placeholder={t('user.email')}
                            onChange={handleChange}
                            required
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.email || ''}
                            InputProps={{
                                disableUnderline: true
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='mobile'
                            placeholder={t('user.mobile')}
                            onChange={handleChange}
                            required
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.mobile || ''}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                </Grid>
                <div className='flex-c-m'>
                    <Button variant="contained" onClick={submitData} className='big-button' style={{ marginTop: 20 }} fullWidth>{t('button.save')}</Button>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    input: {
        display: "none",
    },
}));