import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _, { isNull, isUndefined } from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Typography, Avatar, Dialog, IconButton, DialogTitle, DialogContent, Link, Grid, Button, Menu, MenuItem, Tooltip, Drawer, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Swiper, SwiperSlide } from "swiper/react";
import { storeMaxRank, changeLanguage } from '@actions';

import { getUrl, postUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';
import moment from 'moment-timezone';
import useNotificationLoading from '@utils/useNotificationLoading';
import ReferralQr from '@layouts/ReferralQr';

import "swiper/css";
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import { ChevronRight, Close } from '@mui/icons-material';
import NoticeItem from '../../components/NoticeItem';
import { GoGlobe } from "react-icons/go";

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { username, profile_image, max_rank, rank_display } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    const [state, setState] = useState({});
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        key: 0,
        data: null
    });
    const [coverImg, setCoverImg] = useState({});
    const [totalBalance, setTotalBalance] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    const rankDisplay = rank_display ? rank_display.name[i18n.language] : null;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    const ref = useRef(null);
    const [screenWidth, setScreenWidth] = useState(0);
    useLayoutEffect(() => {
        setScreenWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    useEffect(() => {
        getUrl(`/user/get-max-rank`).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeMaxRank(response.maxRank));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("MMM DD, YYYY"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    useEffect(() => {
        getUrl(`/get-notice-images`).then(response => {
            if (response.status) {
                setCoverImg(response?.data?.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/member-wallets`).then(response => {
            if (response.status) {
                const balanceArray = response.data.flatMap(item => {
                    return item.wallet_balances.map(wallet => parseFloat(wallet.balance) || 0);
                });
                const totalBalance = balanceArray.reduce((sum, balance) => sum + balance, 0);
                setTotalBalance(totalBalance);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, [])

    useEffect(() => {
        setLoading(true);
        let params = {
            lang: i18n.language,
        }
        getUrl(`/member-homepage`, params).then(response => {
            setLoading(false);
            setState(response.data);
            if (_.size(response?.data?.announcements) > 0) {
                setDialogInfo({
                    open: true,
                    key: 1,
                    data: response?.data?.announcements[0]
                });
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, [i18n.language])

    const getImageLink = (info) => {
        let imageDetail = null;
        if (_.size(info?.content_images) > 0) {
            let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
            if (!isUndefined(tempData)) {
                imageDetail = tempData?.file_name;
            } else {
                let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                if (defaultData) {
                    imageDetail = defaultData?.file_name;
                }
            }
        }

        return imageDetail;
    }

    const closeDialog = () => {
        if (_.size(state?.announcements) > dialogInfo.key) {
            setDialogInfo({ ...dialogInfo, key: dialogInfo.key + 1, data: state?.announcements[dialogInfo.key] });
        } else {
            setDialogInfo({
                open: false,
                key: 0,
                data: null
            });
        }
    }

    const loginGame = async (code) => {
        postUrl('/game-login', {game_code: code}).then(response => {
            if (response.status) {
                let url = new URL(response.data.url);
                url.searchParams.set('token', atob(response.data.token));
                window.open(url.toString(), "_blank", "noreferrer");
            } else {
                alert(response.message);
            }
        }).catch((error) => {
            alert(error.message || "Please try again later or contact admin if problem persist.");
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="p-t-20 p-lr-20 p-b-10" style={{ backgroundColor: '#100F15' }}>
                <div className='flex-sb-m'>
                    <div className='flex-c-m'>
                        <img src="images/logos/logo.png" style={{ height: '30px', width:'35px', marginRight: 10 }} alt="logo" loading='lazy' />
                        <div className='p-lr-8 p-tb-4' style={{ backgroundColor: '#26292E', borderRadius: 2 }}>
                            <Typography color={theme.palette.gray.main} style={{ fontWeight: 400, fontSize: 12 }}>{t('dashboard.serverTime')}: {serverTime.time} {serverTime.date}</Typography>
                        </div>
                    </div>
                    <div className='flex-m p-l-10'>
                        <Tooltip title={t('general.language')} arrow><IconButton onClick={handleClick} style={{ marginRight: 5 }}><GoGlobe className="img-style" style={{ color: 'white' }} /></IconButton></Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                            <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                        </Menu>
                        <Avatar className={styles.avatarStyle} src="/images/profile/profile-image.png" />
                        {/* {
                            profile_image ?
                                <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                :
                                <Avatar className={styles.avatarStyle}>
                                    <Typography style={{ color: theme.palette.text.base, fontSize: 16 }} className='txt-upper'>{username ? username.substring(0, 1) : '-'}</Typography>
                                </Avatar>
                        } */}
                    </div>
                </div>

                <div className="flex-sb-m m-t-20">
                    <div>
                        <Typography style={{ color: theme.palette.gray.main, fontSize: 10 }}>{t('dashboard.totalAssets')}</Typography>
                        <Typography style={{ fontSize: 22, fontWeight: 600, color: 'white', marginTop: 5 }}>$ {currencyFormat(totalBalance)}</Typography>
                    </div>
                    <div>
                        <Link component={RouterLink} to={`/funds/wallet`} >
                            <Button variant="contained" color="primary" style={{ fontSize: 12 }}>{t('walletListing.deposit')}</Button>
                        </Link>
                    </div>
                </div>

                <div className="flex-sb-m m-t-20">
                    <Link component={RouterLink} to={`/games`} >
                        <div className="flex-col-c-m">
                            <img src="images/dashboard/game.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                            <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('dashboard.games')}</Typography>
                        </div>
                    </Link>
                    <Link component={RouterLink} to={`/account/recruit`} >
                        <div className="flex-col-c-m">
                            <img src="images/dashboard/recruit.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                            <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('dashboard.registerAccount')}</Typography>
                        </div>
                    </Link>
                    {/* <Link component={RouterLink} to={`/account/referral-qr`} > */}
                    <div className="flex-col-c-m pointer" onClick={() => setDialogOpen(true)}>
                        <img src="images/dashboard/qrcode.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                        <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('dashboard.inviteQr')}</Typography>
                    </div>
                    {/* </Link> */}
                    <Link component={RouterLink} to={`/plan-histories`} >
                        <div className="flex-col-c-m">
                            <img src="images/dashboard/package-history.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                            <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('dashboard.packageHistory')}</Typography>
                        </div>
                    </Link>
                </div>

                <div className="m-t-30">
                    <Link component={RouterLink} to={`/pnl`} underline='hover'>
                        <Typography variant="body2" style={{ color: 'white', fontSize: 12 }}>{t('botTrading.transactionRecord')}</Typography>
                        <Typography variant="body1" style={{ wordBreak: "break-all", textAlign: "center", color: theme.palette.gold }}>0x6D2B794f126448A0C5cd9aca831cafc02a603f80</Typography>
                    </Link>
                </div>
            </div>

            {
                _.size(state?.banners) > 0 ?
                    <div className='p-t-20 p-lr-20' ref={ref}>
                        <Swiper
                            className="mySwiper"
                            spaceBetween={20}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 4500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination]}
                            style={{ height: (screenWidth-40)/2.5, "--swiper-pagination-color": "#fff", "--swiper-pagination-bullet-inactive-color": theme.palette.primary.main, "--swiper-pagination-bullet-inactive-opacity": "1" }}
                        >
                            {
                                _.map(state?.banners, (banner, index) => {
                                    return (
                                        <SwiperSlide key={index} style={{ position: 'relative' }}>
                                            <img src={banner.file_name} alt='banner' className='w-full' loading='lazy' style={{ objectFit: 'fill'}}/>
                                            {/* <div className='s-full bor-15 pos-absolute' style={{ top: 0, boxShadow: '-2px -2px 2px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 4px 0px #FFF inset' }} /> */}
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                    : null
            }

            {
                _.size(state?.games?.['neuralsport']) > 0 &&
                <div className='p-t-20 p-lr-20' ref={ref}>
                    <img src={`/images/games/neural-sport-banner${i18n.language === 'cn' ? '-cn' : ''}.png`} alt='banner' className='w-full' loading='lazy' style={{ objectFit: 'fill', cursor: 'pointer' }} onClick={() => loginGame(state?.games?.['neuralsport']['code'])}/>
                </div>
            }
            

            <div className="p-lr-20">
                {
                    _.size(state?.news) > 0 &&
                    <div className='p-t-30'>
                        <div className='flex-sb-m p-b-15 txt-title'>
                            <p style={{ color: "white", fontSize: 14, fontWeight: 600 }}>{t('news.latestNews')}</p>
                            <Link component={RouterLink} to={`/account/notices?type=news`} state={{ from: storePaths(location) }}>
                                <p className='fs-12 txt-title'>{t('button.viewAll')}</p>
                            </Link>
                        </div>

                        {_.map(state?.news, (data, index) => {
                            let imageLink = null;
                            if (data?.content_type === "image" && _.size(data?.content_images) > 0) {
                                imageLink = getImageLink(data);
                            }

                            if (isNull(imageLink)) {
                                imageLink = '/images/dashboard/example.png';
                            }

                            if (isNull(data.cover_image)) {
                                imageLink = '/images/dashboard/example.png';
                            } else {
                                let filename = _.filter(coverImg, { id: data.cover_image });
                                imageLink = filename[0]?.file_name;
                            }

                            if (imageLink) {
                                return (
                                    <div className="m-b-15" key={index}>
                                        <Link component={RouterLink} to={`/account/notice/${data?.id}`} state={{ from: storePaths(location) }} underline='none' key={data.id}>
                                            <div className="flex-sb-m" style={{ borderRadius: 8, padding: "8px 14px", backgroundColor: "#16171C" }}>
                                                <div style={{ fontWeight: 500 }}>
                                                    <p className='txt-truncate2 fs-14' style={{ color: "white" }}>{data?.title[i18n.language] ? data.title[i18n.language] : data?.title?.cn}</p>
                                                    <p className='fs-10 m-t-5' style={{ color: theme.palette.gray.main }}>{data?.created_at ? moment(data?.created_at).format("YYYY-MM-DD") : '-'}</p>
                                                </div>
                                                <div>
                                                    <img src={imageLink} alt='news-img' style={{ height: 60, width: 60, objectFit: 'cover', borderRadius: 6 }} />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                }
                {
                    (dialogInfo.data && _.size(state?.announcements) > 0)
                    &&
                    <Dialog
                        maxWidth="xs"
                        fullWidth
                        open={dialogInfo.open}
                        onClose={() => closeDialog()}
                        sx={{
                            background: 'transparent',
                            '& .MuiPaper-root': {
                                width: 400,
                                background: dialogInfo.data?.content_type === "image" ? 'transparent' : '#253240',
                                boxShadow: dialogInfo.data?.content_type === "image" ? 'none' : 'rgba(255, 255, 255, 0.12) 3px 3px 3px 0px inset',
                                color: theme.palette.text.content,
                            },
                            '& .MuiBackdrop-root': {
                                backgroundColor: 'rgba(0, 0, 0, 0.78)',
                            }
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 10 }} className='w-full' >
                            <IconButton
                                onClick={() => closeDialog()}
                                sx={{
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    }
                                }}
                            >
                                <Close style={{ color: dialogInfo.data?.content_type === "image" ? 'white' : theme.palette.secondary.main }} fontSize="medium" />
                            </IconButton>
                        </div>
                        {
                            dialogInfo.data && dialogInfo.data?.content_type === "text" &&
                            // <DialogTitle style={{ padding: '0 20px' }}>
                            <Typography variant="h6" color="primary" style={{ padding: '0 20px', wordBreak: 'break-word', color: theme.palette.text.title }}>{dialogInfo.data?.title[i18n.language] ? dialogInfo.data?.title[i18n.language] : dialogInfo.data?.title?.cn}</Typography>
                            // </DialogTitle>
                        }
                        <DialogContent>
                            <NoticeItem info={dialogInfo.data} />
                        </DialogContent>
                    </Dialog>
                }
            </div>
            <Drawer
                anchor={'bottom'}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <Box style={{ overflow: 'hidden' }}>
                    <ReferralQr handleClose={handleDialogClose} />
                </Box>
            </Drawer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        height: 24,
        width: 24,
        flexShrink: 0,
        background: '#eee095'
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px',
        background: theme.palette.sectionBackgroundColor
    },
}));