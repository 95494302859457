import { useLocation , createSearchParams } from "react-router-dom";
import { useState } from 'react';
import { store } from '../configs/Store';
import _ from "lodash";
import moment from "moment";
import { public_restricted_routes } from '@configs/Router';

export const generateRandomString = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characterLength = characters.length;

    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characterLength));
    }
    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useMessage = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    return {
        value,
        setValue,
        reset: () => setValue(""),
    };
}

function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

export const daysBetween = (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

export const currencyFormat = (amount, decimal = 2) => {
    // if(decimal === 0){
    //     return Math.floor(Number(amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    // return Number(amount).toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return Intl.NumberFormat('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal, roundingMode: "trunc" }).format(amount);
}

export const formatDate = date => {
    date = date.substring(0, 10);
    let theDate = new Date(date);
    let offset = theDate.getTimezoneOffset();
    theDate = new Date(theDate.getTime() - (offset * 60 * 1000));
    return theDate.toISOString().substring(0, 10);
}

export const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export const stringToLang = (string) => {
    if (string === "") return "";
    const language = store.getState().general.language;
    let key_lang = { en: 0, cn: 1 };
    let stringArray = _.split(string, '|');
    return stringArray[key_lang[language]] ? stringArray[key_lang[language]] : stringArray[key_lang['en']];
}

export const timezoneDate = (date) => {
    if (!date) return '-';
    return moment.utc(date).local().format('YYYY-MM-DD h:mm:ss A');
}

export const convertToUtcDate = (date, type) => {
    if (!date) return '';
    if (type === 'from_date') {
        return moment(date + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
    } else if (type === 'to_date') {
        return moment(date + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
    }
}

export const truncate = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';
    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
};

export const storePaths = (data) =>{
    let pathLists =[];
    let lastUrl =null;
    let need_store =false;
    
    if(_.size(data?.state?.from) > 0){
        pathLists = data?.state?.from;
        lastUrl=_.last(pathLists);
    }

    if (lastUrl === null) {
        need_store = true;
    } else if ((data?.pathname).includes("payment")) {
        need_store = false;
    } else {
        let dataLink = data?.pathname;
        if (data?.search !== '') {
            const dataSearchParams = createSearchParams(data?.state?.from?.search).toString();
            dataLink = dataLink + "?" + dataSearchParams;
        }

        let lastLink = lastUrl?.pathname;
        if (lastUrl?.search !== '') {
            const dataSearchParams = createSearchParams(data?.state?.from?.search).toString();
            lastLink = lastLink + "?" + dataSearchParams;
        }
        if (lastLink !== dataLink) {
            need_store = true;
        }
    }

    if(need_store){
        pathLists = _.concat(pathLists, [data]);
    }

    return pathLists;
}

export const reducePaths = (data) =>{
    let pathLists =null;
    const accessToken = store.getState().general.accessToken;
    if(_.size(data?.state?.from) > 0){

        pathLists = _.take(Object(data?.state?.from), _.size(data?.state?.from)-1);

        if(accessToken){
            let publickLink =_.map(public_restricted_routes, _.property('path'));

            if(_.size(publickLink) > 0){
                publickLink.push('/login');
                Object(publickLink).map(info =>{

                    return pathLists= _.filter(pathLists, detail => detail.pathname !== info);
                })

            }
        }
    }

    return pathLists;
}