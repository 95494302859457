import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Grid, IconButton, InputAdornment, TextField, InputLabel } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { AiOutlineUser } from 'react-icons/ai';
import { MdLockOutline, MdOutlineMail } from 'react-icons/md';
import { FiEye, FiEyeOff, FiPhone } from "react-icons/fi";

const INITIAL_STATE = {
    username: '',
    name: '',
    email: '',
    mobile: '',
    password: '',
    passwordConfirmation: '',
    securityPassword: '',
    securityPasswordConfirmation: '',
    referralName: '',
    placementName: '',
    placementPosition: 1,
};

const RegisterForm = memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { username } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
        securityPasswordShow: false,
        securityPasswordConfirmationShow: false,
    });
    const [searchParams] = useSearchParams();
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];
    let location = useLocation();
    let current_path = location?.pathname;

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase();
        }

        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    useEffect(() => {
        let paramRef = searchParams.get("r") ? atob(searchParams.get("r")) : '';
        setState({ ...state, referralName: paramRef ? paramRef : (username ? username : '') });
    }, [username, searchParams]);

    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: state.name,
            username: state.username,
            email: state.email,
            mobile: state.mobile,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            security_password: state.securityPassword,
            security_password_confirmation: state.securityPasswordConfirmation,
            referral_name: state.referralName,
            // placement_name: state.placementName,
            // placement_position: state.placementPosition
        }
        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                if (username) {
                    setState(INITIAL_STATE);
                } else {
                    navigate('/login');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const passwordTextField = (column, columnSnake) => {
        return (
            <Grid item xs={12} >
                <InputLabel>{t(`user.${column}`) + '*'}</InputLabel>
                <TextField
                    variant="standard"
                    fullWidth
                    name={column}
                    placeholder={t(`user.${column}`) + '*'}
                    value={state?.[column]}
                    onChange={handleChange}
                    type={option?.[column + 'Show'] ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => handlePasswordShow(column + 'Show')}>
                                    {option?.[column + 'Show'] ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdLockOutline className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                    helperText={inputErrors && inputErrors[columnSnake] ? inputErrors[columnSnake] : ''}
                    error={inputErrors && inputErrors[columnSnake] ? true : false}
                    InputLabelProps={{ shrink: true }}
                    className={classes.textFieldStyle}
                />
            </Grid>
        )
    }

    return (
        <>
            {/* <Grid item xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    name='name'
                    placeholder={t('user.name')}
                    value={state.name}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                    error={inputErrors && inputErrors.name ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    className={classes.textFieldStyle}
                />
            </Grid> */}
            <Grid item xs={12}>
                <InputLabel>{t('user.username')}</InputLabel>
                <TextField
                    variant="standard"
                    fullWidth
                    name='username'
                    placeholder={t('user.username') + '*'}
                    value={state?.username}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                    error={inputErrors && inputErrors.username ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiOutlineUser className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.textFieldStyle}
                />
                <p className='fs-10 txt-content p-b-11' style={{ fontWeight: 500, letterSpacing: 0.3, color: '#ffffff8a' }}>
                    {t('user.usernameLowerCase')}
                </p>
            </Grid>
            <Grid item xs={12}>
                <InputLabel>{t('user.email')}</InputLabel>
                <TextField
                    variant="standard"
                    fullWidth
                    name='email'
                    placeholder={t('user.email') + '*'}
                    value={state?.email}
                    onChange={handleChange}
                    required
                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                    error={inputErrors && inputErrors.email ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdOutlineMail className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.textFieldStyle}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel>{t('user.mobile')}</InputLabel>
                <TextField
                    variant="standard"
                    fullWidth
                    name='mobile'
                    placeholder={t('user.mobile')}
                    onChange={handleChange}
                    required
                    helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                    error={inputErrors && inputErrors.mobile ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiPhone className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                            </InputAdornment>
                        ),
                    }}
                    value={state?.mobile}
                    className={classes.textFieldStyle}
                />
            </Grid>
            {/* {
                username ? null
                    : */}
            <Grid item xs={12}>
                <InputLabel>{t('user.referralName')}</InputLabel>
                <TextField
                    variant="standard"
                    fullWidth
                    name='referralName'
                    placeholder={t('user.referralName') + '*'}
                    value={state?.referralName}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                    error={inputErrors && inputErrors.referral_name ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AiOutlineUser className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                            </InputAdornment>
                        ),
                    }}
                    className={classes.textFieldStyle}
                />
            </Grid>
            {/* } */}

            {/* <Grid item xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    name='placementName'
                    placeholder={t('user.placementName')}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                    error={inputErrors && inputErrors.placement_name ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                    className={classes.textFieldStyle}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomSelect
                    placeholder={t('user.placementPosition')}
                    listing={POSITION}
                    list_label='label'
                    list_value='value'
                    placeholder={t('general.selectOption', { option: t('user.position') })}
                    helperText={inputErrors && inputErrors.placement_position ? inputErrors.placement_position : ''}
                    error={inputErrors && inputErrors.placement_position ? true : false}
                    displayEmpty={true}
                    value={state.placementPosition}
                    onChange={handleChange}
                    name="placementPosition"
                />
            </Grid> */}
            {passwordTextField('password', 'password')}
            {passwordTextField('passwordConfirmation', 'password_confirmation')}
            {passwordTextField('securityPassword', 'security_password')}
            {passwordTextField('securityPasswordConfirmation', 'security_password_confirmation')}
            <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                <Button variant="contained" onClick={handleSubmit} className='big-button' style={{ marginTop: 20 }} fullWidth>
                    {current_path.includes('/register') ? t('login.signUp') : t('login.registerAcc')}
                </Button>
            </Grid>
            <p className='fs-12 txt-content p-tb-11' style={{ fontWeight: 500, letterSpacing: 0.3, color: '##848D9B' }}>
                {t('user.registerDesc')}
            </p>
        </>
    )
});

export default RegisterForm;

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: 320,
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));