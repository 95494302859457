import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Container, Dialog, Drawer, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { storeMaxRank } from '@actions';
import { getUrl, removeLoginAccess } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import ReferralQr from '@layouts/ReferralQr';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { MdOutlineSettings } from "react-icons/md";
import VerifiedIcon from '@mui/icons-material/Verified';

const Me = () => {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { username, email, max_rank, rank_display, kyc_status } = useSelector(state => state.user);
    const { setLoading, addAlert } = useNotificationLoading();
    const [dialogOpen, setDialogOpen] = useState(false);

    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    const rankDisplay = rank_display ? rank_display.name[i18n.language] : null;

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        getUrl(`/user/get-max-rank`).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeMaxRank(response.maxRank));
            }
        })
    }, [dispatch]);

    const logout = () => {
        removeLoginAccess();
        addAlert('', t('success.logoutSuccess'), 'success', '');
        navigate('/landing');
    };

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="p-t-20 p-lr-20 p-b-10" style={{ backgroundColor: '#100F15' }}>
                <div className='flex-sb-m'>
                    <p style={{ color: '#E7E7E7', fontSize: '16px' }}>{t('title.profile')}</p>
                    {/* <MdOutlineSettings className='pointer' size={18} color='#E7E7E7' /> */}
                </div>
                <div className='flex-m p-t-15 p-b-15'>
                    <div className={`${styles.avatarOutlineStyle} flex-c-m`} >
                        <Avatar className={styles.avatarStyle} src="/images/profile/profile-image.png" />
                    </div>
                    <div className='w-full p-l-20'>
                        <div className='flex-l-m'>
                            <p className='fs-16 lh-11 txt-title txt-truncate' style={{ color: '#E7E7E7' }}>{username || ''}</p>
                        </div>
                        <p className={`${styles.infoText}`} style={{ color: theme.palette.gray.main }}>{email ? email.slice(0, 3) : ''}***@***</p>
                    </div>
                </div>
                <div className='flex-m' style={{ gap: 10}}>
                    { 
                        kyc_status && 
                        <div style={{ background: '#F7A6001A', width: 'fit-content' }}>
                            <p className={`${styles.infoText} txt-title p-all-4`} style={{ color: '#F7A600' }}><VerifiedIcon fontSize='small' style={{ verticalAlign: 'middle', marginRight: 3, fontSize: 16 }} />{t('user.verified')}</p>
                        </div>
                    }
                    <div style={{ background: '#F7A6001A', width: 'fit-content' }}>
                        <p className={`${styles.infoText} txt-title p-all-4`} style={{ color: '#F7A600' }}>{rankDisplay || t('user.guest')}</p>
                    </div>
                </div>
            </div>
            <div className="flex-sb-m m-tb-10 p-all-20" style={{ background: theme.palette.sectionBackgroundColor }}>
                <Link component={RouterLink} to={`/account/security`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none' }}>
                    <div className="flex-col-c-m">
                        <img src="images/profile/icon-security.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                        <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('profile.security')}</Typography>
                    </div>
                </Link>
                <Link component={RouterLink} to={`/account/recruit`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none' }}>
                    <div className="flex-col-c-m">
                        <img src="images/profile/icon-register-account.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                        <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('profile.registerAcc')}</Typography>
                    </div>
                </Link>
                <div className="flex-col-c-m pointer" onClick={() => setDialogOpen(true)}>
                    <img src="images/profile/icon-qr.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                    <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('profile.referralQr')}</Typography>
                </div>
                <Link component={RouterLink} to={`/plan-histories`} state={{ from: storePaths(location) }} style={{ textDecoration: 'none' }}>
                    <div className="flex-col-c-m">
                        <img src="images/profile/icon-my-purchases.png" alt='game-img' style={{ objectFit: 'contain', width: 18, height: 18 }} />
                        <Typography style={{ fontSize: 10, fontWeight: 500, color: 'white', marginTop: 5 }}>{t('profile.packageHistory')}</Typography>
                    </div>
                </Link>
            </div>
            <div className="flex m-tb-10 p-all-20" style={{ background: theme.palette.sectionBackgroundColor }}>
                <div>
                    <p className='fs-16' style={{ color: 'white' }}>{t('profile.setting')}</p>
                </div>
                {/* <div className='flex-sb-m p-t-20 p-l-10 pointer' onClick={() => navigate("/account/kyc", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('profile.kyc')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div> */}
                <div className='flex-sb-m p-t-20 p-l-10 pointer' onClick={() => navigate("/account/language", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('general.language')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div>
                {/* <div className='flex-sb-m p-t-20 p-l-10 pointer' onClick={logout}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('button.logout')}</p>
                </div> */}
            </div>
            <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 70, left: 0, right: 0 }}>
                <Button fullWidth className='m-lr-20' style={{ height: '46px', marginTop: 10, border: '2px solid #1B1B23', background: 'none', color: '#E7E7E7', borderRadius: 8 }} onClick={logout}>{t('button.logout')}</Button>
            </Container>
            <Drawer
                anchor={'bottom'}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <Box style={{ overflow: 'hidden' }}>
                    <ReferralQr handleClose={handleDialogClose} />
                </Box>
            </Drawer>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        width: '100%',
        height: '100%',
        flexShrink: 0,
        background: theme.palette.secondary.main
    },
    avatarOutlineStyle: {
        // borderRadius: 48,
        // border: '2px solid',
        // borderColor: theme.palette.secondary.main,
        // padding: 3,
        width: 50,
        height: 'auto'
    },
    infoText: {
        wordBreak: 'break-all',
        lineHeight: '14.52px',
        margin: '5px 0',
        fontSize: 12
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px',
        background: theme.palette.sectionBackgroundColor
    },
}));

export default Me;