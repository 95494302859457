import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Button ,  Tab, Tabs, MenuItem, TextField, InputLabel, IconButton, Container } from '@mui/material';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';

import { QRCodeSVG } from 'qrcode.react';
import CustomSelect from '@components/CustomSelect';
import { Link as RouterLink, useParams , useLocation , useSearchParams} from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TOKENS = ['USDT', 'KEY', 'CARD', 'NFT'];
const NETWORK = ['ERC20', 'KEY', 'CARD', 'NFT'];

const INITIAL_STATE = {
    bsc: [],
    trx: [],
    wallet: '',
    wallet_name: [],
    details: [],
    tokens: "",
    // network: "",
    // depositAddress: '0x0000000000000000000000000000000000000000',
}
const Deposit = () => {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const { addAlert, setLoading } = useNotificationLoading();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState(INITIAL_STATE);
    const [activeTab, setActiveTab] = useState('trx');

    // const [qrcode, setQrcode] = useState('');
    
    // const handleChange = ({ target }) => {
    //     let { name, value } = target;
    //     setState({ ...state, [name]: value });
    // };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const getDepositAddress = () => {
        setLoading(true);

        try {
            let apiData = {
                wallet: parseInt(atob(searchParams.get('w'))),
            };
            
            getUrl('wallets/deposit_address', apiData).then(response => {
                if (response.status) 
                {
                    setState({ ...state, trx: response.trx , bsc: response.bsc , details: response.details, wallet_name: response.wallet_name, tokens: response.token});
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            })
        }
        catch (error) {
            
            addAlert(JSON.stringify(error.message));
        }
    }

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDepositAddress();
        
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ marginTop: 80, marginBottom: 10}} >
            {/* <Typography variant='h6' className='p-b-30' style={{ textAlign: 'center', fontWeight: "bold" }}>
                {t('title.deposit')} {state.wallet_name[i18n.language]}
            </Typography> */}
            <Grid className='pos-relative p-lr-20 p-tb-10' container style={{ backgroundColor: '#16171C' }}>
                <Grid item xs={12} className="p-t-10">
                    <InputLabel>{t('deposit.token')}</InputLabel>
                    <div className="flex-m" style={{ backgroundColor: '#28292E', borderRadius: 6, padding: "12px 14px", gap: 5 }}>
			{ (state.tokens==='usdt')?
                        <><img src={'/images/profile/icon-usdt.png'} alt="usdt Logo" loading='lazy' style={{ height: 18, weight: 18 }}/>
                        <Typography style={{ color: 'white', fontSize: '0.875rem', fontWeight: 600 }}>USDT</Typography>
                        <span style={{ color: theme.palette.gray.main, fontSize: '0.75rem' }}>Tether USDT</span></>
			: 
                        <Typography style={{ color: 'white', fontSize: '0.875rem', fontWeight: 600 }}>{state.tokens.toUpperCase()}</Typography>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} className="p-t-10">
                    <InputLabel>{t('deposit.network')}</InputLabel>
                    <TextField
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '10px', padding: "12px 0px 12px 14px", height: 46 } }}
                        variant="standard"
                        select
                        placeholder={t('deposit.network')}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={activeTab}
                        onChange={(event) => setActiveTab(event.target.value)}
                    >
                        <MenuItem value={'trx'}>{t('wallet.trx')}</MenuItem>
                        <MenuItem value={'bsc'}>{t('wallet.bsc')}</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            {
                state.trx && state.bsc && 
                (
                    <>
                    <div className="m-t-10 p-lr-20 p-tb-20" style={{ backgroundColor: '#16171C' }}>
                        {/* <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            className={styles.optionWalletBonusLayout}
                            centered
                        >
                            <Tab
                                className={`${styles.tabButton} ${activeTab === 'trx' && styles.activeTab}`}
                                value="trx"
                                label={
                                    <Typography variant="body1" style={{ color: activeTab === 'wallet' ? '#121C28' : 'inherit' }}>
                                        {t('wallet.trx')}
                                    </Typography>
                                }
                            />
                            <Tab
                                className={`${styles.tabButton} ${activeTab === 'bsc' && styles.activeTab}`}
                                value="bsc"
                                label={
                                    <Typography variant="body1" style={{ color: activeTab === 'wallet' ? '#121C28': 'inherit' }}>
                                        {t('wallet.bsc')}
                                    </Typography>
                                }
                            />
                        </Tabs> */}
                        {
                            activeTab && 
                            (
                                <div className='flex-col-c'>
                                    <div
                                        style={{ 
                                            padding: 10 ,
                                            borderRadius: '4px',
                                            background: 'white',
                                            // boxShadow: ' 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset'
                                        }}
                                    >
                                        <QRCodeSVG 
                                            value={state[activeTab]?.address} 
                                            size={80}
                                            style={{ verticalAlign: 'middle' }}
                                        />
                                    </div>
                                    <div className="flex-sb-m w-full">
                                        <p className='p-t-15 p-lr-5 txt-center fs-12 txt-content'>
                                            {
                                                state[activeTab]?.address
                                            }
                                        </p>
                                        <IconButton onClick={() => copyShareLink(state[activeTab]?.address)}>
                                            <ContentCopyIcon className='fs-18' style={{ color: theme.palette.gray.main }}/>
                                        </IconButton>
                                    </div>
                                    
                                    {/* <Button 
                                        variant="contained" 
                                        className='big-button'
                                        fullWidth
                                        onClick={() => copyShareLink(state[activeTab]?.address)}
                                    >
                                        {t('button.copyAddress')}
                                    </Button> */}
                                    {/* <div className='fs-12 p-t-20 txt-content'>
                                        <Typography style={{ fontSize: 12, padding: '15px 40px 0 ', width: '100%'}}>
                                            {t('deposit.noti', {'value': state?.details?.min_amount})}
                                        </Typography>
                                        <p>
                                            {t('deposit.notiwarrning')}
                                        </p>
                                        <p>
                                            {t('deposit.depositFee', {'min_amount': state?.details?.deposit_fee?.top_up_minimum_amount, 'deposit_fee': state?.details?.deposit_fee?.type == 'fixed' ? state?.details?.deposit_fee?.amount : state?.details?.deposit_fee?.amount + "%"})}
                                        </p>
                                    </div> */}
                                    
                                </div>
                            )
                        }
                    </div>
                    <div className="m-t-10 p-all-20" style={{ backgroundColor: '#16171C' }}>
                        <Typography style={{ fontSize: "0.625rem", color: theme.palette.gray.main }}>Notice:</Typography>
                        <Typography style={{ fontSize: "0.625rem", color: theme.palette.gray.main }}>
                            {t('deposit.notiwarrning')}
                        </Typography>
                    </div>
                    <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#16171C' }}>
                        <Button fullWidth variant="contained" style={{ height: '46px', marginTop: 10 }} onClick={() => copyShareLink(state[activeTab]?.address)}>{t('button.copyAddress')}</Button>
                    </Container>
                    </>
                )
            }
        </div>
    )
   
}

const useStyles = makeStyles(theme => ({
    depositBox: {
        borderRadius: 6,
        backgroundColor: '#D6A364',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 20,
        paddingLeft: 5,
        paddingRgiht: 5,
        justifyContent: 'center'
    },
    optionWalletBonusLayout: {
        borderRadius: '48px',
        background: '#253240',
        boxShadow: '2px 2px 3px 0px rgba(200, 252, 255, 0.26), 3px 3px 3px 0px #000 inset;'
    },
    tabButton: {
        borderRadius: '48px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        color: '#E1AC46',
    },
    activeTab: {
        color: '#121C28',
        // background: 'linear-gradient(91deg, #86552E 2.67%, #DDA253 99.35%)',
        background:'#EEB23E',
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
    },
}));

export default Deposit;
