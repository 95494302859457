import _ from 'lodash';
import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BonusItem from '@components/BonusItem';

const BonusListing = props => {
    const { listing } = props;
    const { i18n } = useTranslation();

    const bgColors = [
        'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
        'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
        'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
        'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
        'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
    ];

    return (
        <Grid container spacing={2} style={{ padding: '0', margin:0, width:'100%' }}>
            {
                _.size(listing) > 0 && _.map(listing, (data, key) => {
                    {/* let setBgIndex = key % 6;
                    let bonusBgColor = bgColors[setBgIndex]; */}
                    return (
                        <Grid item xs={12} key={key} style={{ margin:'5px 0', width: '100%', padding:0 }} className='bonusItemAlign'>
                            <BonusItem
                                bonusName={data?.bonus_type}
                                bonusCode={data?.bonus_type}
                                bonusValue={data?.total_amount || '0'}
                                bonusDecimal={2}
                                bonusType={data?.name?.[i18n.language] ? data?.name?.[i18n.language] : data?.name?.cn}
                                // background={bonusBgColor}
                                // labelColor={setBgIndex === 0 ? '#000000' : '#ffffff'}
                                urlLink={`/bonus?b=${btoa(data?.id)}`}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

export default BonusListing;