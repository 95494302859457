import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid, Dialog, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/styles';
import moment from 'moment-timezone';

import Receipt from '@layouts/Receipt';

const PlanHistoryItem = props => {
    const { history } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#006638',
        color: '#fff',
        text: t('transfer.completed'),
    });

    const decimalPlaces = 2;
    const serviceFee = parseFloat(history?.details?.service_fee) || 0;

    const formattedAmount = (parseFloat(history?.price) + serviceFee).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });

    const sign = '';
    const color = '#F33D4A';

    const dateObject = new Date(history?.created_at);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    const formattedDate = moment(history?.created_at.replace(/-/g, "/")).format("YYYY-MM-DD");

    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    // const time = `${hours}:${minutes}:${seconds}`;
    const time = moment(history?.created_at.replace(/-/g, "/")).format("HH:mm:ss");

    const percent = (parseFloat(history?.profit_limit_balance) / parseFloat(history?.profit_limit)) * 100;

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Box>
            <div onClick={() => setDialogOpen(true)}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    {/* Type */}
                    <p className='fs-14' style={{ fontWeight: "bold", textAlign: "left", color: '#E7E7E7' }}>
                        {t('planHistory.package') + ' - '}{history?.plan_name ? history?.plan_name?.[i18n.language] : history?.plan_name?.process.env.REACT_APP_DEFAULT_LANGUAGE}
                    </p>

                    {/* Amount */}
                    <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "right", color: color, wordBreak: 'break-all' }}>
                        {history?.factor === -1 && sign}- $ {formattedAmount}
                    </Typography>
                </div>

                {/* ULID */}
                {/* <div style={{ marginTop: 10 }}>
                    <Typography noWrap style={{ color: "#747474", fontSize: 12 }}>TXID: {history.ulid}</Typography>
                </div> */}

                {/* Date and time */}
                <p className='fs-12' style={{ color: '#747474' }}>{`${formattedDate} ${time}`}</p>
                <p className='fs-12' style={{ color: '#747474' }}>{t('planHistory.typePurchase')}</p>
                {/* Profit Limit */}
                {/* <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography noWrap style={{ color: "#747474", fontSize: 12 }}>{t('receipt.profitLimit')}</Typography>
                    <Box style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '40%' }}>
                        <div style={{ position: 'absolute', width: '100%', zIndex: 1 }}>
                            <Typography style={{ textAlign: 'end', color: "#fff", fontSize: 12, paddingRight: 5 }}>{history?.profit_limit_balance} / {history?.profit_limit}</Typography>
                        </div>
                        <div style={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={percent} style={{ width: '100%', height: 14, borderRadius: 49 }} />
                        </div>
                    </Box>
                </div> */}

                {/* Rectangle Bottom */}
                {/* <div className='s-full m-t-10' style={{ height: 4, background: '#F1F1F1', boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 2px 0px #FFF' }}></div> */}
            </div>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <Box style={{ maxWidth: 400, overflow: 'hidden' }}>
                    <Receipt type={'plan-history'} data={history} handleClose={handleDialogClose} />
                </Box>
            </Dialog>
        </Box>
    )
}

export default PlanHistoryItem;