import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

let theme = createTheme({
    palette: {
        primary: {
            main: '#121C28',
            secondary: '#253240'
        },
        secondary: {
            main: '#E1AC46',
            secondary: '#EDC77E',
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        gray: {
            main: '#848D9B',
            secondary: '#b5b5b5',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        divider:{
            background:'#31455B'
        },
        button: {
            main: '#CDAC89',
            borderMain: '2px solid #CDAC89',
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        text:{
            content:'#CFCCCC',
            title:'#E1AC46',
            base:'#121C28'
        },
        bottomIconColor: '#ffffff',
        selectedBottomIconColor: '#D6A364',
        backgroundColor: '#020202',
        mainBackground: '#efefef',
        white: '#ffffff',
        dialogContentBg: '#D6A364',
        gold: '#F7A600',
        sectionBackgroundColor: '#16171C',
    },
    components: {
        // Name of the component
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    backgroundColor: '#1B1B23',
                    height: 63,
                },
                
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#848D9B',
                    minWidth:'20%'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 100,
                    textTransform: 'capitalize',
                    // borderTopLeftRadius: 40,
                    // borderBottomRightRadius: 40,
                    backgroundColor:'#F7A600',
                    padding: "6px 12px",
                    fontWeight: 600,
                    color:'#100F15',
                    // boxShadow:'rgb(0 0 0) 4px 4px 7px 0px, inset rgb(255 255 255 / 27%) 2px 4px 5px 0px',
                    '&:hover': {
                        backgroundColor:'#F7A600',
                        color:'#100F15',
                    },
                    '&:disabled': {
                        backgroundColor: '#6F5010',
                        color: '#16171C'
                    }
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root:hover::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Default state of text input field font.
                    // '& .MuiInputBase-input.MuiInput-input': {
                    //     color: '#a8bfd9',
                    // },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: '#a8bfd9',
                    },

                    '& .MuiInputBase-root': {
                        color: '#a8bfd9',
                        fontSize: '0.875rem'
                    },

                    '& .MuiFormLabel-root.Mui-focused': {
                        color: '#e1ac46',
                    },

                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: '#a8bfd9',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },

                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                        color: '#a8bfd9',
                    },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                        borderBottom: 'none',
                    },
                },
            },
        },
      
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: '#28292E',
                    borderRadius: 6,
                    flexShrink: 0,
                    // boxShadow: '3px 3px 6px 0px rgba(255, 255, 255, 0.20), 3px 3px 2px 0px rgba(0, 0, 0, 0.76) inset',
                    height: 50,
                    padding: '12px 14px',
                    textDecorationLine: "none",
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: 'rgba(221, 242, 254, 0.51)',
                        borderRadius: '0px !important',
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                    '&::placeholder': {
                        color: 'rgba(221, 242, 254, 0.51)',
                        opacity: 1
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(221, 242, 254, 0.51)',
                    fontSize: '0.75rem'
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    ":after": {
                        borderTop: "thin solid #ffffff"
                    },
                    ":before": {
                        borderTop: "thin solid #ffffff"
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#8495a1',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#8495a1',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'transparent',
                },
                scrollButtons: {
                    color: '#565656',
                }
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#D6A364',
                },
                arrow: {
                    color: '#D6A364',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#E1AC46',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
                underline: {
                    "&&&:before": {
                        borderBottom: "none"
                    },
                    "&&:after": {
                        borderBottom: "none"
                    }
                }
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D6A364',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    color: '#E1AC46'
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    gap: 16,
                    borderRadius: 12,
                    alignItems: 'center',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#E1AC46',
                },
            },
        },
        
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#E1AC46',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
              & .MuiBottomNavigation-root &.Mui-selected {
                color: #F7A600 !important;
              },
              & .MuiTab-root.Mui-selected{
                color: ${themeParam.palette.primary.main} !important;
              },
            `,
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    maxWidth: 444,
                    margin: '0 auto',
                    backgroundColor:'#253240'
                }
            }
        }

    }
});

theme = responsiveFontSizes(theme);

export default theme;