import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

import { currencyFormat } from '@utils/Tools';
import { storePaths } from '@utils/Tools';
import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import { FaCheck, FaArrowCircleDown } from "react-icons/fa";

const Receipt = props => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { type, data, completedLink } = props;

    let location = useLocation();
    let navigate = useNavigate();
    const containerRef = useRef();
    let currDate = moment();

    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 10;

            if (isAtBottom) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
        };

        // Attach the scroll event listener to the container
        container.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => { container.removeEventListener('scroll', handleScroll); };
    }, []);

    const handleComplete = () => {
        navigate(completedLink, { state: { from: storePaths(location) } });
    }

    // Determine whether have the service fee
    const serviceFee = _.size(data?.details) > 0 ? parseFloat(data?.details?.['service_fee']) : 0;

    // Extract the decimal value from the wallet data, default to 2 if not available
    const decimalPlaces = data?.wallet_balance?.wallet?.decimal || 2;

    // Format the amount with the appropriate number of decimal places
    const formattedAmount = parseFloat(type === 'transaction' ? data?.amount : parseFloat(data?.price)).toFixed(decimalPlaces);

    // Determine the sign and color based on the transaction.factor
    const sign = data?.factor === 1 ? '+' : (data?.factor === -1 ? '-' : '');
    const color = data?.factor === -1 ? '#C2872E' : '#3C946F';

    const handleClose = () => {
        // Call the handleClose callback passed from TransactionItem
        props.handleClose();
    };

    return (
        <Box>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(90deg, #FFC122 0.01%, #FFECBC 28.01%, #DBAA2C 100%)', height: 17, width: '100%', borderRadius: 49 }}>
                <div style={{ background: theme.palette.sectionBackgroundColor, height: 11, width: '98%', borderRadius: 49 }} />
            </div>
            <Box ref={containerRef} style={{ width: '95%', height: (type === 'transaction' ? '100%' : 450), overflow: 'auto', position: 'relative', top: -10, left: '2.5%' }}>
                <Box className='p-lr-20 p-b-30' style={{ boxShadow: 'inset #ffffff33 -1px -1px 10px 1px', borderRadius: 0, backgroundColor: theme.palette.sectionBackgroundColor }}>
                    {/* Close button */}
                    <Box style={{ width: '100%', textAlign: 'right' }}>
                        <IconButton style={{color:'#EDC77E'}} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box style={{ marginBottom: 30 }}>
                        <Box className="flex-c-m">
                            {
                                type === 'transaction' && data?.wallet_transactionable?.status_display?.code != 60 && data?.transaction_type == "withdraw"
                                    ? null
                                    : <FaCheck className={styles.checkIcon} />
                            }
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                {
                                    type === 'transaction'
                                        ?
                                        <Typography
                                            className={
                                                data?.wallet_transactionable?.status_display?.code == 60 || data?.transaction_type != "withdraw"
                                                    ? styles.colorGreen
                                                    : data?.wallet_transactionable?.status_display?.code == 20 || data?.wallet_transactionable?.status_display?.code == 40
                                                        ? styles.colorLightGrey
                                                        : styles.colorRed

                                            }
                                            style={{ fontWeight: 'bold' }}
                                            variant='h6'>
                                            {data?.transaction_code_display?.details[i18n.language] ? data?.transaction_code_display?.details[i18n.language] : data?.transaction_code_display?.details?.en}
                                        </Typography>
                                        :
                                        <Typography className={styles.colorGreen} style={{ fontWeight: 'bold' }} variant='h6'>
                                            {t('planHistory.planPurchase')}
                                        </Typography>
                                }
                            </Box>
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                <Typography variant='body2' className={styles.colorGrey}>{t('receipt.subtitle')}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Receipt Details */}
                    <div>
                        {/* creator & recipient */}
                        { type==='plan-history' &&
                        <>
                            { data?.role_id===4 && 
                            <Box className={styles.detailsLayout}>
                                <Typography variant="body2" className={styles.colorGrey} style={{ marginRight: 20 }}>{t('receipt.initiator')}</Typography>
                                <Typography variant="body2" className={styles.colorGrey} style={{ width: '70%', wordBreak: 'break-word' }}>{data?.initiator}</Typography>
                            </Box>
                            }
                            <Box className={styles.detailsLayout}>
                                <Typography variant="body2" className={styles.colorGrey} style={{ marginRight: 20 }}>{t('receipt.recipient')}</Typography>
                                <Typography variant="body2" className={styles.colorGrey} style={{ width: '70%', wordBreak: 'break-word' }}>{data?.recipient}</Typography>
                            </Box>
                        </>
                        }

                        {/* refNo */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey} style={{ marginRight: 20 }}>{t('receipt.refNo')}</Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ width: '70%', wordBreak: 'break-word' }}>{data?.ulid}</Typography>
                        </Box>

                        {/*Date Time */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2" className={styles.colorGrey}>{t('receipt.dateTime')}</Typography>
                            <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>{data?.created_at}</Typography>
                        </Box>
                    </div>

                    <div className='divider-design'></div>

                    {
                        data?.transaction_type == "withdraw"
                            ?
                            <>
                                <div>
                                    {/*status */}
                                    <Box className={styles.detailsLayout}>
                                        <Typography variant="body2" className={styles.colorGrey}>{t('receipt.status')}</Typography>
                                        <Typography variant="body2" className={styles.colorGrey} style={{ textAlign: "right" }}>{data?.wallet_transactionable?.status_display?.details[i18n.language]}</Typography>
                                    </Box>
                                </div>
                                <div className='divider-design'></div>

                            </>
                            : null
                    }

                    {
                        type === 'transaction'
                            ? (
                                <>
                                    {/*transaction, type & amount*/}
                                    <>
                                        {/* <div>
                                            <Box>
                                                <Typography variant="body2" className={styles.colorGrey}>{t('receipt.transaction')}</Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout}>
                                                <Typography variant="body2" className={styles.colorGrey}>
                                                    {data?.transaction_code_display?.details[i18n.language] ? data?.transaction_code_display?.details[i18n.language] : data?.transaction_code_display?.details?.en}
                                                </Typography>
                                                <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                    {data?.factor === -1 && sign} $ {currencyFormat(formattedAmount)}
                                                </Typography>
                                            </Box>
                                        </div>

                                        <div className='divider-design'></div> */}
                                    </>

                                    {/* remark */}
                                    {
                                        _.size(data.remark) > 0 &&
                                        <>
                                            <Box style={{ marginBottom: 10 }}>
                                                <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 5 }}>{t('receipt.remark')} </Typography>
                                                {
                                                    data.remark?.member_remark
                                                        ? <Typography variant="body2" className={styles.colorGrey}>{data.remark?.member_remark ? data.remark?.member_remark : '-'}</Typography>
                                                        : <Typography variant="body2" className={styles.colorGrey}>{data.remark?.admin_remark ? data.remark?.admin_remark : '-'}</Typography>
                                                }
                                            </Box>

                                            <div className='divider-design'></div>
                                        </>
                                    }

                                    <>
                                        {/* total amount */}
                                        <Box className={styles.detailsLayout} style={{ margin: '10px 0 0 0' }}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={styles.colorGrey}>{t('receipt.amount')}</Typography>
                                            <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", width: '70%', color:'#CFCCCC' }}>
                                                {(data?.wallet_balance?.wallet?.code==='gc'||data?.wallet_balance?.wallet?.code==='nc' || data?.wallet_balance?.wallet?.code==='ngc')?'':'$ '}{currencyFormat(formattedAmount)}
                                            </Typography>
                                        </Box>
                                    </>
                                </>
                            )
                            : (
                                <>
                                    {/*transaction, type & amount*/}
                                    <>
                                        <div>
                                            <Box>
                                                <Typography variant="body2" className={styles.colorGrey}>{t('receipt.transaction')}</Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ alignItems: 'flex-end' }}>
                                                <Typography variant="body2" className={styles.colorGrey} style={{ width: '50%' }}>
                                                    {t('planHistory.planPurchase')} ({data?.plan_name?.[i18n.language]})
                                                </Typography>
                                                <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                    {data?.factor === -1 && sign} $ {currencyFormat(formattedAmount)}
                                                </Typography>
                                            </Box>
                                        </div>

                                        <div className='divider-design'></div>
                                    </>

                                    {/*profit limit, profit limit balance & roi */}
                                    <>
                                        <Box style={{ marginBottom: 10 }}>
                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2" className={styles.colorGrey}>
                                                    {t('planHistory.profitLimit')}
                                                </Typography>

                                                <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.profit_limit).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2" className={styles.colorGrey}>
                                                    {t('planHistory.profitLimitBalance')}
                                                </Typography>

                                                <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.profit_limit_balance).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            {
                                                (data?.roi?.locked > 0)
                                                    ?
                                                    <>
                                                    <div className='divider-design'></div>
                                                    <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                                                        <Typography variant="body2" className={styles.colorGrey}>
                                                            {t('planHistory.roiLockedAmount')}
                                                        </Typography>

                                                        <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                                            <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                                $ {parseFloat(data?.roi?.locked_amount).toFixed(2)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    </>
                                                    :
                                                    null
                                            }
                                            
                                            {
                                                (data?.roi?.total_distributions > 0) && (data?.roi?.percent > 0)
                                                    ?
                                                    <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                                                        <Typography variant="body2" className={styles.colorGrey}>
                                                            {t('planHistory.roi')}
                                                        </Typography>

                                                        <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                                            <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                                {data?.roi?.received_times} / {data?.roi?.total_distributions} ({parseFloat(data?.roi?.percent).toFixed(2)} %)
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    null
                                            }

                                            {
                                                (parseFloat(data?.roi?.received_times) < parseFloat(data?.roi?.total_distributions) && data?.roi?.ROI_day >= currDate.format('YYYY-MM-DD')) &&
                                                <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                                                    <Typography variant="body2" className={styles.colorGrey}>
                                                        {t('receipt.roiDate')}
                                                    </Typography>

                                                    <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                                        <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                            {data?.roi?.ROI_day}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>

                                        <div className={styles.rectangle}></div>
                                    </>

                                    {/* remark */}
                                    <>
                                        {
                                            data?.admin_remark
                                                ? (
                                                    <>
                                                        <Box style={{ marginBottom: 10 }}>
                                                            <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 5 }}>{t('receipt.remark')} </Typography>
                                                            {
                                                                data?.member_remark
                                                                    ? <Typography variant="body2" className={styles.colorGrey}>{data?.member_remark ? data?.member_remark : '-'}</Typography>
                                                                    : <Typography variant="body2" className={styles.colorGrey}>{data?.admin_remark ? data?.admin_remark : '-'}</Typography>
                                                            }
                                                        </Box>

                                                        <div className='divider-design'></div>
                                                    </>
                                                )
                                                : (null)
                                        }
                                    </>

                                    <>
                                        {/* total */}
                                        <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                            <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 5 }}>{t('receipt.total')} </Typography>
                                            <Typography variant="body2" className={styles.colorGrey}>$ {currencyFormat((parseFloat(data?.price)).toFixed(2))}</Typography>
                                        </Box>

                                        {/* service tax */}
                                        {/* <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                            <Typography variant="body2" className={styles.colorGrey} style={{ marginBottom: 5 }}>{t('receipt.serviceFee')} </Typography>
                                            <Typography variant="body2" className={styles.colorGrey}>$ {currencyFormat(serviceFee.toFixed(2))}</Typography>
                                        </Box> */}

                                        {/* total amount */}
                                        <Box className={styles.detailsLayout} style={{ margin: '10px 0 0 0' }}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }} className={styles.colorGrey}>{t('receipt.amount')}</Typography>
                                            <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "right", color: '#CFCCCC', width: '70%' }}>
                                                {'$ ' + currencyFormat((parseFloat(data?.price) + serviceFee).toFixed(2))}
                                            </Typography>
                                        </Box>
                                    </>
                                </>
                            )
                    }

                    {/* payment method */}
                    {
                        // _.size(data?.payment_methods) > 0 &&
                        data?.payment_display &&
                        <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                            <Typography variant="body2" className={styles.colorLightGrey} style={{ marginBottom: 5 }}>{t('receipt.paymentMethod')} </Typography>
                            <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                {/* {
                                    _.size(data?.payment_methods) > 0
                                        ? _.map(data?.payment_methods, (payment, key) => (
                                            <Typography key={key} variant="body2" className={styles.colorLightGrey}>{payment}</Typography>
                                        ))
                                        : <Typography variant="body2" className={styles.colorLightGrey}>-</Typography>
                                } */}
                                {
                                    <Typography variant="body2" className={styles.colorLightGrey}>{data?.payment_display}</Typography>
                                }
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>

            <div style={{ height: 43, position: 'relative', top: -10, background: 'linear-gradient(0deg, #16171c 3.64%, #26292e 90.55%)', borderRadius: '0 0 30px 30px', filter: 'drop-shadow(0px -5px 9px rgba(0, 0, 0, 0.07))' }} />
            {
                type !== 'transaction' &&
                <div style={{ position: 'relative', bottom: 0, width: '100%', textAlign: 'center' }}>
                    <FaArrowCircleDown style={{ fontSize: 25, color: !isBottom ? '#cfcccc' : 'transparent' }} />
                </div>
            }

            {
                completedLink
                    ?
                    <Box className="flex-c-m">
                        <Button variant="contained" style={{ position: "absolute", bottom: 30 }} onClick={handleComplete}>{t('payment.completed')}</Button>
                    </Box>
                    : null
            }
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    colorGreen: {
        color: "#EDC77E"
    },
    colorGrey: {
        color: "#CFCCCC",
        fontWeight:500
    },
    colorLightGrey: {
        color: '#cfcccc',
    },
    colorRed: {
        color: '#FF0101',
    },
    checkIcon: {
        color: "#EDC77E",
        width: 100,
        height: 50
    },
    detailsLayout: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15
    },
    rectangle: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 2px 0px #FFF',
        height: '4px',
        margin: '5px 0px 15px 0px'
    },
}));

export default Receipt;