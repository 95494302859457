import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid, Dialog } from '@mui/material';
import { useTheme } from '@mui/styles';
import moment from 'moment-timezone';

import Receipt from '@layouts/Receipt';

const TransactionItem = props => {
    const { transaction, selectedWalletId } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#006638',
        color: '#fff',
        text: t('transfer.completed'),
    });

    // Extract the decimal value from the wallet data, default to 2 if not available
    const decimalPlaces = transaction?.wallet_balance?.wallet?.decimal || 2;

    // Format the amount with the appropriate number of decimal places
    const formattedAmount = parseFloat(transaction?.amount).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });    

    // Determine the sign and color based on the transaction.factor
    const sign = transaction?.factor === 1 ? '+' : (transaction?.factor === -1 ? '-' : '');
    const color = transaction?.factor === -1 ? '#F33D4A' : '#2fbe81';

    // Date and time format
    const dateObject = new Date(transaction?.created_at);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    const formattedDate = moment(transaction?.created_at.replace(/-/g, "/")).format("YYYY-MM-DD");

    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    // const time = `${hours}:${minutes}:${seconds}`;
    const time = moment(transaction?.created_at.replace(/-/g, "/")).format("HH:mm:ss");

    // const transactionIcon = () => {
    //     let img = '../images/icons/empty.png';
    //     switch(transaction.transaction_type){
    //         case 'payment':
    //         case 'payment-reverse':
    //             img='../images/icons/purchase.png'; break;
    //         case 'promotion':
    //         case 'promotion-reverse':
    //             img='../images/icons/reward.png'; break;
    //         case 'member-topup':
    //         case 'member-topup-reverse':
    //             img='../images/icons/topup.png'; break;
    //         case 'transfer':
    //         case 'convert':
    //             img='../images/icons/transfer.png'; break;
    //         case 'withdraw':
    //         case 'withdraw-refund':
    //             img='../images/icons/withdrawal.png'; break;
    //         default: break;
    //     }
    //     return(
    //         <div style={{ height: 85, width: 85 }}>
    //             <MerchantItem shopLogo={img} />
    //         </div>
    //     )
    // }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Box>
            <Grid container alignItems="center" onClick={() => setDialogOpen(true)}>
                {/* <Grid item xs={3.5}>
                    {transactionIcon()}
                </Grid> */}

                <Grid item xs={12}>
                    {/* Type */}
                    <p className='fs-14 txt-content' style={{ fontWeight: "bold"}}>
                        {transaction?.transaction_code_display?.details[i18n.language] ? transaction?.transaction_code_display?.details[i18n.language] : transaction?.transaction_code_display?.details?.en}
                    </p>

                    {/* Payment/Transfer/Convert details */}
                    {/* <div>
                        {transaction?.transaction_type === 'payment' && (
                            <Typography variant='caption' style={{ color: '#747474' }}>
                                {t('Payment to')} {transaction?.wallet_transactionable?.branch?.branch_name}
                            </Typography>
                        )}

                        {(transaction?.transaction_type === 'transfer' || transaction?.transaction_type === 'convert') && (
                            <Typography variant='caption' style={{ color: '#747474' }}>
                                {transaction.factor === 1
                                    ? `${t('Receive from')} ${transaction?.details?.from}`
                                    : `${t('Transfer to')} ${transaction?.details?.to}`
                                }
                            </Typography>
                        )}
                    </div> */}

                    {/* Remark and Amount */}
                    <div className='flex-sb-m' style={{ marginTop: 10 }}>
                        <div style={{width: '70%'}}>
                        {
                            transaction && transaction.remark ? (
                                <div style={{ textAlign: 'left' }}>
                                    {transaction.remark.admin_remark && ( <Typography noWrap style={{ color: "#cfcccc", fontSize: "12px" }}>{transaction.remark.admin_remark}</Typography> )}
                                    {transaction.remark.member_remark && ( <Typography noWrap style={{ color: "#cfcccc", fontSize: "12px" }}>{transaction.remark.member_remark}</Typography> )}
                                </div>
                            ) : ( <Typography noWrap style={{ color: "#cfcccc", fontSize: "12px" }}>-</Typography> )
                        }
                        </div>

                        {/* Amount */}
                        <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "right", color: color }}>
                            {transaction?.factor === -1 && sign} {(selectedWalletId===5||selectedWalletId===6||selectedWalletId===9)?'':'$'} {formattedAmount}
                        </Typography>
                    </div>

                    {/* Date and time */}
                    <p className='txt-content fs-12' >
                        {`${formattedDate}  \u00a0   |  \u00a0   ${time}`}
                    </p>

                    {/* Remarks */}
                    {/* <div>
                        {transaction && transaction.remark && (
                            <div>
                                {transaction.remark.admin_remark && (
                                    <div>
                                        <Typography noWrap style={{ color: "#2B2B2B", fontSize: "12px" }}>{t('transactionListing.adminRemark')}{transaction.remark.admin_remark}</Typography>
                                    </div>
                                )}

                                {transaction.remark.member_remark && (
                                    <div>
                                        <Typography noWrap style={{ color: "#2B2B2B", fontSize: "12px" }}>{t('transactionListing.memberRemark')}{transaction.remark.member_remark}</Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </div> */}

                    {/* Rectangle Bottom */}
                    <div className='divider-design'></div>
                </Grid>

            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                }}
            >
                <Box style={{maxWidth: 400}}>
                    <Receipt type={'transaction'} data={transaction} handleClose={handleDialogClose} />
                </Box>
            </Dialog>
        </Box>
    )
}

export default TransactionItem;