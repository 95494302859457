import axios from 'axios';
import { removeLoginAccess } from '@utils/ApiAction';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
    },
});

API.interceptors.response.use(
    (res) => {
        console.log("response for: " + res.config.url, res);
        if(res.data.maintenance){
            window.location.href = process.env.REACT_APP_BASE_URL+"/maintenance";
            return res.data;
        }else if(res.status === 200) {
            return res.data;
        }
    },
    (err) => {
        console.log(err.toJSON());
        if(err && err.response) {
            switch(err.response.status) {
                case 400:
                    console.log("Request error")
                    break;
                case 401:
                    console.log("Unauthorized access")
                    removeLoginAccess();
                    break;
                case 403:
                    console.log("No Permission");
                    return err.response.data;
                case 429:
                    console.log("Too Many Requests");
                    return err.response.data;
    
                default:
                    console.log('Other error messages');
            }
        }
        return Promise.reject(err);
    }
)

export { API };