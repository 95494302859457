import Receipt from '@layouts/Receipt';
import { Box, Button, Dialog, IconButton, InputAdornment, InputLabel, MenuItem, TextField, Typography, Grid, Container } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, useParams } from 'react-router-dom';


export default function GameTopUp() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);

    const [inputErrors, setInputErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [wallets, setWallets] = useState({});
    const [selectedGame, setSelectedGame] = useState({});
    const [selectedWallet, setSelectedWallet] = useState({});
    const [result, setResult] = useState({});

    const [state, setState] = useState({
        amount: "",
        securityPassword: "",
        game_code: id,
        wallet_id: 0
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        navigate('/games');
    };

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if(id){
            getUrl(`/game-topup-setting/${id}`).then(response => {
                if (isMountedRef.current) {
                    let { status, data } = response;
                    if (status) {
                        setWallets(data.wallets);
                        setSelectedGame(data.game);
                    }
                }
            }).catch(error => {
                if (isMountedRef.current) {
                    addAlert(JSON.stringify(error.message));
                }
            }).finally(() => {
                setLoading(false);
            });
        }
        else{
            navigate('/games');
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(wallets && state.wallet_id > 0){
            setSelectedWallet(_.find(wallets, {id: state.wallet_id}));
        }

    }, [state.wallet_id]);

    const gameTopup = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            security_password: state.securityPassword,
            amount: state.amount,
            wallet_id: state.wallet_id,
            game_code: state.game_code
        }
        postUrl(`/game-topup`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setResult(response.data);
                addAlert('', t('success.topupSuccess'), 'success', '');
                navigate('/games');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.topupError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Box className='p-lr-20 p-t-80' style={{ minHeight: '100vh', position: "relative" }}>
                <Box className='p-t-16' style={{  paddingBottom: 150 }}>
                    <Grid className='pos-relative' container>
                        <Grid item xs={12}>
                            <InputLabel>{t('game.gameSelected')}</InputLabel>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '6px', color: theme.palette.gold, height: 46 }}}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={selectedGame?.name ? `${t('game.gameDashName', {game: selectedGame?.name} )}` : ""}
                                className={styles.textFieldStyle}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <InputLabel>{t('game.currency')}</InputLabel>
                            <TextField
                                style={{ paddingTop: 10, paddingBottom: 10 }}
                                sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                                variant="standard"
                                select
                                placeholder={t('game.currency')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                name='wallet_id'
                                value={state?.wallet_id}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.wallet_id ? inputErrors.wallet_id : ''}
                                error={inputErrors && inputErrors.wallet_id ? true : false}
                            >
                                <MenuItem value={0} disabled>{t('general.pleaseSelect')}</MenuItem>
                                {
                                    _.map(wallets, (_wallet, _key) => {
                                        return (
                                            <MenuItem key={_key} value={_wallet.id}>
                                            {(_wallet?.name ? _wallet?.name?.[i18n.language] : _wallet?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE])}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <div className="flex-sb-m">
                                <InputLabel>{t('game.amount')}</InputLabel>
                                <span style={{ color: 'white', fontSize: '0.75rem', textTransform: 'uppercase' }}>{`${currencyFormat(selectedWallet?.wallet_balances?.[0]?.balance || 0)} ${selectedWallet?.code || ""}`}</span>
                            </div>
                            <TextField
                                style={{ paddingTop: 10, paddingBottom: 10 }}
                                placeholder={t('game.amountPlaceholder')}
                                sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                                variant='standard'
                                fullWidth
                                autoComplete='amount'
                                name='amount'
                                value={state?.amount}
                                onChange={({ target }) => setState({ ...state, amount: target.value })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography onClick={() => setState({ ...state, amount: parseFloat(selectedWallet?.wallet_balances?.[0]?.balance).toFixed(0) })} style={{ color: theme.palette.gold, fontSize: '0.875rem', cursor: 'pointer' }}>{t('game.max')}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                inputErrors && inputErrors.amount
                                    ?
                                    <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                                    :
                                    null
                            }
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <InputLabel>{t('user.securityPassword')}</InputLabel>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '6px', height: 46 } }}
                                variant="standard"
                                placeholder={t('plan.securityPasswordPlaceholder')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle securityPassword visibility"
                                                onClick={() => handleClickShowPassword('securityPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword?.securityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                name='securityPassword'
                                type={showPassword?.securityPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.securityPassword}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    disableScrollLock={true}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <Box style={{ marginTop: 20, maxWidth: 350 }}>
                        <Receipt type={'plan-history'} data={result} handleClose={handleDialogClose} />
                    </Box>
                </Dialog>
                <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#16171C' }}>
                    <div className='p-b-10 dis-flex flex-m flex-sb-m'>
                        <Typography style={{fontSize: "0.75rem", color: theme.palette.gray.main, fontWeight:500}}>{t('game.totalAmount')}</Typography>
                        <Typography style={{fontSize: "0.75rem", color: "white", fontWeight:500, textTransform: 'uppercase'}}><b>{state.wallet_id > 0 && selectedWallet?.code} {currencyFormat((state?.amount || 0))}</b></Typography>
                    </div>
                    <div className='w-full flex-c-m' style={{ bottom: 10 }}>
                        <Button fullWidth variant='contained' className='big-button' onClick={gameTopup}>
                            {t('game.topupNow')}
                        </Button>
                    </div>
                </Container>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
}));