import moment from 'moment-timezone';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Link } from '@mui/material';

import { makeStyles, useTheme } from '@mui/styles';
import { changeLanguage } from '@actions';
import { FiLock, FiUnlock } from "react-icons/fi";



export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className=' p-t-25 p-b-110' style={{ minHeight: '90vh' }}>
            <div className='p-t-50'>
                <p className='txt-center fs-19' style={{color:'#82512D'}}><b>{t('trade.tradingSummary')}</b></p>
            </div>
            
            {/* ///////////////  Chart  ///////////////// */}
            <div className='p-tb-30 p-lr-35 pos-relative flex-c-m w-full' style={{ backgroundImage: 'url(/images/trade/mountain.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center' }}>
                <div className='flex-m pos-absolute' style={{ width: 'fit-content', transform: 'rotate(-90deg)', top: 105, left: 0 }}>
                    <div className='flex-m' style={{ width: 'fit-content' }}>
                        <div style={{ height: 15, width: 15, borderRadius: 48, backgroundColor: '#DE9D49' }} />
                        <p className='p-l-8' style={{ color: '#565656', fontWeight: 500 }}>{t('trade.unlock')}</p>
                    </div>
                    <div className='flex-m p-l-20' style={{ width: 'fit-content' }}>
                        <div style={{ height: 15, width: 15, borderRadius: 48, backgroundColor: '#82512D' }} />
                        <p className='p-l-8' style={{ color: '#565656', fontWeight: 500 }}>{t('trade.lock')}</p>
                    </div>
                </div>
                <div style={{ boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 4px 4px 6px 0 #fff', height: 185, width: 185, borderRadius:'100%', padding:15 }}>
                    <div className='p-all-8 w-full h-full' style={{background:' #82512D', boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset', borderRadius:'100%'}}>
                    </div>
                </div>
            </div>

            {/* ///////////////  Lock & Unlock Amount  ///////////////// */}
            <div className='flex-sb-m p-t-30 p-lr-35'>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiLock className='fs-21'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.lock')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#7D4E2B', height: 80, width: 9, background: 'rgb(125, 78, 43)', position: 'absolute', bottom: 7, right: -4}} />
                </div>
                <div className='p-all-15 pos-relative' style={{ width: '48%', borderRadius: 10, background: '#E8E8E8', boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 0.87), 2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset'}}>
                    <div style={{color:'#565656'}}>
                        <FiUnlock className='fs-21'/>
                        <p className='fs-18'><b>10,253.58</b></p>
                        <p className='txt-upper fs-12' style={{fontWeight:500}}>{t('trade.unlock')}</p>
                    </div>
                    <div style={{height:80, width:5, background:'#DE9D49', height: 80, width: 9, background: '#DE9D49', position: 'absolute', bottom: 7, right: -4}} />
                </div>
            </div>

            {/* ///////////////  Button Trading & Market  ///////////////// */}
            <div className='flex-sb-m p-t-20 p-lr-35'>
                <Link component={RouterLink} to={`/trade/trading`} state={{ from: location }} underline='none' style={{width: '48%',}}>
                    <div className='p-all-10 shadow-out w-full bor-10' style={{background: '#E8E8E8', height: 70 }}>
                        <div className='flex-m w-full h-full'>
                            <div className='flex-c-m' style={{ boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 2px 2px 2px 0px #FFF', padding: 5, borderRadius: '100%', width: 45, height: 45 }}>
                                <div className='flex-c-m w-full h-full' style={{ boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(255, 255, 255, 0.84) inset', padding: 5, borderRadius: '100%' }}>
                                    <img src='/images/trade/trade.png' alt="icon" className='max-w-full' />
                                </div>
                            </div>
                            <p className='txt-upper fs-14 p-l-10' style={{ color: '#82512D' }}>
                                {t('trade.trading')}
                            </p>
                        </div>
                    </div>
                </Link>
                <Link component={RouterLink} to={`/trade/marketplace`} state={{ from: location }} underline='none' style={{ width: '48%', }}>
                    <div className='p-all-10 shadow-out w-full bor-10' style={{ background: '#E8E8E8', height: 70 }}>
                        <div className='flex-m w-full h-full'>
                            <div className='flex-c-m' style={{ boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 2px 2px 2px 0px #FFF', padding: 5, borderRadius: '100%', width: 45, height: 45 }}>
                                <div className='flex-c-m w-full h-full' style={{ boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(255, 255, 255, 0.84) inset', padding: 5, borderRadius: '100%' }}>
                                    <img src='/images/trade/market.png' alt="icon" className='max-w-full' />
                                </div>
                            </div>
                            <p className='txt-upper fs-14 p-l-10' style={{ color: '#82512D' }}>
                                {t('trade.market')}
                            </p>
                        </div>
                    </div>
                </Link>
            </div>

             {/* ///////////////  Transaction  ///////////////// */}
            <div className='p-lr-35 p-t-30'>
                <div className='w-full flex-sb-m p-b-20'>
                    <p style={{ color: '#9B9896' }} className='fs-18'>{t('title.transaction')}</p>
                    <p style={{ color: '#82512D', fontWeight: 500 }} className='fs-16' >{t('button.all')}</p>
                </div>
                {_.map([1, 2, 3], data => {
                    return (
                        <div className='p-b-10'>
                            
                            <div className='flex-sb-m p-b-10'>
                                <div style={{ color: '#565656' }}>
                                    <p>Package Unit Rewards</p>
                                    <p style={{ fontWeight: 500 }}>2023-12-21</p>
                                </div>
                                <div>
                                    <p>100</p>
                                </div>
                            </div>
                            <div className='w-full' style={{ height: 5, boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF', background: '#F1F1F1' }} />
                        </div>
                    )
                })}
                <p className='txt-center p-t-20' style={{fontWeight:500, color:'#a0a0a0'}}>- {t('table.endOfTheList')} -</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    buttonStyle: {
        width: 300,
        height: 50,
        marginTop: 20,
    },
}));