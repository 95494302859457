import moment from 'moment-timezone';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import { changeLanguage } from '@actions';
import { FiTag, FiShoppingBag } from "react-icons/fi";



export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className=' p-t-25 p-b-110' style={{ minHeight: '90vh' }}>
            <div className='p-tb-50 p-lr-35 pos-relative flex-c-m w-full' style={{ backgroundImage: 'url(/images/trade/mountain.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center' }}>
                <p className='txt-center txt-title fs-19' style={{color:'#82512D'}}><b>{t('title.marketplace')}</b></p>
            </div>
          

             {/* ///////////////  Transaction  ///////////////// */}
            <div className='p-lr-35'>

                <div className='shadow-in p-lr-20 p-tb-10 bor-10'>
                    <div className='flex-sb-m w-full p-tb-10 list-divider' style={{ color: '#565656'}}>
                        <p><b>{t('trade.remainingUnit')}</b></p>
                        <p><b>{t('trade.rate')}</b></p>
                    </div>
                    {_.map([1, 2, 3, 4, 5], data => {
                        return (
                            <div className='p-b-10 list-divider p-tb-10'>
                                <div className='flex-sb-m w-full' style={{ color: '#565656', fontWeight:500 }}>
                                    <p>3,000</p>
                                    <p>100</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    buttonStyle: {
        width: 300,
        height: 50,
        marginTop: 20,
    },
}));