import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { changeLanguage } from '@actions';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { GoGlobe } from 'react-icons/go';

export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();

    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    const [maintenance, setMaintenance] = useState({
        force: '',
        start: '',
        end: '',
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/maintenance`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                if (!response.maintenance_mode) {
                    navigate('/');
                }
                setMaintenance({
                    force: response.force_maintenance,
                    start: response.maintenance_start,
                    end: response.maintenance_end,
                });
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("MMM DD, YYYY"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="p-t-20 p-lr-20 p-b-10">
                <div className='flex-sb-m'>
                    <div className='p-lr-8 p-tb-4' style={{ backgroundColor: '#26292E', borderRadius: 2 }}>
                        <Typography color={theme.palette.gray.main} style={{ fontWeight: 400, fontSize: 12 }}>{t('dashboard.serverTime')}: {serverTime.time} {serverTime.date}</Typography>
                    </div>
                    <div className='flex-m p-l-10'>
                        <Tooltip title={t('general.language')} arrow><IconButton onClick={handleClick} style={{ marginRight: 5 }}><GoGlobe className="img-style" style={{ color: 'white' }} /></IconButton></Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                            <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='p-t-50 p-lr-20 flex-col-c'>
                    <img src='images/logos/logo.png' alt='logo' style={{ height: 60, width: 'auto' }} loading='lazy' />
                    <img src='images/maintenance/timer.png' alt='timer' className='m-t-30' style={{ height: 61, width: 'auto' }} loading='lazy' />
                </div>
                <div className='txt-center p-t-15 txt-content' >
                    <p className='p-t-3 p-lr-20 fs-12' style={{ fontWeight: 500, color: theme.palette.gray.main }}>{t('maintenance.content')}</p>
                </div>
                <div className='m-t-30' style={{ backgroundColor: '#16171C', borderRadius: 6 }}>
                    <div className='flex-col-c-m p-tb-15'>
                        <p className='fs-12' style={{ color: theme.palette.gray.main }}>{t('maintenance.estimateTime')}</p>
                        <p className='fs-12 p-t-5' style={{ color: '#E7E7E7' }}>{maintenance.end} {serverTime.date}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));