import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { currencyFormat } from '@utils/Tools';

const WalletItem = props => {
    const { walletName = '', walletCode='', walletValue='0', walletDecimal='0', walletType, background='#100F15', labelColor='white', urlLink } = props;
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    // Determine wallet icon path based on the wallet code
    const gecpalletIconPath = () => {
        switch (walletCode) {
            case 'cp':
                return '/images/wallet_icon/icon_wallet_cp.png';
            case 'ap':
                return '/images/wallet_icon/icon_wallet_ap.png';
            case 'rp':
                return '/images/wallet_icon/icon_wallet_rp.png';
            case 'lp':
                return '/images/wallet_icon/icon_wallet_lp.png';
            default:
                return null;
        }
    };

    return (
        <Box className={styles.wallet} style={{ backgroundColor: background }}>
            {/* wallet icon */}
            {/* {gecpalletIconPath() && (
                <div style={{ alignSelf: 'flex-end', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                        src={gecpalletIconPath()}
                        alt={`Wallet Icon - ${walletName}`}
                        style={{ maxWidth: '40px', maxHeight: '40px' }}
                    />
                </div>
            )} */}
            <div>
                <Typography style={{ color: theme.palette.gray.main, fontSize: '0.75rem', marginTop: '2px', fontWeight: 500 }}>{walletName}</Typography>

                {/* Amount and Font of Wallet Balance */}
                <div style={{ alignItems: 'flex-start', marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h6"
                        style={{
                            color: labelColor,
                            wordBreak: 'break-all',
                            fontWeight: 500,
                            fontSize: '1rem'
                        }}
                    >
                        <b>{(walletCode==='gc'||walletCode==='nc'||walletCode==='ngc')?'':'$'} {walletValue ? currencyFormat(parseFloat(walletValue), walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType}</b>
                    </Typography>

                    {/* <Typography style={{ color: labelColor, textTransform: 'capitalize' }}>
                        {t('wallet.balance')}
                    </Typography> */}
                </div>
            </div>
            <div>
                <ArrowForwardIcon fontSize="medium" style={{ color: theme.palette.gray.main }}/>
            </div>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    wallet: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        height: '100%', 
        padding: '10px 15px', 
        borderRadius: '8px', 
        overflow: 'hidden', 
        marginTop: '10px', 
        // border: '1px solid #E7E7E7', 
        cursor: "pointer",
        // boxShadow: '3px 3px 12px 0px rgba(255, 255, 255, 0.25) inset',
    }
}));

// DEFINE DEFAULT PROPS TYPE
WalletItem.propTypes = {
    walletName: PropTypes.string,
    walletLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
};

// DEFINE DEFAULT PROPS VALUE
// WalletItem.defaultProps = {
//     walletName: '',
//     walletLogo: "",
//     walletValue: '0',
//     walletDecimal: 0,
//     background: 'linear-gradient(109deg, #82512D 3.41%, #D1913C 98.09%)',
//     labelColor: '#121C28',
// };

export default WalletItem;