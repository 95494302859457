import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiEye, FiEyeOff, FiKey, FiLock } from "react-icons/fi";

import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const Password = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
    });

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password: state.currentPassword,
            password: state.password,
            password_confirmation: state.passwordConfirmation
        }
        putUrl(`/user/password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ currentPassword: "", password: "", passwordConfirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <TextField
                    variant="standard"
                    fullWidth
                    name="currentPassword"
                    placeholder={t('profile.currentPassword')}
                    type={showPassword.currentPassword ? 'text' : 'password'}
                    value={state.currentPassword || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : ''}
                    error={inputErrors && inputErrors.current_password ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiKey />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword('currentPassword')}
                                >
                                    {showPassword.currentPassword ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    className={styles.textFieldBox}
                />
                <TextField
                    variant="standard"
                    fullWidth
                    name="password"
                    placeholder={t('profile.newPassword')}
                    type={showPassword.password ? 'text' : 'password'}
                    value={state.password || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                    error={inputErrors && inputErrors.password ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiLock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword('password')}
                                >
                                    {showPassword.password ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    className={styles.textFieldBox}
                />
                <TextField
                    variant="standard"
                    fullWidth
                    name="passwordConfirmation"
                    placeholder={t('profile.retypeNewPassword')}
                    type={showPassword.passwordConfirmation ? 'text' : 'password'}
                    value={state.passwordConfirmation || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                    error={inputErrors && inputErrors.password_confirmation ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiLock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword('passwordConfirmation')}
                                >
                                    {showPassword.passwordConfirmation ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    className={styles.textFieldBox}
                />
                <div className='flex-c-m m-t-20'>
                    <Button variant="contained" onClick={updatePassword} className='big-button' fullWidth>{t('button.update')}</Button>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    titleBox: {
        // backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
    textFieldBox: {
        marginBottom: 20
    }
}));

export default Password