import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { storePaths } from '@utils/Tools';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Security = () => {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const { email, mobile } = useSelector(state => state.user);

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80" style={{ background: theme.palette.sectionBackgroundColor }}>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/password", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('profile.password')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/profile", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('user.email')}</p>
                    <div className='flex-m'>
                        <span className='p-r-10 fs-12' style={{ color: '#848D9B' }}>{email ? email.slice(0, 3) : ''}***@***</span><ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                    </div>
                </div>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/profile", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('user.mobile')}</p>
                    <div className='flex-m'>
                        <span className='p-r-10 fs-12' style={{ color: '#848d9b' }}>{mobile ? (mobile.slice(0, 2) + '***' + mobile.slice(-3)) : t('profile.noSetup')}</span><ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                    </div>
                </div>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/security-password", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('profile.securityPassword')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div>
            </div>
            <div className="flex p-lr-20 p-tb-10 m-tb-10" style={{ background: theme.palette.sectionBackgroundColor }}>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/2fa", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('profile.twoFactorAuth')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div>
                <div className='flex-sb-m pointer p-tb-10' onClick={() => navigate("/account/withdrawal-address", { state: { from: storePaths(location) } })}>
                    <p className='fs-14' style={{ color: '#E7E7E7' }}>{t('profile.withdrawalAddress')}</p>
                    <ArrowForwardIcon fontSize="small" style={{ color: theme.palette.gray.main }} />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({

}));

export default Security;