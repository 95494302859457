import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button, Drawer, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { MdClose } from "react-icons/md";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths } from '@utils/Tools';

import "@css/tree.css";

export default function Plans() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const [games, setGames] = useState([]);
    const [openPlan, setOpenPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});

    const GAME_IMAGE = {'neuralsport': '/images/games/neuralsport.png'};

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/games`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data } = response;

                if (status) {
                    setGames(data);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const handleOpenDrawer = (data) => {
        setOpenPlan(true);
        setSelectedPlan(data);
    }

    const loginGame = async () => {
        setOpenPlan(false);
        postUrl('/game-login', {game_code: selectedPlan?.code}).then(response => {
            if (response.status) {
                let url = new URL(response.data.url);
                if(selectedPlan?.code==='neuralsport'){
                    url.searchParams.set('token', atob(response.data.token));
                }else{
                    url.searchParams.set('token', response.data.token);
                }
                window.open(url.toString(), "_blank", "noreferrer");
            } else {
                alert(response.message);
            }
        }).catch((error) => {
            alert(error.message || "Please try again later or contact admin if problem persist.");
        });
    }

    return (
        <Box style={{ padding: '0 0 80px', paddingLeft: 15, paddingRight: 15, minHeight: '90vh', marginTop: 65, overflowY: 'hidden' }}>
            <Box className='p-lr-10 p-b-46' >
                <div className='flex-c m-t-10'>
                    <div className='p-r-20'>
                        <Typography style={{ fontSize: '0.875rem', fontWeight: 600, color: theme.palette.gold }}>{t('game.pageDesc1')}</Typography>
                        <Typography style={{ fontSize: '0.625rem', fontWeight: 400, color: theme.palette.gray.main, paddingRight: 30 }}>{t('game.pageDesc2')}</Typography>
                    </div>
                    <div>
                        <img src={'/images/logos/logo-no-text.png'} className='w-full' alt="plan_logo" loading='lazy' style={{ height: 82, width: 82, maxWidth: 'fit-content', borderRadius: 6}}  />
                    </div>
                </div>
                <Grid container className="m-t-30">
                    <Grid item xs={12} className='p-t-15'>
                        { 
                            _.map(games, game => (
                                <div key={game.code} className='m-tb-10 p-tb-10 p-lr-15 flex-sb-m' style={{  borderRadius: 6, background: '#28292E', border: 1, borderStyle: 'solid', borderColor: '#F7A6001A' }}>
                                    <Typography variant='h6' style={{ color: 'white', fontSize: '1rem' }}><b>{game.name}</b></Typography>
                                    <div >
                                        <Button variant="contained" style={{width:'100%', minWidth:'unset', fontSize: '0.75rem'}} onClick={() => handleOpenDrawer(game)}>
                                            {t('game.select')}
                                        </Button>
                                    </div>
                                </div>
                            ))
                        }
                    </Grid>
                </Grid>

                <Drawer
                    anchor={'bottom'}
                    open={openPlan}
                    onClose={() => setOpenPlan(false)}
                    disableScrollLock={true}
                    classes={{ paper: styles.paperAnchorBottom }}
                >
                    <div className='p-tb-30 p-lr-50'>
                        <div className='dis-flex flex-m flex-sb-m'>
                            <p className='w-full txt-center txt-content'><b>{t('game.selectedGame')}</b></p>
                            <IconButton onClick={() => setOpenPlan(false)}>
                                <MdClose className='fs-21' />
                            </IconButton>
                        </div>
                        <Grid container spacing={2} className='p-tb-20 dis-flex flex-c-m'>
                            <Grid item xs={4}>
                                <div style={{ maxWidth: 123, maxHeight: 123, aspectRatio: 1 / 1 }}>
                                    <img src={GAME_IMAGE[selectedPlan?.code] || '/images/logos/logo-no-text.png'} className='s-full' alt="plan_logo" loading='lazy' style={{ borderRadius: 20 }}/>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div style={{ paddingBottom: 10 }}>
                                    <Typography variant='h6'><b>{ selectedPlan?.name }</b></Typography>
                                    <Typography variant='body1' style={{ color: '#AEAEAE' }}>{ selectedPlan?.descr?.[i18n.language] }</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='flex-c-m'>
                            <Button variant="contained" fullWidth className='big-button' onClick={() => navigate(`/games-topup/${selectedPlan?.code}`, { state: { from: storePaths(location) } })}>
                                {t('game.topup')}
                            </Button>
                            <Button variant="contained" fullWidth className='big-button' style={{ marginLeft: 15 }} onClick={loginGame}>
                                {t('game.playNow')}
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    }
}));