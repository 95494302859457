import { Drawer, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import WalletItem from '@components/WalletItem';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { storePaths } from '@utils/Tools';
import { useTranslation } from 'react-i18next';

const WalletListing = (props) => {
  const { listing } = props;
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();

  const goToLink = (link) => {
    navigate(link, { state: { from: storePaths(location) } })
  };

  // State for managing drawer visibility and selected wallet data
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  
  const handleWalletClick = (data) => {
    // Open the drawer and set the selected wallet data
    setDrawerOpen(true);
    setSelectedWallet(data);
  };

  const handleCloseDrawer = () => {
    // Close the drawer
    setDrawerOpen(false);
  };

  const gradients = [
    'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
    'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
    'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
    'linear-gradient(109deg, #B77800 3.41%, #FFC85E 98.09%)',
  ];

  // Function to render drawer content
  const renderDrawerContent = (data) => {
    return(
      <div className={styles.drawerContent}>
        <Typography variant='h6' className='txt-center m-b-10 txt-title'>{t('general.pleaseSelect')}</Typography>
        
        <List>
          {
            data?.setting_transfer?.[data.id]?.[data.id] && data?.setting_transfer?.[data.id]?.[data.id]?.allow_others && (
              <React.Fragment>
                <ListItem
                  onClick={() => goToLink(`/transfer?w=${btoa(data?.id)}`)}
                  className={styles.listItem}
                >
                  <ListItemIcon>
                    <img src="/images/wallets/icon_transfer.png" alt="Transfer" className={styles.icon} loading='lazy' />
                  </ListItemIcon>
                  <Typography>{t('walletListing.transfer')}</Typography>
                </ListItem>
                <div className={styles.rectangle}></div>
              </React.Fragment>
            )
          }

          {
            data?.setting_withdraw 
            ?
            (
              <React.Fragment>
                <ListItem
                  onClick={() => goToLink(`/withdraw?w=${btoa(data?.id)}`)}
                  className={styles.listItem}
                >
                  <ListItemIcon>
                    <img src="/images/wallets/icon_withdrawal.png" alt="Withdrawal" className={styles.icon} loading='lazy' />
                  </ListItemIcon>
                  <Typography>{t('walletListing.withdrawal')}</Typography>
                </ListItem>
                  <div className={styles.rectangle}></div>
              </React.Fragment>
            )
            :
            null
          }
          {
            (data?.setting_deposit || data?.setting_token_deposit)
            ?
            (
              <React.Fragment>
                      
                  <ListItem
                    onClick={() => goToLink(`/deposit?w=${btoa(data?.id)}`)}
                    className={styles.listItem}
                  >
                    <ListItemIcon>
                      <img src="/images/wallets/icon_deposit.png" alt="Deposit" className={styles.icon} loading='lazy' />
                    </ListItemIcon>
                    <Typography>{t('walletListing.deposit')}</Typography>
                  </ListItem>
                  <div className={styles.rectangle}></div>
              </React.Fragment>
            )
            :
            null
          }
          <React.Fragment>
            <ListItem
              onClick={() => goToLink(`/transaction?w=${btoa(data?.id)}`)}
              className={styles.listItem}
            >
              <ListItemIcon>
                <img src="/images/wallets/icon_transaction.png" alt="Transaction" className={styles.icon} loading='lazy' />
              </ListItemIcon>
              <Typography>{t('walletListing.transaction')}</Typography>
            </ListItem>
            {/* No rectangle for the last item */}
          </React.Fragment>
        </List>
      </div>
    )
  };  
  
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center'}}>
      {_.size(listing) > 0 &&
        _.map(listing, (data, key) => {
          {/* let setBgIndex = key % 4; */}
          {/* let background = gradients[setBgIndex]; */}
          return (
              <div onClick={() => goToLink(`/transaction?w=${btoa(data?.id)}`)} key={key} style={{ width: '100%' }}>
              {/* <div onClick={() => handleWalletClick(data)} key={key} style={{ width: '100%' }}> */}
                <WalletItem
                  walletName={data?.name[i18n.language] ? data.name[i18n.language] : data?.name?.en}
                  walletValue={data?.wallet_balances?.[0]?.balance}
                  walletDecimal={data?.decimal}
                  // background={background}
                  walletCode={data?.code}
                  urlLink={`/transaction?w=${btoa(data?.id)}`}
                />
              </div>

          );
        })}

        {/* Drawer for actions */}
        <Drawer 
            anchor="bottom" 
            open={drawerOpen} 
            onClose={handleCloseDrawer}
            disableScrollLock={true}
            classes={{ paper: styles.paperAnchorBottom }}
        >
            {selectedWallet && renderDrawerContent(selectedWallet)}
        </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    padding: '20px 40px 0 40px',
  },
  rectangle: {
    background: '#31455B',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.57) inset',
    height: '4px',
    margin: '5px 0',
    flexShrink: 0
  },
  icon: {
    width: 33,
    height: 'auto', // Maintain aspect ratio
    marginRight: 8,
  },
  listItem: {
    cursor: 'pointer'
  },
  paperAnchorBottom: {
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    height: 'auto',
    maxHeight: '100%',
    maxWidth: 444,
    overflow: 'hidden',
    margin:'0 auto',
    borderRadius:'30px 30px 0px 0px'
  },
}));

export default WalletListing;