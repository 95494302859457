import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Grid, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PlanHistoryItem from '@components/PlanHistoryItem';

const PlanHistory = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t } = useTranslation();

    const styles = useStyles();
    const isMountedRef = useRef(null);

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [planHistories, setPlanHistories] = useState([]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`/active-plan-histories/${id}`, { per_page: 10, page: page }).then((response) => {
            setLoading(false);
            if (response && isMountedRef.current) {
                let { data, current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);

                if (page < last_page) {
                    window.addEventListener('scroll', handleScroll);
                }

                if (page > 1) {
                    setPlanHistories(_.concat(planHistories, data));
                } else {
                    setPlanHistories(data);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll); }
    }, [page]);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom) {
            setPage(page + 1);
        }
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                {
                    !loading ?
                        <Grid container>
                            {
                                planHistories.length > 0 ? (
                                    planHistories.map((history, key) => (
                                        <Grid item xs={12} key={history.id} style={{ padding: '10px 0', cursor: 'pointer' }}>
                                            <Box>
                                                <PlanHistoryItem
                                                    history={history}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </Box>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src="images/wallets/transaction_icon.png" alt="Transaction Icon" style={{ marginBottom: '10px', width: 100 }} loading='lazy' />
                                        <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20 }}>
                                            {t('table.noTransactionYet')}
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                planHistories.length > 0 && (
                                    <Grid item xs={12} className={styles.endFontStyle}>
                                        <Typography style={{ color: '#B5B5B5' }}>
                                            {currPage === total ? t('table.endOfTheList') : t('table.loadingList')}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        : null
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundImage: `url('images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 85,
        justifyContent: "center",
    },
    colorBrow: {
        color: theme.palette.primary.main
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    viewAll: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer'
    },
    sliderShow: {
        width: '1000px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        padding: '0 30px'
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
    drawerContent: {
        padding: '0 35px'
    },
}));

export default PlanHistory;