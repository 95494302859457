import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { storePaths, currencyFormat } from '@utils/Tools';

const BonusItem = (props) => {
    const { bonusName, bonusCode, bonusValue, bonusDecimal, bonusType, background='#100F15', labelColor='white', urlLink } = props;
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();

    const getBonusIconPath = () => {
        switch (bonusCode) {
            case 'sponsor-bonus':
                return '/images/bonus_icon/sponsor.png';
            case 'extra-sponsor-bonus':
                return '/images/bonus_icon/extra-sponsor.png';
            case 'roi-bonus':
                return '/images/bonus_icon/roi.png';
            case 'extra-roi-bonus':
                return '/images/bonus_icon/roi.png';
            case 'leadership-bonus':
                return '/images/bonus_icon/leadership.png';
            case 'matching-bonus':
                return '/images/bonus_icon/matching.png';
            case 'matching-bonus-1':
                return '/images/bonus_icon/matching.png';
            case 'matching-bonus-2':
                return '/images/bonus_icon/matching.png';
            case 'subsidy-bonus':
                return '/images/bonus_icon/subsidy.png';
            default:
                return null;
        }
    };

    const handleOnClick = () => {
        if (urlLink) {
            navigate(urlLink, { state: { from: storePaths(location) } });
        }
    };

    return (
        // <Box onClick={handleOnClick} className={styles.bonusItem}
        //     style={{
        //         cursor: urlLink ? 'pointer' : 'default',
        //         minHeight: 150,
        //         width:'90%',
        //         background: `${background}`,
        //     }}
        // >
        //     {getBonusIconPath() && (
        //         <div style={{ display: 'flex', justifyContent: 'center' }}>
        //             <img
        //                 src={getBonusIconPath()}
        //                 alt={`Bonus Icon - ${bonusName}`}
        //                 style={{ maxWidth: '100%', maxHeight: '100%', height:130 }}
        //             />
        //         </div>
        //     )}

        //     <div className={styles.bonusContent}>
        //         <Typography variant="h6" className={styles.amount}>
        //             <b>$ {bonusValue ? parseFloat(bonusValue).toFixed(bonusDecimal) : (0).toFixed(bonusDecimal)}</b>
        //         </Typography>
        //         <Typography className={styles.bonusType}>{bonusType}</Typography>
        //     </div>
        // </Box>
        <Box onClick={handleOnClick} className={styles.bonusItem} style={{ backgroundColor: background }}>
            <div>
                <Typography style={{ color: theme.palette.gray.main, fontSize: '0.75rem', marginTop: '2px', fontWeight: 500 }}>{bonusType}</Typography>

                <div style={{ alignItems: 'flex-start', marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h6"
                        style={{
                            color: labelColor,
                            wordBreak: 'break-all',
                            fontWeight: 500,
                            fontSize: '1rem'
                        }}
                    >
                        <b>$ {bonusValue ? currencyFormat(parseFloat(bonusValue), bonusDecimal) : (parseFloat(0)).toFixed(bonusDecimal)}</b>
                    </Typography>
                </div>
            </div>
            <div>
                <ArrowForwardIcon fontSize="medium" style={{ color: theme.palette.gray.main }}/>
            </div>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    bonusItem: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        height: '100%', 
        width: '100%', 
        padding: '10px 15px', 
        borderRadius: '8px', 
        overflow: 'hidden', 
        marginTop: '10px', 
        cursor: "pointer",
    },
    bonusContent: {
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // background: `url('images/wallets/background_image.png') center center / cover`,
    },
    amount: {
        color: '#121C28',
        textAlign: 'left',
        fontSize: 16,
        wordBreak: 'break-all',
    },
    bonusType: {
        color: '#121C28',
        textAlign: 'left',
        textTransform: 'capitalize',
        fontSize: 12,
        textTransform: 'uppercase',
    },
}));

BonusItem.propTypes = {
    bonusName: PropTypes.string,
    bonusCode: PropTypes.string,
    bonusValue: PropTypes.string,
    bonusDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
};

export default BonusItem;