import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, InputAdornment, TextField, Drawer } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { storeProfile } from '@actions';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { FiEye, FiEyeOff, FiKey, FiLock } from "react-icons/fi";
import { MdOutlineSecurity } from "react-icons/md";

const CryptoAddress = () => {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { wallets } = useSelector(state => state.user);

    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const [state, setState] = useState({
        textfield: false,
        cryptoAddress: wallets?.general?.address || "",
        // walletType: "",
        securityPassword: "",
        authenticationCode: "",
    });
    const [open, setOpen] = useState(false);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const handleOpenDrawer = () => {
        if (state.cryptoAddress !== '') {
            setOpen(true);
        } else {
            addAlert('', t('error.emptyCryptoSecurity'), 'error', '');
        }
    }

    const updateCryptoAddress = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            crypto_address: state.cryptoAddress,
            // wallet_type: state.walletType,
            security_password: state.securityPassword,
            authentication_code: state.authenticationCode,
        }
        putUrl(`/user/crypto-address`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setOpen(false);
                dispatch(storeProfile(response.data));
                setState({ cryptoAddress: "", walletType: "", securityPassword: "", authenticationCode: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                let message = '';
                if (response.errors) {
                    if (response.errors.crypto_address) {
                        setOpen(false);
                        message = t('error.editError');
                    } else {
                        message = response.errors.authentication_code;
                    }
                } else {
                    message = response.message;
                }
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            setOpen(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <p className='fs-12' style={{ color: theme.palette.gray.main }}>{t('profile.walletAddress')}</p>
                <div className='flex-sb-c w-full m-t-10' style={{ background: '#16171C', borderRadius: 8 }}>
                    <div className='flex-sb-m p-all-10'>
                        <div className='flex-l-m' style={{ padding: '3px 5px' }}>
                            <img src="/images/profile/icon-usdt.png" alt="usdt" style={{ objectFit: 'cover', height: '18px', width: 'auto' }} />
                            <p className='fs-14 p-lr-10' style={{ color: '#E7E7E7' }}>USDT</p>
                            <div style={{ borderRadius: 4, padding: '4px 12px', background: '#28292E' }}>
                                <p className='fs-10 txt-upper' style={{ color: '#E7E7E7' }}>{wallets?.general?.network || ''}</p>
                            </div>
                        </div>
                        <p className='fs-12 m-r-10 pointer' style={{ color: theme.palette.gray.main }} onClick={() => setState({ ...state, textfield: !state.textfield, cryptoAddress: wallets?.general?.address || state.cryptoAddress })}>{t('button.edit')}</p>
                    </div>
                    <div style={{ borderBottom: '2px solid #26292E' }}></div>
                    {
                        state.textfield ?
                            <TextField
                                // variant="standard"
                                fullWidth
                                name="cryptoAddress"
                                placeholder={t('profile.newCryptoAddress')}
                                value={state.cryptoAddress || ''}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                                error={inputErrors && inputErrors.crypto_address ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    // disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiKey className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            // className={styles.textFieldBox}
                            />
                            :
                            <p className='fs-12 txt-truncate' style={{ color: theme.palette.gray.main, padding: '14px 14px 20px 14px' }}>{wallets?.general?.address || ''}</p>
                    }
                </div>
                {
                    state.textfield ?
                        <div className='flex-c-m m-t-20'>
                            <Button variant="contained" onClick={handleOpenDrawer} className='big-button' fullWidth>{t('button.update')}</Button>
                        </div>
                        : null
                }
            </div>

            <Drawer
                anchor={'bottom'}
                open={open}
                onClose={() => setOpen(false)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <div className='p-all-30'>
                    {/* <div className='dis-flex flex-m flex-sb-m p-b-20'>
                        <Typography className='w-full' style={{ color: 'rgba(82, 82, 82, 0.80)' }}><b>{t('profile.enterTwoFactorAuth')}</b></Typography>
                    </div> */}
                    <div className='p-tb-30'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name="securityPassword"
                            placeholder={t('profile.currentSecurityPassword')}
                            type={showPassword.securityPassword ? 'text' : 'password'}
                            value={state.securityPassword || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FiLock className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                        >
                                            {showPassword.securityPassword ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={styles.textFieldBox}
                        />
                        <TextField
                            helperText={inputErrors && inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                            error={inputErrors && inputErrors.authentication_code ? true : false}
                            placeholder={t('user.authenticationCode')}
                            variant="standard"
                            type="number"
                            fullWidth
                            value={state?.authenticationCode || ''}
                            name="authenticationCode"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdOutlineSecurity className="img-style fs-18" style={{ color: '#E7E7E7' }} />
                                    </InputAdornment>
                                ),
                            }} />
                    </div>

                    <Button variant='contained' className='big-button' style={{ marginTop: 20 }} fullWidth onClick={updateCryptoAddress}>
                        {t('button.update')}
                    </Button>
                </div>
            </Drawer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    titleBox: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px',
        background: theme.palette.sectionBackgroundColor
    },
}));

export default CryptoAddress