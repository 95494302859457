import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, TextField, Typography, IconButton, InputAdornment, InputLabel, Container } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { currencyFormat } from '@utils/Tools';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useSearchParams, useNavigate } from 'react-router-dom';

import WalletItem from '@components/WalletItem';

const Transfer = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    const { addAlert, setLoading } = useNotificationLoading();

    const [completed, setCompleted] = useState(false);
    const INITIAL_STATE = {
        from_wallet_id: '',
        to_wallet_id: '',
        wallet_id: '',
        username: '',
        amount: '',
        remark: '',
        security_password: '',
        authentication_code: '',
        showPassword: false,
    };
    const [state, setState] = useState(INITIAL_STATE);
    const [toWallet, setToWallet] = useState({});
    const [wallet, setWallet] = useState({});
    const [walletBalance, setWalletBalance] = useState('');
    const [walletLocked, setWalletLocked] = useState('');
    const [searchParams] = useSearchParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    const [transaction, setTransaction] = useState([]);


    useEffect(() => {
        setLoading(true);
        callApi()
    }, [searchParams]);
    
    const callApi = () => {
        if (typeof searchParams.get("w") !== 'string') {
            setLoading(false);
            navigate(`/`);
            return;
        }
        
        try {
            let wallet_id = parseInt(atob(searchParams.get("w")));
        
            if (isNaN(wallet_id)) {
                setLoading(false);
                navigate(`/`);
                return;
            }
        
            setState({
                ...state,
                wallet_id: wallet_id,
            });
        
            let apiData = {
                wallet_id: wallet_id,
            };
        
            getUrl(`/member-transfer-balance`, apiData)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setWalletBalance(response.data.wallet_balance);
                    setWalletLocked(response.data.wallet_locked);
                    setWallet(response.data.wallet);
                    // setState({ ...INITIAL_STATE, from_wallet_id:wallet_id, to_wallet_id: Object.keys(response.data?.transfer_setting?.[wallet_id]).find(to_id=>{return response.data?.transfer_setting[wallet_id][to_id].allow_others === true;})});
                    let to_id = Object.keys(response.data?.transfer_setting?.[wallet_id]).find(to_id=>{return response.data?.transfer_setting[wallet_id][to_id].allow_others === true;})
                    setState({ ...INITIAL_STATE, from_wallet_id:wallet_id, to_wallet_id: to_id});
                    let to_wallet = Object.values(response.data?.all_wallet);
                    setToWallet(to_wallet.find(wallet => wallet.id === parseInt(to_id)));

                } else {
                    navigate(`/`);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
                navigate(`/`);
            });
        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    };      

    const closeDrawer = () => {
        setDrawerOpen(false);
        // Reset the state to clear the data
        setState({
            ...INITIAL_STATE
        });
        // Clear input errors
        setInputErrors(null);
    };   

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const insertSecurityPwd = () => {
        if (state.username && state.amount && state.remark && state.security_password) {
            setDrawerOpen(true);
        } else {
            addAlert('', t('transfer.insertAmountRemark'), 'error', '');

            // Close the drawer
            setDrawerOpen(false);
        }
    };
    
    const confirmTransfer = () => {
        if (state.username && state.amount && state.remark && state.security_password) {
            
        } else {
            addAlert('', t('transfer.insertAmountRemark'), 'error', '');
            return;
        }

        setLoading(true);
        setInputErrors();
        let params = {
            username: state?.username,
            from_wallet_id: state?.from_wallet_id,
            to_wallet_id: state?.to_wallet_id,
            amount: state?.amount,
            remark: state?.remark,
            security_password: state?.security_password,
            authentication_code: state?.authentication_code,
        }
        
        postUrl(`/wallets/transfer`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setCompleted(true);
                setTransaction(response.data);
                callApi();
                closeDrawer();
                addAlert('', t('success.transferSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
    
                // Check for specific errors and close the drawer accordingly
                if (
                    (!state.username || !state.amount || !state.remark || !state.security_password) ||
                    (response.errors && (response.errors.username || response.errors.amount || response.errors.remark || response.errors.security_password))
                ) {
                    setDrawerOpen(false);
                }                   
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    };    

    return (
        <Box className="p-lr-20" style={{marginTop: 60, paddingBottom: 150 }}>
            <Box>
                <Typography variant='h6' style={{ textAlign: 'center' }}>
                    <b>
                        {t('title.transfer')} {wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.en}
                    </b>
                </Typography>
                <Box style={{ margin: "20px 0px" }}>
                    <InputLabel>{t('transfer.transferTo')}</InputLabel>
                    <TextField
                        placeholder={t('transfer.transferToPlaceholder')}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 0px 15px 15px", height: 46 } }}
                        variant='standard'
                        fullWidth
                        autoComplete='username'
                        name='username'
                        value={state?.username}
                        onChange={({ target }) => setState({ ...state, username: target.value })}
                    />
                    {
                        inputErrors && inputErrors.username
                            ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.username}</Typography>
                            : inputErrors?.allow_self
                                ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_self}</Typography>
                                : inputErrors?.allow_others
                                    ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_others}</Typography>
                                    : null
                    }
                </Box>
                <Box style={{ margin: "20px 0px" }}>
                    <div className="flex-sb-m">
                        <InputLabel>{t('transfer.amount')}</InputLabel>
                        <span style={{ color: 'white', fontSize: '0.75rem' }}>{`${currencyFormat(walletBalance)} ${wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']}`}</span>
                    </div>
                    <TextField
                        placeholder={t('transfer.amountPlaceholder')}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        variant='standard'
                        fullWidth
                        autoComplete='amount'
                        name='amount'
                        value={state?.amount}
                        onChange={({ target }) => setState({ ...state, amount: target.value })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography onClick={() => setState({ ...state, amount: Math.floor((parseFloat(walletBalance)-parseFloat(walletLocked))||0) })} style={{ color: theme.palette.gold, fontSize: '0.875rem', cursor: 'pointer' }}>{t('transfer.max')}</Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        inputErrors && inputErrors.amount
                            ?
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                            :
                            null
                    }
                    { walletLocked > 0 &&
                        <Typography style={{ color: theme.palette.gold, fontSize: '0.875rem', cursor: 'pointer' }}>
                            ${currencyFormat(parseFloat(walletLocked)||0)}
                        </Typography>
                    }
                </Box>
                <Box style={{ margin: "20px 0px" }}>
                    <InputLabel>{t('transfer.remarkNote')}</InputLabel>
                    <TextField
                        placeholder={t('transfer.remarkNotePlaceholder')}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 0px 15px 15px", height: 46 } }}
                        variant='standard'
                        fullWidth
                        autoComplete='remark'
                        name='remark'
                        value={state?.remark}
                        onChange={({ target }) => setState({ ...state, remark: target.value })}
                    />
                    {
                        inputErrors && inputErrors.remark
                            ?
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                            :
                            null
                    }
                </Box>
                <Box style={{ margin: "20px 0px" }}>
                    <InputLabel>{t('transfer.securityPassword')}</InputLabel>
                    <TextField
                        placeholder={t('transfer.securityPasswordPlaceholder')}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        variant='standard'
                        fullWidth
                        type={state.showPassword ? 'text' : 'password'}
                        autoComplete='securityPassword'
                        name='securityPassword'
                        value={state?.security_password}
                        onChange={({ target }) => setState({ ...state, security_password: target.value })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        inputErrors && inputErrors.security_password
                            ?
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                            :
                            null
                    }
                </Box>
                <Box style={{ margin: "20px 0px" }}>
                    <InputLabel>{t('transfer.twoFactorAuth')}</InputLabel>
                    <TextField
                        error={inputErrors && inputErrors.authentication_code ? true : false}
                        helperText={inputErrors?.authentication_code || ''}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        placeholder={t('transfer.twoFactorAuthPlaceholder')}
                        variant="standard"
                        fullWidth
                        value={state?.authentication_code}
                        onChange={({ target }) => {
                            const numericInput = target.value.replace(/\D/g, '');
                            setState({ ...state, authentication_code: numericInput });
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            inputMode: 'numeric', // Set input mode to numeric
                            pattern: '[0-9]*',     // Set a pattern to enforce numeric input
                        }}
                    />
                </Box>

                <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#16171C' }}>
                    <div className="flex-sb-m">
                        <Typography style={{ fontWeight: 500, fontSize: '0.75rem', color: theme.palette.gray.main }}>{t('transfer.transferFee')}</Typography>
                        <Typography style={{ backgroundColor: '#F7A6001A', padding: 4, borderRadius: 2, fontSize: '0.625rem', color: theme.palette.gold}}>{t('transfer.zeroFees')}</Typography>
                    </div>
                    <div className="flex-sb-m m-t-5">
                        <Typography style={{ fontWeight: 500, fontSize: '0.75rem', color: theme.palette.gray.main }}>{t('transfer.amountReceived')}</Typography>
                        <Typography style={{ fontSize: '0.75rem', color: 'white'}}>{`${currencyFormat(state.amount || 0)} ${toWallet?.name?.[i18n.resolvedLanguage] ? toWallet?.name?.[i18n.resolvedLanguage] : toWallet?.name?.en}`}</Typography>
                    </div>
                    <Button fullWidth variant="contained" style={{ height: '46px', marginTop: 10 }} onClick={confirmTransfer}>{t('transfer.confirmTransfer')}</Button>
                </Container>
            </Box>

            {/* <Drawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <Box className="m-lr-30 m-t-30">
                    <div className='dis-flex flex-m flex-sb-m p-b-20'>
                        <p className='w-full txt-content' ><b>{t('transfer.enterTwoFApassword')}</b></p>
                    </div>

                    <div className='p-b-50'>
                        <TextField
                            error={inputErrors && inputErrors.authentication_code ? true : false}
                            helperText={inputErrors?.authentication_code || ''}
                            placeholder={t('transfer.twoFactorAuth')}
                            variant="standard"
                            fullWidth
                            value={state?.authentication_code}
                            onChange={({ target }) => {
                                const numericInput = target.value.replace(/\D/g, '');
                                setState({ ...state, authentication_code: numericInput });
                            }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                inputMode: 'numeric', // Set input mode to numeric
                                pattern: '[0-9]*',     // Set a pattern to enforce numeric input
                            }}
                        />
                    </div>
                    
                    <div className='flex-c-m'>
                        <Button variant="contained" fullWidth className='big-button' style={{ marginBottom: 30 }} onClick={confirmTransfer}>
                            {t('transfer.confirmTransfer')}
                        </Button>
                    </div>
                </Box>
            </Drawer> */}
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    colorBrown: {
        color: '#82512D'
    },
    input: {
        borderRadius: '10px',
        background: '#EEE',
        boxShadow: '-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset',
    },
    button: {
        borderRadius: '30px 0px',
        background: '#82512D',
        boxShadow: '3px 3px 6px 0px rgba(255, 255, 255, 0.43) inset, 2px 2px 4px 0px rgba(181, 126, 87, 0.65)',
        '&:hover': {
            background: '#946439',
        },
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    },
}));

export default Transfer;