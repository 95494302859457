import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import moment from 'moment-timezone';

const BonusHistoryItem = props => {
    const { history } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const decimalPlaces = 2;
    const formattedAmount = (parseFloat(history?.amount)).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });    

    const sign = '';
    const color = '#2fbe81';

    const dateObject = new Date(history?.created_at);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    const formattedDate = moment(history?.created_at.replace(/-/g, "/")).format("YYYY-MM-DD");

    return (
        <Box>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "left", color:'#cfcccc' }}>
                    {history?.bonus?.name ? history?.bonus?.name?.[i18n.language] : history?.bonus?.name?.process.env.REACT_APP_DEFAULT_LANGUAGE}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* Amount */}
                    <Typography variant='subtitle2' style={{ fontWeight: "500", textAlign: "right", color: color, wordBreak: 'break-all' }}>
                        {history?.factor === -1 && sign}$ {formattedAmount}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* Remark */}
                    {
                        history.downline_username &&
                        <Typography noWrap style={{ color: "#cfcccc", fontSize: 12 }}>{t('bonus.downlineUsername')}: {history.downline_username}</Typography>
                    }
                    {/* Date */}
                    <Typography variant='caption' style={{ color: "#cfcccc" }}>
                        {`${formattedDate}  \u00a0`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* Rectangle Bottom */}
                    <div className='divider-design'></div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BonusHistoryItem;