import React, { memo, useState, useEffect } from 'react';
import _ from 'lodash';
import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useLocation, useNavigate, matchRoutes, matchPath } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Tooltip } from '@mui/material';
import { BiChevronLeft } from "react-icons/bi";
import { GoGlobe } from "react-icons/go";
import { FiLogIn, FiLogOut } from "react-icons/fi"
import { changeLanguage } from '@actions';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import { reducePaths } from '@utils/Tools';
import Draggable from '@components/Draggable';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

// const HIDE_BAR = ['/reset-password', '/landing', '/', '/dashboard', '/register', '/plans'];
const WITH_TRANSPARENT = ['/payment/:id'];
const GRAY_BACKGROUND = ['/account/withdrawal-address', '/account/language'];

const TitleBar = memo((props) => {
    const { showTitle=true } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeColor, setChangeColor] = useState(false);
    const [chatReady, setChatReady] = useState(false);
    
    const { accessToken, language } = useSelector(state => state.general);

    const [routeDetail, setRouteDetail] = useState({});
    // const { email } = useSelector(state => state.user);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let path = location?.pathname.split('/');

    useEffect(() => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let currentRoute = _.find(public_restricted_routes, function (_route) { return matchPath({ path: _route.path }, current_path) });
        if (_.size(currentRoute) === 0) {
            currentRoute = _.find(public_routes, function (_route) { return matchPath({ path: _route.path }, current_path) });
        }
        if (_.size(currentRoute) === 0) {
            currentRoute = _.find(private_routes, function (_route) { return matchPath({ path: _route.path }, current_path) });
        }

        if (_.size(currentRoute) > 0) {
            setRouteDetail(currentRoute);
        }
        // eslint-disable-next-line
    }, [current_path]);

    useEffect(() => {
        if (accessToken) {
            // if(email === null){
            //     navigate('/account/profile');
            // }
        }
        // eslint-disable-next-line
    }, [accessToken]);

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    const logout = () => {
        removeLoginAccess();
        navigate('/landing');
    };

    const login = () => {
        navigate('/login');
    };

    const changeBackgroundColor = () => {
        if (window?.scrollY > 0) {
            setChangeColor(true);
        } else {
            setChangeColor(false);
        }
    }
    window.addEventListener('scroll', changeBackgroundColor);

    let checkPath = matchRoutes(private_routes, current_path)?.[0]?.['route'];

    // CHATBOT
    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        window.$crisp=[];window.CRISP_WEBSITE_ID="79b05f06-92ca-46c7-866f-4ab08684e58f";
        script.setAttribute("src", "https://client.crisp.chat/l.js");
        head.appendChild(script);
        // var css = '#crisp-chatbox span.cc-imbb.cc-qfnu {  background-color: #6f93be3b !important; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)!important; box-shadow: 3px 3px 3px 0 #00000042 !important }';
        var css = '#crisp-chatbox a.cc-1m2mf { display: none !important }';
        
        var style = document.createElement('style');
        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        window.CRISP_READY_TRIGGER = function() {
            setChatReady(true);
        };

        return () => {
            head.removeChild(script);
        };
    }, []);

    const openChat = () => {
        window.$crisp.push(["do", "chat:toggle"])
    };

    return (
        <>
            {
                (_.size(routeDetail) === 0 || (_.size(routeDetail) > 0 && !routeDetail?.hideBar))
                    ?
                    <AppBar position='fixed'
                        sx={{
                            maxWidth: 'inherit',
                            width: '100%',
                            right: 'auto',
                            backgroundColor: _.includes(GRAY_BACKGROUND, checkPath?.['path']) ? '#16171C' : (changeColor ? '#121c286e' : 'transparent'),
                            backdropFilter: changeColor ? 'blur(4px)' : 'blur(2px)',
                            transition: 'all 0.8s linear',
                        }}
                    >
                        <Toolbar style={{ paddingLeft: 10 }}>
                            {!routeDetail?.hideBack && <IconButton onClick={goBack}><BiChevronLeft className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? theme.palette.gray.main : theme.palette.gray.main }} /></IconButton>}
                            {
                                showTitle &&
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 600, position: 'absolute', width: '100%', paddingLeft: '32px', paddingRight: '42px', pointerEvents: 'none' }} color="white">
                                    {(routeDetail.title && i18n.exists(routeDetail?.title)) ? t(routeDetail?.title) : ""}
                                </Typography>
                            }
                            {
                                (current_path.includes('/login') || current_path.includes('/register') || current_path.includes('/sso-login')) ?
                                    <div style={{ flexGrow: 1, textAlign: 'right' }}>
                                        {/* {
                                            current_path.includes('/login') ?
                                                <p className='fs-12 pointer' style={{ color: theme.palette.gold }} onClick={() => navigate('/register')}>{t('login.signUp')}</p>
                                                :
                                                <p className='fs-12 pointer' style={{ color: theme.palette.gold }} onClick={() => navigate('/login')}>{t('login.loginNow')}</p>
                                        } */}
                                        <Tooltip title={t('general.language')} arrow><IconButton onClick={handleClick} ><GoGlobe className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : 'white' }} /></IconButton></Tooltip>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            slotProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '&:before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                                            <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                                        </Menu>
                                    </div>
                                    : null
                            }
                            {/* {
                                accessToken ?
                                    <Tooltip title={t('button.logout')} arrow><IconButton onClick={logout} ><FiLogOut className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : theme.palette.primary.main }} /></IconButton></Tooltip>
                                    :
                                    <Tooltip title={t('button.login')} arrow><IconButton onClick={login} ><FiLogIn className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : theme.palette.primary.main }} /></IconButton></Tooltip>

                            } */}
                            
                        </Toolbar>
                    </AppBar>
                    :
                    null
            }
            {
               chatReady === true &&
                <Draggable offsetX="10" offsetY="55" style={{ backgroundColor: '#E1AE01', width: 54, height: 54, textAlign: 'center', borderRadius: '50%' }}>
                    <IconButton onClick={openChat} style={{ padding: 0 }}><ChatBubbleIcon className="img-style" style={{ color: 'white' }} /></IconButton>
                </Draggable>
            }
        </>
    )
});

// TitleBar.defaultProps = {
//     showTitle: true,
// };

export default TitleBar;