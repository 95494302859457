import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button, OutlinedInput, MenuItem, InputAdornment, TextField, IconButton, Dialog, InputLabel, Container } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiEye, FiEyeOff } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat, storePaths } from '@utils/Tools';

import "@css/tree.css";
import Receipt from '@layouts/Receipt';

export default function Payment() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const [inputErrors, setInputErrors] = useState();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [rate, setRate] = useState({});
    const [result, setResult] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [wallets, setWallets] = useState({});
    const [paymentMethods, setPaymentMethods] = useState({});
    const [state, setState] = useState({
        username: '',
        paymentMethod: 0,
        securityPassword: '',
        showSecurityPassword: false,
    });

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/active-plan/${id}`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data, rate } = response;

                if (status) {
                    setSelectedPlan(data);
                    setRate(rate);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });

        getUrl(`/payment-methods`).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setWallets(data.wallets);
                    setPaymentMethods(data.payment_methods);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        let {name, value} = event.target;
        setState({ ...state, [name]: value });
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        navigate('/plan-histories', { state: { from: storePaths(location) } });
    };

    const purchasePlan = () => {
        setLoading(true);
        setInputErrors();
        let apiData = {
            username: state.username,
            package_id: id,
            combination_id: state.paymentMethod,
            security_password: state.securityPassword,
        };
        postUrl(`/purchase-plan`, apiData).then(response => {
            setLoading(false);
            let { status, message, errors, data } = response;
            if (status) {
                setResult(data);
                setDialogOpen(true);
                addAlert('', message || t('success.purchaseSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.purchaseError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    // let serviceFee = 0;
    let totalAmount = 0;

    /* if (_.size(selectedPlan?.service_fee) > 0) {
        if (selectedPlan?.service_fee?.['type'] === 'Percent') {
            serviceFee = selectedPlan?.price * (parseFloat(selectedPlan?.service_fee?.['amount']) / 100);
        } else {
            serviceFee = parseFloat(selectedPlan?.service_fee?.['amount']);
        }
    }
    totalAmount = parseFloat(selectedPlan?.price) + serviceFee; */
    totalAmount = parseFloat(selectedPlan?.bv * rate?.sell);

    return (
        <>
            {/* <div className='w-full' style={{ borderRadius: '0px 0px 140px 0px', height: 221, background: theme.palette.primary.main}}>
                <Typography variant='h6' className='p-t-77 p-lr-16 p-b-12 txt-center' style={{ color: '#fff' }}><b>{t('plan.subscribePlan')}</b></Typography>
            </div> */}
            <Box className='p-lr-20 p-t-80' style={{ minHeight: '100vh', position: "relative" }}>
                <Box className='p-t-16' style={{  paddingBottom: 150+(_.size(paymentMethods[state?.paymentMethod])*50) }}>
                    {/* <div className='pos-relative dis-flex flex-m' style={{ width: '100%', flexDirection: 'column' }}>
                        <Typography variant='h6' className='p-lr-16 p-b-12 txt-center' style={{ color: '#fff' }}><b>{t('plan.subscribePlan')}</b></Typography>
                        <div className='m-b-10' style={{ width: 171, maxHeight: 171, aspectRatio: 1 / 1 }}>
                            <img src={selectedPlan?.plan_logo ? selectedPlan?.plan_logo?.file_name : '/images/logos/logo-no-text.png'} className='s-full' alt="Plan Logo" loading='lazy' />
                        </div>

                        <div className='txt-center'>
                            <Typography variant='h5' style={{ color: theme.palette.text.content }}><b>{selectedPlan?.name ? selectedPlan?.name?.[i18n.language] : selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></Typography>
                            <Typography variant='body1' style={{ color: theme.palette.text.content }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.title ? selectedPlan?.description?.[i18n.language]?.title : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title) : ""}</Typography>
                            <Typography variant='body1' style={{ color: theme.palette.text.content }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.subtitle ? selectedPlan?.description?.[i18n.language]?.subtitle : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle) : ""}</Typography>
                        </div>
                    </div> */}

                    <Grid className='pos-relative' container>
                        <Grid item xs={12}>
                            <InputLabel>{t('plan.packageSelected')}</InputLabel>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px', color: theme.palette.gold }}}
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={selectedPlan?.name ? `${t('plan.packageDashName', {plan: (selectedPlan?.name?.[i18n.language] || selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE])} )}` : ""}
                                className={styles.textFieldStyle}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <InputLabel>{t('plan.recipientUsername')}</InputLabel>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('plan.usernamePlaceholder')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                autoComplete="username"
                                name='username'
                                value={state?.username}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <InputLabel>{t('plan.paymentMethod')}</InputLabel>
                            <TextField
                                style={{ paddingTop: 10, paddingBottom: 10 }}
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px', padding: "15px 0px 15px 15px" } }}
                                variant="standard"
                                select
                                placeholder={t('plan.paymentMethod')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                autoComplete="paymentMethod"
                                name='paymentMethod'
                                value={state?.paymentMethod}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.combination_id ? inputErrors.combination_id : ''}
                                error={inputErrors && inputErrors.combination_id ? true : false}
                            >
                                <MenuItem value={0} disabled>{t('general.pleaseSelect')}</MenuItem>
                                {
                                    _.map(paymentMethods, (paymentRules, combinationId) => {
                                        let combinationStr = '';
                                        _.map(paymentRules, (percentage, walletId) => {
                                            let method = _.find(wallets, { 'id': parseInt(walletId) })
                                            combinationStr = combinationStr ? (combinationStr + ' + ') : '';
                                            combinationStr += (method?.name ? method?.name?.[i18n.language] : method?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]) + ` [${percentage}%]`;
                                        })
                                        return (
                                            <MenuItem key={combinationId} value={combinationId}>
                                                {combinationStr}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className="p-t-10">
                            <InputLabel>{t('user.securityPassword')}</InputLabel>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('plan.securityPasswordPlaceholder')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle securityPassword visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state?.showSecurityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                name='securityPassword'
                                type={state.showSecurityPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.securityPassword}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <div className='p-tb-10 dis-flex flex-m flex-sb-m'>
                                <Typography style={{ fontSize: 17, color: theme.palette.text.content, fontWeight:500 }}>{t('plan.planPrice')}</Typography>
                                <Typography style={{ fontSize: 17, color: theme.palette.text.content, fontWeight:500 }}><b>$ {currencyFormat((selectedPlan?.bv * rate?.sell).toFixed(2))}</b></Typography>
                            </div> */}
                            {/* <div className='dis-flex flex-m flex-sb-m'>
                                <Typography style={{ fontSize: 17, color: theme.palette.text.content, fontWeight:500 }}>{t('plan.serviceFee')}{selectedPlan?.service_fee?.['type'] === 'Percent' && `(${selectedPlan?.service_fee?.['amount']}%)`}</Typography>
                                <Typography style={{ fontSize: 17, color: theme.palette.text.content, fontWeight:500 }}>
                                    <b>$ { _.size(selectedPlan?.service_fee) > 0 ? (currencyFormat((serviceFee).toFixed(2))) : ((0).toFixed(2)) }</b>
                                </Typography>
                            </div> */}
                            {/* <div className='p-tb-10 dis-flex flex-m flex-sb-m'>
                                <Typography style={{fontSize:17, color: theme.palette.text.content, fontWeight:500}}>{t('plan.totalAmount')}</Typography>
                                <Typography style={{fontSize:17, color: theme.palette.text.content, fontWeight:500}}><b>$ {currencyFormat((totalAmount).toFixed(2))}</b></Typography>
                            </div> */}
                        </Grid>
                    </Grid>

                    {/* <div className='w-full flex-c-m' style={{ bottom: 10 }}>
                        <Button fullWidth variant='contained' className='big-button' onClick={purchasePlan}>
                            {t('button.purchaseNow')}
                        </Button>
                    </div> */}
                </Box>

                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    disableScrollLock={true}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <Box style={{ marginTop: 20, maxWidth: 350 }}>
                        <Receipt type={'plan-history'} data={result} handleClose={handleDialogClose} />
                    </Box>
                </Dialog>
                <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#16171C' }}>
                    {
                        state?.paymentMethod > 0 && _.map(paymentMethods[state.paymentMethod], (percentage, walletId) => {
                            let method = _.find(wallets, { 'id': parseInt(walletId) })
                            return(
                                <div key={walletId} className='p-b-10 dis-flex flex-m flex-sb-m'>
                                    <Typography style={{fontSize: "0.75rem", color: theme.palette.gray.main, fontWeight:500}}>{(method?.name ? method?.name?.[i18n.language] : method?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE])}</Typography>
                                    <Typography style={{fontSize: "0.75rem", color: "white", fontWeight:500}}><b>{currencyFormat((totalAmount*percentage/100).toFixed(2))}</b></Typography>
                                </div>
                            )
                        })
                    }
                    <div className='p-b-10 dis-flex flex-m flex-sb-m'>
                        <Typography style={{fontSize: "0.75rem", color: theme.palette.gray.main, fontWeight:500}}>{t('plan.totalAmount')}</Typography>
                        <Typography style={{fontSize: "0.75rem", color: "white", fontWeight:500}}><b>$ {currencyFormat((totalAmount).toFixed(2))}</b></Typography>
                    </div>
                    <div className='w-full flex-c-m' style={{ bottom: 10 }}>
                        <Button fullWidth variant='contained' className='big-button' onClick={purchasePlan}>
                            {t('button.purchaseNow')}
                        </Button>
                    </div>
                    <Typography className="p-t-10" style={{fontSize: "0.75rem", color: theme.palette.gray.main, fontWeight:500}}>{t('plan.selectedPackageWillBeSent')}</Typography>
                </Container>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
}));