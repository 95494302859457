import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import WalletItem from '@components/WalletItem';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
// import styles bundle
import 'swiper/css/bundle';
// import Swiper and modules styles
import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { storePaths } from '@utils/Tools';

const WalletSliderShow = (props) => {
    const { listing, onActiveIndexChange  } = props;
    const navigate = useNavigate();
    let location = useLocation();
    const [swiper, setSwiper] = useState(null);
    const [decodedParams, setDecodedParams] = useState(null);
    const theme = useTheme();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (decodedParams && swiper) {
            const matchingIndex = listing.findIndex((data) => {
                
                return (
                    data.id === parseInt(decodedParams.id)
                );
            });       
    
            if (matchingIndex !== -1) {
                swiper.slideTo(matchingIndex);
            }
        }
    }, [decodedParams, listing, swiper]);
  
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('w');
    
        if (id) {
            const decodedId = atob(id);
            setDecodedParams({
                id: decodedId,
            });
        }
    }, []);
    

    const handleSlideChange = (swiper) => {
        const activeIndex = swiper.activeIndex;
        const selectedWalletID = listing[activeIndex].id;
        
        // Update the URL with selected parameters
        navigate(`/transaction?w=${btoa(selectedWalletID)}`, { state: { from: storePaths(location) } });

        // Invoke the callback to pass the active index to the parent component
        if (onActiveIndexChange) {
            onActiveIndexChange(activeIndex);
        }
    };

    const gradients = [
        'linear-gradient(109deg, #82512D 3.41%, #D1913C 98.09%)',
        'linear-gradient(109deg, #B17435 3.41%, #DEA96C 98.09%)',
        'linear-gradient(109deg, #D0985B 3.41%, #EAB367 98.09%)',
        'linear-gradient(109deg, #D6A364 3.41%, #FFD49B 98.09%)',
    ];

    return (
        <div>
            <Swiper 
                centeredSlides={true}
                spaceBetween={20}
                grabCursor={true}
                loop={false}
                pagination={{ 
                    clickable: true,
                    el: '.wallet-slider',
                }}
                slidesPerView={3}
                freeMode={true}
                onSwiper={setSwiper}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                modules={[Pagination]} 
                className="wallet-swiper"
            >
                {listing.map((data, dataIndex) => {
                    const setBgIndex = dataIndex % 4;
                    const background = gradients[setBgIndex];
                    return (
                        <SwiperSlide key={dataIndex}>
                            <div>
                                <WalletItem
                                    walletValue={data?.wallet_balances?.[0]?.balance}
                                    walletDecimal={data?.decimal}
                                    walletCode={data?.code}
                                    background={background}
                                    walletName={data?.name[i18n.language] ? data.name[i18n.language] : data?.name.cn}
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="wallet-slider" style={{margin: "20px 0"}}/>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
}));

export default WalletSliderShow;