import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Tab, Tabs, Typography, LinearProgress, Link, ThemeProvider, createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';

import BonusListing from '@pages/Wallet/BonusListing';
import WalletListing from '@pages/Wallet/WalletListing';
import { FiPlus } from "react-icons/fi";
import theme from '../../Theme';

const Wallet = () => {
    const { t } = useTranslation();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();

    let { page } = useParams();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    
    const [walletListing, setWalletListing] = useState([]);
    const [bonusListing, setBonusListing] = useState([]);
    const [activeTab, setActiveTab] = useState('wallet');
    const [totalBalance, setTotalBalance] = useState(0);
    const [isBonusTabActive, setIsBonusTabActive] = useState(false);
    const [isBonusTabDisabled, setIsBonusTabDisabled] = useState(false);
    const [profitLimit, setProfitLimit] = useState({});
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'wallet',
    });

    useEffect(() => {
        if (page) {
            if (_.includes(['wallet', 'bonus'], page)) {
                setState({
                    ...state,   
                    pageIndex: page === 'bonus' ? 'bonus' : 'wallet',
                    subPage: page,
                });
                if (page === 'bonus') {
                    setIsBonusTabActive(true);
                } else {
                    setIsBonusTabActive(false);
                }
            }
        }
    }, [page]);

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-wallets`).then(response => {
            if (response.status) {
                setWalletListing(response.data);
                // Extract balance values from the wallet_balances array
                const balanceArray = response.data.flatMap(item => {
                    return item.wallet_balances.map(wallet => parseFloat(wallet.balance) || 0);
                });

                // Calculate total balance
                const totalBalance = balanceArray.reduce((sum, balance) => sum + balance, 0);

                // Update the total balance in the state
                setTotalBalance(totalBalance);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setBonusListing(response.data);
                setProfitLimit(response.profit.total_profit_limit);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        setLoading(false);
    }, []);

    const handleTabChange = (event, newValue) => {
        // Use the new value to determine the active tab
        if (newValue === 'bonus') {
            // Check if the Bonus tab is disabled
            if (isBonusTabDisabled) {
                return;
            }

            // Enable bonusListing and set the bonus tab as active
            // setBonusListing([]);
            setIsBonusTabActive(true);
        } else {
            // Disable bonusListing for other tabs and set the bonus tab as inactive
            setIsBonusTabActive(false);
        }

        // Always update the active tab to handle toggling
        setActiveTab(newValue);
        navigate(`/funds/${newValue === 'bonus' ? 'bonus' : 'wallet'}`, { state: { from: storePaths(location) } });
    };

    let percent = null;
    let remain = null;
    let text = '';
    let profit_limit = null;

    percent = (parseFloat(profitLimit?.profit_limit) > 0) ? ((parseFloat(profitLimit?.profit_limit_balance) / parseFloat(profitLimit?.profit_limit)) * 100) : 0;
    remain = parseFloat(profitLimit?.profit_limit_balance);
    text = (profitLimit?.profit_limit_balance || 0) + ' / ' + (profitLimit?.profit_limit || 0);
    profit_limit = parseFloat(profitLimit?.profit_limit);


    return (
        <div style={{ marginTop: 20, marginBottom: 100}}>

        {/* Tabs for wallet or bonus */}
        <div className='p-lr-30'>
                {accessToken && (
                    <div>
                        <div className="flex-col-c">                        
                            <Tabs
                                value={state?.pageIndex || activeTab}
                                onChange={handleTabChange}
                                indicatorColor="#404855"
                                style={{ marginBottom: 5, backgroundColor: '#26292E', minHeight: 'fit-content' }}
                                centered
                            >
                                <Tab
                                    className={`${styles.tabButton} ${state?.pageIndex === 'wallet' && styles.activeTab}`}
                                    value="wallet"
                                    label={
                                        <Typography variant="body1" style={{ color: state?.pageIndex === 'wallet' ? '#E7E7E7' : '#848D9B', fontSize: '0.75rem', fontWeight: 600 }}>
                                            {t('wallet.tabTitleWallet')}
                                        </Typography>
                                    }
                                    style={{ minHeight: 'fit-content', padding: '3px 5px' }}
                                />
                                <Tab
                                    className={`${styles.tabButton} ${state?.pageIndex === 'bonus' && styles.activeTab} ${isBonusTabDisabled && styles.disabledTab}`}
                                    value="bonus"
                                    label={
                                        <Typography variant="body1" style={{ color: state?.pageIndex === 'bonus' ? '#E7E7E7' : '#848D9B', fontSize: '0.75rem', fontWeight: 600 }}>
                                            {t('wallet.tabTitleBonus')}
                                        </Typography>
                                    }
                                    style={{ minHeight: 'fit-content', padding: '3px 5px' }}
                                />
                            </Tabs>
                        </div>

                        {/* Title & Amount */}
                        <div className={`m-t-15 ${styles.walletBalanceLayout}`}>
                            <p variant='body1' className='txt-content' style={{ fontWeight: 500, color: theme.palette.gray.main, fontSize: '0.75rem' }} >
                                { state?.pageIndex === 'bonus' ? t('wallet.profitBalance') : t('wallet.accumulateWalletAmount')}
                            </p>
                            <Typography variant='h3' className='txt-title' style={{ fontWeight: 600, color: 'white', fontSize: '1.875rem', marginTop: 10 }}>
                                { state?.pageIndex === 'bonus' ? <>$ {currencyFormat(parseFloat(profitLimit?.profit_limit_balance || 0))} <span className='fs-18'>/ {currencyFormat(parseFloat(profitLimit?.profit_limit || 0))}</span></> : `$ ${currencyFormat(totalBalance)}`}
                            </Typography>
                        </div>

                        <div className='m-t-15'>
                            <Typography style={{ fontSize: '0.75rem', fontWeight: 600, color: 'white' }}>{ state?.pageIndex === 'bonus' ?  t('wallet.myBonus') : t('wallet.myWallets') }</Typography>
                        </div>

                        {/* Profit Limit Balance */}
                        {/* {
                            state?.pageIndex === 'bonus' &&
                            <>
                                <div className='w-full pos-relative flex-m p-b-10  p-t-10' >
                                    <div style={{ width: 'calc(100% - 20px)' }} className='flex-c-m'>
                                        <ThemeProvider theme={progressBarStyle}>
                                            <div className='w-full' >
                                                <ThemeProvider theme={changeDirection => ({ ...changeDirection, direction: 'rtl' })}>
                                                    <div className='p-all-8' style={{ borderRadius: 48, background: '#F7F7F7', boxShadow: ' 3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px #FFF' }}>
                                                        <LinearProgress dir="rtl" variant="determinate" value={percent ? percent : 0} style={{ height: 30, borderRadius: 48 }} />
                                                    </div>
                                                </ThemeProvider>
                                            </div>
                                        </ThemeProvider>
                                        <div className='w-full txt-center pos-absolute'>
                                            <div className='flex-c-m w-full' style={{  color: percent > 57 ? '#fff' : '#545454', fontWeight: 500 }}>
                                                <p variant='body1'>
                                                    {percent}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <Link component={RouterLink} to={`/plans`} state={{ from: storePaths(location) }} underline="none" className='pos-absolute' style={{ right: 0 }}>
                                        <div className='flex-c-m clwhite plus-btn'>
                                            <FiPlus className='fs-27'/>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        } */}

                        {/* Content for each tab */}
                        <div className={`tab-content ${state?.pageIndex === 'wallet' ? '' : 'hidden-left'}`}>
                            {state?.pageIndex === 'wallet' && <WalletListing listing={walletListing} />}
                        </div>
                        <div className={`tab-content ${state?.pageIndex === 'bonus' ? '' : 'hidden-right'}`}>
                            {state?.pageIndex === 'bonus' && <BonusListing listing={bonusListing} />}
                        </div>
                    </div>
                )}
            </div>

            {/* Balance Wallet */}

                {/* Daily Interest Amount */}
                {/* <Grid item xs={12} style={{marginLeft: 100}}>
                    <Typography variant='body2' className={styles.colorGrey} style={{fontWeight: 700}}>
                        {isBonusTabActive ? `+ $ ${(totalToday).toFixed(2)} ${t('wallet.today')}` : ''}
                    </Typography>
                </Grid> */}

            
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {       
       backgroundColor: '#16171C',
       padding: 12,
       borderRadius: 12,
    },
    tabButton: {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    activeTab: {
        background:'#404855',
    },
}));



const progressBarStyle = createTheme({
    components: {
      // Name of the component
      MuiLinearProgress: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor:'#dddddd',
            boxShadow:'3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset'
          },
          bar1Determinate: {
            // backgroundColor:'#DE9D49',
            background:'#DE9D49',
            background:'linear-gradient(45deg, #a46a20 0%, #efb43f 100%)',
            borderRadius:48,
            boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.44) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 0px 2px 0px rgba(0, 0, 0, 0.10)'
          }
        },
      },
    },
  });

export default Wallet;