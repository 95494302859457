import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const PnL = () => {
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const theme = useTheme();

    const styles = useStyles();
    const isMountedRef = useRef(null);

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [listing, setListing] = useState([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        
        getUrl(`/bot-tradings`, { per_page: 10, page: page }).then((response) => {
            if (response.status && isMountedRef.current) {
                let { data, current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);

                if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                if (page > 1) { setListing(_.concat(listing, data)); }
                else { setListing(data); }
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })

        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
    }, [page, searchParams]);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom ) {
            setPage(page + 1);
        }
    }

    return (
        <div className='flex-c-m flex-col m-t-80'>
            <div className={styles.walletBalanceLayout}>
                <Typography variant='h6' className='txt-title' style={{fontWeight: 'bold'}}>
                    {t('botTrading.transactionRecord')}
                </Typography>
                
                <Link href={`https://bscscan.com/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c?a=0x6D2B794f126448A0C5cd9aca831cafc02a603f80`} underline="hover" target="_blank" rel="nofollow">
                    <Typography variant='h6' className='txt-title p-lr-40' style={{fontWeight: 'bold', wordBreak: "break-all", color: "white", textAlign: "center"}}>
                        0x6D2B794f126448A0C5cd9aca831cafc02a603f80
                    </Typography>
                </Link>
            </div>

            <Grid container style={{padding: '0 35px'}}>
                {
                    listing?.length > 0 ? (
                        listing.map((_row, key) => (
                            <Grid item xs={12} key={_row.id} style={{ padding: '10px 0' }}>
                                <Box>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} className="flex-col-c-sb">
                                            <Typography variant='subtitle2' style={{ textAlign: "left", color:'#cfcccc' }}>
                                                PnL:
                                            </Typography>
                                            <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "left", color:'#2fbe81' }}>
                                                {_row.pnl}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="flex-col-c-sb">
                                            <Typography variant='subtitle2' style={{ textAlign: "left", color:'#cfcccc' }}>
                                                {t('botTrading.margin')}:
                                            </Typography>
                                            <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "left", color:'#2fbe81' }}>
                                                {_row.margin}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex' }}>
                                            <Typography variant='subtitle2' style={{ textAlign: "left", color:'#cfcccc', flex: 1 }}>
                                                {t('botTrading.hash1')}:
                                            </Typography>
                                            <div style={{ flex: 2}}>
                                                <Typography variant='subtitle1' style={{ fontWeight: "500", textAlign: "right", color: '#cfcccc', wordBreak: 'break-all' }}>
                                                    {_row.tx_hash_1}
                                                </Typography>
                                                <Link href={`https://bscscan.com/tx/${_row.tx_hash_1}`} color="primary" underline="hover" target="_blank" rel="nofollow">
                                                    <Typography variant='subtitle2' style={{ textAlign: "right", color: theme.palette.gold}}>{t('botTrading.viewOnBscScan')}</Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex' }}>
                                            <Typography variant='subtitle2' style={{ textAlign: "left", color:'#cfcccc', flex: 1 }}>
                                                {t('botTrading.hash2')}:
                                            </Typography>
                                            <div style={{ flex: 2}}>
                                                <Typography variant='subtitle1' style={{ fontWeight: "500", textAlign: "right", color: '#cfcccc', wordBreak: 'break-all' }}>
                                                    {_row.tx_hash_2}
                                                </Typography>
                                                <Link href={`https://bscscan.com/tx/${_row.tx_hash_2}`} color="primary" underline="hover" target="_blank" rel="nofollow">
                                                    <Typography variant='subtitle2' style={{ textAlign: "right", color: theme.palette.gold}}>{t('botTrading.viewOnBscScan')}</Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption' style={{ color: "#cfcccc", textAlign: "left" }}>
                                                {`${_row.first_hash_timestamp}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className='divider-design'></div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src="images/wallets/emptystate.png" alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                            <Typography variant='h6' style={{ fontWeight: 'bold', paddingTop: 20 }}>
                                {t('table.noTransactionYet')}
                            </Typography>
                        </Grid>
                    )
                }

                {
                    listing?.length > 0 && (
                        <div className={styles.endFontStyle}>
                            <p className='fs-12 txt-content txt-center'>
                                {currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                            </p>
                        </div>
                    )
                }
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundImage: `url('images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 85,
        justifyContent: "center",
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
        width:'100%'
    },
}));

export default PnL;