import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, useTheme, styled } from '@mui/styles';

import { Button, FormControl, TextField, Box, SwipeableDrawer, InputAdornment, Input, IconButton} from '@mui/material';

import { changeLanguage } from '@actions';
import { FiPlus, FiMinus } from "react-icons/fi";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };
    
    //////////////////  Textfield - Security Password  //////////////////
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    /////////////////////////////  Drawer  ///////////////////////////////////
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
          <Box
              sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
              role="presentation"
          >
              <div className='p-all-20' style={{ minHeight: 150 }}>
                <div className='p-b-80'>
                    <p className='p-b-25' style={{fontWeight:500, color:'#565656'}}>{t('trade.enterSecurityPwd')}</p>
                    {/* <TextField id="outlined-basic"  variant="standard" /> */}
                      <FormControl variant="standard" fullWidth>
                          <Input
                              id="standard-adornment-password"
                              placeholder={t('title.securityPassword')}
                              fullWidth
                              style={{borderBottom:'none'}}
                              type={showPassword ? 'text' : 'password'}
                              endAdornment={
                                  <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                      >
                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                  </InputAdornment>
                              }
                          />
                      </FormControl>
                </div>
                  <div className='flex-c-m p-t-20 w-full pos-relative'  onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}>
                      <Button variant="contained" onClick={toggleDrawer('bottom', true)} className='big-button pos-fixed' style={{ bottom: 30, position: 'fixed' }} fullWidth>{t('button.proceed')}</Button>
                  </div>
              </div>
          </Box>
      );

    return (
        <div className=' p-t-25 p-b-110 pos-relative' style={{ minHeight: '90vh' }}>
            <div className='p-tb-50 p-lr-35 pos-relative flex-c-m w-full' style={{ backgroundImage: 'url(/images/trade/mountain.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center' }}>
                <div className='txt-center txt-title'>
                    <p className='fs-14' style={{ color: '#565656' }}><b>{t('trade.availableSellingAmount')}</b></p>
                    <div className='flex-c-m'>
                    <p className='fs-30 p-r-5' style={{color:'#D99948'}}>177.172</p>
                    <div className='max-w-full lh-0 p-t-5' style={{ width: 25 }}>
                        <img src='/images/trade/usdt.png' alt='usdt' className='w-full'/>
                    </div>
                    </div>
                </div>
            </div>
          

             {/* ///////////////  Transfer Details  ///////////////// */}
            <div className='p-lr-35 pos-relative h-full'>
                
                <div className='flex-sb-m p-tb-10' style={{ color:'#565656' }}>
                    <p>{t('trade.unit')}</p>
                    <div className='pos-relative flex-c-m' style={{width:160, height:40, }}>
                        <div className='pos-absolute flex-c-m' style={{ width: 45, height: 45, background:'#eee', borderRadius:8, left: 0, boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF'}}>
                            <FiPlus/>
                        </div>
                        <div className='w-full flex-c-m h-full' style={{boxShadow:'-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset', background:'#eee', borderRadius:5}}>
                            <p className='txt-center'>235</p>
                        </div>
                       
                        <div className='pos-absolute flex-c-m' style={{ width: 45, height: 45, background:'#eee', borderRadius:8, right: 0, boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF' }}>
                            <FiMinus/>
                        </div>
                    </div>
                </div>

                <div className='flex-sb-m p-tb-10' style={{ color:'#565656'}}>
                    <p>{t('trade.currentRate')}</p>
                    <p className=' fs-21'>0.35</p>
                </div>

                <div className='flex-sb-m p-tb-10' style={{ color:'#565656'}}>
                    <p>{t('trade.autoSelling')}</p>
                    <div className='pos-relative' style={{ height: 40, width: 80, borderRadius: 48, background: '#EEE', boxShadow: '-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset' }}>
                        <div className='pos-absolute switch-on'/>
                    </div>
                </div>

                <div className='flex-c-m p-t-20 w-full pos-relative'>
                    <Button variant="contained" onClick={toggleDrawer('bottom', true)} className='big-button' style={{bottom:30, position:'fixed'}} fullWidth>{t('button.update')}</Button>
                </div>
            </div>



            <div>
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}
                >
                    {list('bottom')}
                </SwipeableDrawer>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));