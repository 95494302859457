import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import RegisterForm from '@layouts/RegisterForm';

const Recruit = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div className='p-b-110' style={{ minHeight: '90vh' }}>
            <div className="flex p-lr-20 p-tb-10 p-t-80">
                <RegisterForm />
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    titleBox: {
        // backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 30
    },
    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    }
}));

export default Recruit