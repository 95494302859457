import React, { useState, useRef, useEffect } from 'react';

const Draggable = (props) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [rel, setRel] = useState({ x: 0, y: 0 });
    const dragElementRef = useRef(null);
    
    useEffect(() => {
        const div = document.getElementsByClassName('MuiContainer-root')[0];
        const style = window.getComputedStyle(div);
        setPosition({ x: parseInt(style.width)+parseInt(style.marginLeft)-props.style.width-props.offsetX, y: parseInt(style.height)-props.style.height-props.offsetY })
    }, [])
    

    const handleMouseDown = (e) => {
        if (e.button !== 0) return; // Only allow left mouse button to drag
        startDragging(e.clientX, e.clientY);
        e.preventDefault();
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        startDragging(touch.clientX, touch.clientY);
        // e.preventDefault();
    };

    const startDragging = (clientX, clientY) => {
        const elem = dragElementRef.current;
        const boundingRect = elem.getBoundingClientRect();
        
        setRel({
            x: clientX - boundingRect.left,
            y: clientY - boundingRect.top,
        });
        setDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;
        moveElement(e.clientX, e.clientY);
        e.preventDefault();
    };

    const handleTouchMove = (e) => {
        if (!dragging) return;
        const touch = e.touches[0];
        moveElement(touch.clientX, touch.clientY);
        e.preventDefault();
    };

    const moveElement = (clientX, clientY) => {
        setPosition({
            x: clientX - rel.x,
            y: clientY - rel.y,
        });
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleTouchEnd = () => {
        setDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove, {passive: false});
        document.addEventListener('mouseup', handleMouseUp, {passive: false});
        document.addEventListener('touchmove', handleTouchMove,  { passive: false });
        document.addEventListener('touchend', handleTouchEnd,  { passive: false });
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [dragging, rel]);

    return (
        <div
            ref={dragElementRef}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            style={{
                ...props.style,
                position: 'fixed',
                left: `${position.x}px`,
                top: `${position.y}px`,
                zIndex: 999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {props.children}
        </div>
    );
};

export default Draggable;
