import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline, MdEmail } from "react-icons/md";

import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function ResetSecurityPassword() {
    const { accessToken } = useSelector((state) => state.general);
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const [state, setState] = useState({
        email: '',
        security_password: '',
        security_password_confirmation: '',
        token: searchParams.get("token")
    });

    const [option, setOption] = useState({
        password_show: false,
        password_confirmation_show: false,
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        let postData = {
            email: state.email,
            security_password: state.security_password,
            security_password_confirmation: state.security_password_confirmation,
            token: state.token,
        }
        postUrl('/reset-security-password', postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                navigate('/');
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += ` ${value}`;
                })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box style={{ minHeight: '100vh', position: "relative", overflowY: 'hidden', backgroundImage:`url('../images/auth/login-bg.png')`, backgroundSize:'contain', backgroundPosition:'center'}}>
            {/* <Box style={{ position: "absolute" }}>
                <img src="images/auth/login-bg.png" style={{ width: "100%" }} alt="bg-img" loading='lazy' />
            </Box> */}
            <Box>
                <img src="images/logos/logo.png" style={{ width:'20%', margin: 30, position: "absolute" }} alt="logo" loading='lazy' />
            </Box>
            <Box className={classes.contentBox}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <div className='txt-content p-lr-20 fs-30 p-b-15' style={{ lineHeight: 0.7 }}>
                            {t('resetSecurityPassword.title')}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='txt-content p-lr-20 fs-14 lh-11' variant="subtitle1">
                            {accessToken? t('resetSecurityPassword.desc1'):t('resetSecurityPassword.desc2')}
                        </div>
                    </Grid>

                    { !accessToken &&
                     <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            placeholder={t('user.email')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdEmail className="img-style" />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            type="email"
                            autoComplete="email"
                            color='primary'
                            value={state.email}
                            onChange={({ target }) => setState({ ...state, email: target.value })}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid> }
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            placeholder={t('user.securityPassword')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdLockOutline className="img-style" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handlePasswordShow('password_show')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {option?.password_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            autoComplete="security_password"
                            color='primary'
                            value={state.security_password}
                            type={option?.password_show ? 'text' : 'password'}
                            onChange={({ target }) => setState({ ...state, security_password: target.value })}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            placeholder={t('user.securityPasswordConfirmation')}
                            type={option?.password_confirmation_show ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdLockOutline className="img-style" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handlePasswordShow('password_confirmation_show')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {option?.password_confirmation_show ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            autoComplete="password"
                            color='primary'
                            value={state.security_password_confirmation}
                            onChange={({ target }) => setState({ ...state, security_password_confirmation: target.value })}
                            helperText={inputErrors && inputErrors.security_password_confirmation ? inputErrors.security_password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            className={classes.textFieldStyle}
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                    <Button variant="contained" color="primary" onClick={resetPassword} className='big-button' style={{ marginTop: 20 }} fullWidth>
                        {t('button.reset')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '70%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));