import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import {
    Grid, Box, Typography, Button, TextField,
    FormHelperText, InputLabel, IconButton, InputAdornment, Container, MenuItem
} from '@mui/material';
import { Link as RouterLink, useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';

import CustomSelect from '@components/CustomSelect';
import useNotificationLoading from '@utils/useNotificationLoading';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const INITIAL_STATE = {
    wallet: "",
    amount: "",
    feeOption: 1,
    remark: "",
    security_password: '',
    authentication_code: '',
    showPassword: false,
}

const Withdraw = () => {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [searchParams] = useSearchParams();
    const [inputErrors, setInputErrors] = useState();
    // let navigate = useNavigate();

    const [state, setState] = useState(INITIAL_STATE);
    const [walletSetting, setWalletSetting] = useState({});
    const [submitAction, setSubmitAction] = useState(true);
    const [conditionError, setConditionError] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);
    const [wallet, setWallet] = useState(null);
    const [allWallet, setAllWallet] = useState(null);

    useEffect(() => {
        setLoading(true);
        getUrl(`/get-withdraw-details`).then(response => {
            setLoading(false);
            if (response.status) {
                setWalletSetting(response.wallet_settings);
                // if ( !response?.user?.wallets?.general?.address && !response?.user?.wallets?.general?.network)
                // {
                //     addAlert('', t(`error.notFoundCryptoAddress`) , 'error', '');
                //     navigate('/account/crypto-address');
                // }
                setSubmitAction(false);
            }
            else {
                setSubmitAction(true);
                setConditionError(response.err_msg);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/member-wallets`).then((response) => {
            setLoading(false);
            if (response.status) {
                if (typeof(searchParams.get("w")) === 'string') {
                    let id = parseInt(atob(searchParams.get("w")));
                    let _wallet = _.find(response.data, {id})

                    setWalletBalance(parseFloat(_wallet?.wallet_balances?.[0]?.balance || 0));
                    setWallet(_wallet);
                }
                setAllWallet(response.data);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function totalCal() {
        return (
            (state?.amount -
                (
                    walletSetting?.fee_mode == 'both'
                        ?
                        (walletSetting?.fee_amount + (state?.amount * walletSetting?.fee_percent / 100))
                        :
                        (Math.max(walletSetting?.fee_amount, walletSetting?.fee_percent))
                )).toFixed(2)
        )
    }

    function calcFee(){
        let setText = '';
        let setReceive = '';

        if(wallet.id>0){
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='percent'){
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    setText = setText + (state?.amount*data.fee_percent/100) + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    if(wallet.id===data.fee_wallet_id){
                        setReceive = ((state.amount - (state.amount*data.fee_percent/100))>0? currencyFormat(state.amount - (state.amount*data.fee_percent/100)):0) + (wallet.id===5||wallet.id===6)?' '+wallet.code.toUpperCase():'USDT';
                    }else{
                        setReceive = state.amount + ((wallet.id===5||wallet.id===6)?wallet.code.toUpperCase():' USDT');
                    }
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='fixed'){
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    setText = setText + currencyFormat(data.fee_amount) + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    if(wallet.id===data.fee_wallet_id){
                        setReceive = ((state.amount - data.fee_amount)>0 ? currencyFormat(state.amount - data.fee_amount):0) +' '+ ((wallet.id===5||wallet.id===6)?' '+wallet.code.toUpperCase():' USDT');
                    }else{
                        setReceive = state.amount +' '+ ((wallet.id===5||wallet.id===6)?wallet.code.toUpperCase():' USDT');
                    }
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='higher'){
                let max = 0;
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        let val = Math.max(max, data.fee_amount);
                        if(val>max) {
                            setText = data.fee_amount + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                            if(wallet.id===data.fee_wallet_id){
                                setReceive = ((state.amount - data.fee_amount)>0?currencyFormat(state.amount - data.fee_amount):0) + ((wallet.id===5||wallet.id===6)?' '+wallet.code.toUpperCase():' USDT');
                            }else{
                                setReceive = state.amount + ((wallet.id===5||wallet.id===6)?wallet.code.toUpperCase():' USDT');
                            }
                        }
                    }else{
                        let val = Math.max(max, (state.amount*data.fee_percent/100));
                        if(val>max) {
                            setText = max + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                            if(wallet.id===data.fee_wallet_id){
                                setReceive = (state.amount - (state.amount*data.fee_percent/100)>0? currencyFormat(state.amount - (state.amount*data.fee_percent/100)):0) + (wallet.id===5||wallet.id===6)?' '+wallet.code.toUpperCase():' USDT';
                            }else{
                                setReceive = state.amount + ((wallet.id===5||wallet.id===6)?wallet.code.toUpperCase():' USDT');
                            }
                        }
                    }
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode === 'both') {
                // fee=(withdrawal_amount*fee_percent/100)+fee_amount
                let totalFee = 0;
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        totalFee = totalFee + data.fee_amount;
                    }else{
                        totalFee = totalFee + (state.amount*data.fee_percent/100);
                    }
                    setText = currencyFormat(totalFee) + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    if(wallet.id===data.fee_wallet_id){
                        setReceive = ((state.amount - totalFee)>0?currencyFormat(state.amount - totalFee):0) +' '+ ((wallet.id===5||wallet.id===6)?(' '+wallet.code):' USDT');
                    }else{
                        setReceive = ((state.amount)>0?currencyFormat(state.amount):0) +' '+ ((wallet.id===5||wallet.id===6)?(' '+wallet.code):' USDT');
                    }
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode === 'mixed') {
                let totalFee = 0;
                let toDeduct = 0;
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        setText = setText + currencyFormat(data.fee_amount) + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];;
                        if(wallet.id===data.fee_wallet_id){
                            toDeduct = toDeduct + data.fee_amount;
                        }
                    }else{
                        setText = setText + currencyFormat(state.amount*data.fee_percent/100) + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                        if(wallet.id===data.fee_wallet_id){
                            toDeduct = toDeduct + (state.amount*data.fee_percent/100);
                        }
                    }
                    if(index!==walletSetting[wallet?.id][state?.feeOption]?.wallet.length -1) setText = setText+' + ';
                })
                setReceive = ((state.amount - toDeduct)>0?currencyFormat(state.amount - toDeduct):0) + ((wallet.id===5||wallet.id===6)?' '+wallet.code.toUpperCase():' USDT');
            }
        }

        return (
            {'fee':setText, 'receive':setReceive}
        )
    }

    function displayFee() {
        let setText = '';

        if(wallet.id>0){
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='percent'){
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    setText = setText + data.fee_percent +'% '+ _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='fixed'){
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    setText = setText + data.fee_amount +' '+ 
                    _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode==='higher'){
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        setText = setText + data.fee_amount + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    }else{
                        setText = setText + data.fee_percent + '% ';
                    }
                    if(index!==walletSetting[wallet?.id][state?.feeOption]?.wallet.length -1) setText = setText+' & ';
                })
                setText = setText + t(`withdraw.heigherCodition`);
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode === 'both') {
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        setText = setText + data.fee_amount + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    }else{
                        setText = setText + data.fee_percent + '% ';
                    }
                    if(index!==walletSetting[wallet?.id][state?.feeOption]?.wallet.length -1) setText = setText+' + ';
                })
            }
            if (walletSetting[wallet?.id][state?.feeOption]?.fee_mode === 'mixed') {
                _.map(walletSetting[wallet?.id][state?.feeOption]?.wallet, (data, index)=>{
                    if(data.fee_amount>0){
                        setText = setText + data.fee_amount + ' ' + _.find(allWallet, {id:data.fee_wallet_id})?.name[i18n.resolvedLanguage];
                    }else{
                        setText = setText + data.fee_percent + '% ';
                    }
                    if(index!==walletSetting[wallet?.id][state?.feeOption]?.wallet.length -1) setText = setText+' + ';
                })
            }
        }

        return (
            setText
        )
    }

    const submitData = () => {
        setLoading(true);
        let apiData = {
            wallet_id: parseInt(atob(searchParams.get('w'))),
            amount: state.amount,
            feeOption: state.feeOption,
            remark: state.remark,
            security_password: state?.security_password,
            authentication_code: state?.authentication_code,
        };
        setInputErrors();

        postUrl('/wallets/withdrawal', apiData).then(response => {
            setLoading(false);
            if (response.status) {
                setState(INITIAL_STATE);
                addAlert('', t('success.withdrawalsSuccess'), 'success', '');
                // navigate('/');
            }
            else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.purchaseError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box style={{ minHeight: '90vh', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: 30, paddingRight: 30, marginTop: 60, paddingTop: 10, paddingBottom: 150 }} >
            {/* <div style={{padding: '0 35px 30px 35px'}}>
                <Typography variant='h6' className={styles.colorBrown} style={{fontWeight: 'bold'}}>
                    {t('withdraw.title')}
                </Typography>
            </div> */}
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={12}>
                    <div className="flex-sb-m">
                        <InputLabel>{t('withdraw.withdrawalAmount')}</InputLabel>
                        <span style={{ color: 'white', fontSize: '0.75rem' }}>{`${currencyFormat(walletBalance)} ${wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']}`}</span>
                    </div>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='amount'
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                        error={inputErrors && inputErrors.amount ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography onClick={() => setState({ ...state, amount: Math.floor((parseFloat(walletBalance)-parseFloat(wallet?.wallet_balances?.[0]?.locked))||0) })} style={{ color: theme.palette.gold, fontSize: '0.875rem', cursor: 'pointer' }}>{t('withdraw.max')}</Typography>
                                </InputAdornment>
                            ),
                        }}
                        value={state.amount || ''}
                        placeholder={t('withdraw.withdrawalAmountPlaceholder')}
                    />
                    {/* <FormHelperText>
                        {` ${t('withdraw.amountToReceive')}: 
                            ${state?.amount ? (totalCal() > 0 ? totalCal() : '0.00') : '0.00'}
                        `}
                    </FormHelperText> */}
                    { wallet?.wallet_balances?.[0]?.locked > 0 &&
                        <FormHelperText>
                            ${currencyFormat(parseFloat(wallet?.wallet_balances?.[0]?.locked)||0)}
                        </FormHelperText>
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{t('withdraw.feeOptions')}</InputLabel>
                    <TextField
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '10px', padding: "12px 0px 12px 14px", height: 46 } }}
                        variant="standard"
                        select
                        placeholder={t('withdraw.feeOptions')}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={state.feeOption||1}
                        onChange={({ target }) => setState({ ...state, feeOption: target.value })}
                    >
                        {
                            _.map(walletSetting[wallet?.id], (option, key) => {
                                return(
                                    <MenuItem key={key} value={key}>{option?.display}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{t('withdraw.remark')}</InputLabel>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='remark'
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                        error={inputErrors && inputErrors.remark ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.remark}
                        placeholder={t('withdraw.remarkPlaceholder')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{t('transfer.securityPassword')}</InputLabel>
                    <TextField
                        placeholder={t('transfer.securityPasswordPlaceholder')}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        variant='standard'
                        fullWidth
                        type={state.showPassword ? 'text' : 'password'}
                        autoComplete='securityPassword'
                        name='securityPassword'
                        value={state?.security_password}
                        onChange={({ target }) => setState({ ...state, security_password: target.value })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        inputErrors && inputErrors.security_password
                            ?
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                            :
                            null
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{t('transfer.twoFactorAuth')}</InputLabel>
                    <TextField
                        error={inputErrors && inputErrors.authentication_code ? true : false}
                        helperText={inputErrors?.authentication_code || ''}
                        sx={{ '& .MuiInputBase-root': { borderRadius: '6px', padding: "15px 15px 15px 15px", height: 46 } }}
                        placeholder={t('transfer.twoFactorAuthPlaceholder')}
                        variant="standard"
                        fullWidth
                        value={state?.authentication_code}
                        onChange={({ target }) => {
                            const numericInput = target.value.replace(/\D/g, '');
                            setState({ ...state, authentication_code: numericInput });
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            inputMode: 'numeric', // Set input mode to numeric
                            pattern: '[0-9]*',     // Set a pattern to enforce numeric input
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    {
                        (_.size(conditionError) <= 0 && wallet && walletSetting[wallet?.id] )?
                            <>
                                <Typography variant='body2' >
                                    {t('withdraw.withDrawConditionTitle')}
                                </Typography>
                                <ol className="withdraw-ol">
                                    {
                                        (walletSetting[wallet?.id][state?.feeOption]?.min_amount > 0 && wallet?.id>0)
                                            ?
                                            <li>
                                                <Typography variant='caption' >
                                                    {t('withdraw.withDrawCondition1', { 'min_amount': '$' + walletSetting[wallet?.id][state?.feeOption]?.min_amount })}
                                                </Typography>
                                            </li>
                                            :
                                            null
                                    }
                                    {
                                        walletSetting[wallet?.id][state?.feeOption]?.max_amount > 0
                                            ?
                                            <li>
                                                <Typography variant='caption' >
                                                    {t('withdraw.withDrawCondition2', { 'max_amount': '$' + walletSetting[wallet?.id][state?.feeOption]?.max_amount })}
                                                </Typography>
                                            </li>
                                            :
                                            null
                                    }
                                    {
                                        displayFee()
                                            ?
                                            <li>
                                                <Typography variant='caption' >
                                                    {t('withdraw.withDrawCondition3', { 'value': displayFee() })}
                                                </Typography>
                                            </li>
                                            :
                                            null
                                    }
                                    {
                                        walletSetting[wallet?.id][state?.feeOption]?.multiple_of > 0
                                            ?
                                            <li>
                                                <Typography variant='caption' >
                                                    {t('withdraw.withDrawCondition4', { 'value': walletSetting[wallet?.id][state?.feeOption]?.multiple_of })}
                                                </Typography>
                                            </li>
                                            :
                                            null
                                    }
                                </ol>
                            </>
                            :
                            <>
                                <Typography variant='body2' >
                                    {t('withdraw.withDrawErrorConditionTitle')}
                                </Typography>
                                <ol className="withdraw-ol">
                                    {
                                        _.map(conditionError, (data, key) => {
                                            return <li key={key}><Typography variant='caption' >{data}</Typography></li>;
                                        })
                                    }
                                </ol>
                            </>
                    }
                </Grid>
            </Grid>
            {!submitAction && 
            <Container maxWidth="xs" className="flex-col p-lr-20 p-tb-15" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#16171C' }}>
                <div className="flex-sb-m">
                    <Typography style={{ fontWeight: 500, fontSize: '0.75rem', color: theme.palette.gray.main }}>{t('withdraw.fee')}</Typography>
                    {/* <Typography style={{ fontSize: '0.75rem', color: 'white' }}>{currencyFormat(parseFloat(state?.amount) > 0 ? (totalCal() > 0 ? totalCal() : 0) : 0)}</Typography> */}
                    <Typography style={{ fontSize: '0.75rem', color: 'white' }}>{parseFloat(state?.amount) > 0 ? (calcFee()['fee'] !=='' ? calcFee()['fee'] : 0) : 0}</Typography>
                </div>
                <div className="flex-sb-m m-t-5">
                    <Typography style={{ fontWeight: 500, fontSize: '0.75rem', color: theme.palette.gray.main }}>{t('withdraw.amountToReceive')}</Typography>
                    {/* <Typography style={{ fontSize: '0.75rem', color: 'white'}}>{`${currencyFormat(state?.amount ? (state.amount - totalCal() > 0 ? (state.amount - totalCal()) : 0) : 0)} USDT`}</Typography> */}
                    <Typography style={{ fontSize: '0.75rem', color: 'white'}}>{`${(state?.amount ? (calcFee()['receive']!=='' ? (calcFee()['receive']) : 0) : 0)}`}</Typography>
                </div>
                <Button fullWidth variant="contained" style={{ height: '46px', marginTop: 10 }} onClick={submitData} disabled={submitAction}>{t('button.submit')}</Button>
            </Container>
            }
            {/* <Button
                variant="contained"
                fullWidth
                className='big-button'
                style={{ marginBottom: 30, marginTop: 30 }}
                onClick={submitData}
                disabled={submitAction}
            >
                {t('button.submit')}
            </Button> */}
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    depositBox: {
        borderRadius: 6,
        backgroundColor: '#D6A364',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 10,
        padding: 12,
        justifyContent: 'center'
    }
}));

export default Withdraw;